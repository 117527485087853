import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
// Tools
// import { notesActions } from '@/bus/notes'
import { uiActions } from '@/bus/ui'
// Typing
import { DeleteAsync } from '../../types'
import { ERRORS_MAIN } from '@/constants'
// Api
// import { apiNotes } from '@/api/Notes'

export function* deleteItem(action: DeleteAsync): SagaIterator {
  try {
    yield put(uiActions.startFetching())

    // Api call or some async actions
    // const response = yield call(apiNotes.deleteNote, action.payload)

    // console.log(response)
  } catch (error) {
    yield put(uiActions.emitError(error, 'Note deleteItem workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}
