import React from 'react'
import { Col, Row, Table } from 'antd'
// Redux
// Typings
import type { Client } from '@/bus/clients'
// Local
import { usePagination } from '@/hooks'
import { useColumns } from './useColumns'
import { useFetchData } from './useFetchData'

type PropsTypes = {
  dataSource: Client.Item[]
  loading: boolean
  count: number
  fetchClients: (params?: Client.ReqFetchItems) => void
  className?: string
  searchString?: string
}

export const TableClients: React.FC<PropsTypes> = (props: PropsTypes) => {
  const {
    dataSource,
    loading = false,
    count = 0,
    fetchClients,
    className,
    searchString,
  } = props

  const { handleTableChange } = useFetchData(fetchClients, searchString)

  const pagination = usePagination(count)
  const columns = useColumns()

  return (
    <Row justify="center">
      <Col span={24}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={dataSource}
          onChange={handleTableChange}
          pagination={{ ...pagination, position: ['bottomCenter'] }}
          scroll={{ x: 800 }}
          rowKey={(item: Client.Item): string => item.id.toString()}
          className={className}
        />
      </Col>
    </Row>
  )
}
