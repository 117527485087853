import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { message } from 'antd'
import type { AxiosResponse } from 'axios'
// Local
import { ERRORS_MAIN } from '@/constants'
import { authActions } from '@/bus/auth'
import { uiActions } from '@/bus/ui'
import { RouteEnum } from '@/configs/RouteEnum'
import { successAuth } from '@/bus/auth/saga/workers/successAuth'
// Local Dir
import { apiAuth } from '../../api'
import type {
  ResetPasswordCheckAsync,
  ResetPasswordEndAsync,
  ResetPasswordStartAsync,
} from '../../types'

export function* resetPassword(action: ResetPasswordStartAsync): SagaIterator {
  try {
    yield put(uiActions.startFetching())
    const response: AxiosResponse<string> = yield call(
      apiAuth.resetPasswordStart,
      action.payload
    )

    if (response.status === 201) {
      yield put(
        authActions.saveDataRestorePassword({
          controlName: 'email',
          data: action.payload,
        })
      )

      yield put(push(RouteEnum.ForgotPasswordCode))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'resetPasswordStart worker'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}

export function* resetPasswordCheck(
  action: ResetPasswordCheckAsync
): SagaIterator {
  try {
    yield put(uiActions.startFetching())
    const response = yield call(apiAuth.resetPasswordCheck, action.payload)

    if (response.data) {
      yield put(
        authActions.saveDataRestorePassword({
          controlName: 'secretCode',
          data: action.payload.secretCode,
        })
      )

      yield put(push(RouteEnum.ForgotPasswordPassword))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'resetPasswordStart worker'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}

export function* resetPasswordEnd(action: ResetPasswordEndAsync): SagaIterator {
  try {
    yield put(uiActions.startFetching())
    const response = yield call(apiAuth.resetPasswordEnd, action.payload)

    if (response.data) {
      yield call(successAuth, {
        data: response.data,
        isSaveSession: true,
      })
      yield put(push(RouteEnum.App))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'resetPasswordEnd worker'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}
