import type { FC, ReactNode } from 'react'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
// Local
import { RootState } from '@/core/store/rootReducer'
import { authSelectors } from '@/bus/auth'
import { RouteEnum } from '@/configs/RouteEnum'

interface GuestGuardProps {
  children?: ReactNode
}

export const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const isAuth = useSelector((state: RootState) =>
    authSelectors.isAuthenticate(state)
  )

  if (isAuth) {
    return <Redirect to={RouteEnum.App} />
  }

  return <>{children}</>
}
