import { call, delay, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from 'redux-saga'
import type { AxiosResponse } from 'axios'
// Local
import { ERRORS_MAIN } from '@/constants'
import { clientsActions } from '@/bus/clients'
import { uiActions } from '@/bus/ui'
import type { Client } from '@/bus/clients'
// Local Dir
import { apiClients } from '../../api'
import type {
  FetchClientPointsAsync,
  FetchDetailAsync,
  FetchItemsAsync,
  FetchClientPointsHistoryAsync,
  FetchAllClientPointsHistoryAsync,
} from '../../types'

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    if (action.payload.searchString) {
      yield delay(600)
    }

    yield put(clientsActions.toggleLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<Client.ResFetchItems> = yield call(
      apiClients.fetchItems,
      action.payload
    )

    if (response.data) {
      yield put(
        clientsActions.saveItems({
          items: response.data.items,
          count: response.data.count,
        })
      )
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Clients fetchItems workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.toggleLoading(false))
  }
}

export function* fetchDetail(action: FetchDetailAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<Client.Detail> = yield call(
      apiClients.fetchDetail,
      action.payload
    )

    if (response.data) {
      yield put(clientsActions.saveDetail(response.data))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error.response.data, 'Clients fetchDetail workers')
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.toggleLoading(false))
  }
}

export function* fetchClientPoints(
  action: FetchClientPointsAsync
): SagaIterator {
  try {
    yield put(clientsActions.togglePointsLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<Client.ResPoints> = yield call(
      apiClients.fetchClientPoint,
      action.payload
    )

    if (response.data) {
      yield put(clientsActions.savePoints(response.data.points))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error.response.data, 'Clients fetchDetail workers')
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.togglePointsLoading(false))
  }
}

export function* fetchClientPointsHistory(
  action: FetchClientPointsHistoryAsync
): SagaIterator {
  try {
    yield put(clientsActions.togglePointsHistoryLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<Client.ResPointsHistoryItem[]> = yield call(
      apiClients.fetchClientPointsHistory,
      action.payload
    )

    if (response.data) {
      yield put(clientsActions.savePointsHistory(response.data))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response.data,
        'Clients fetchPointsHistory workers'
      )
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.togglePointsHistoryLoading(false))
  }
}
export function* fetchAllClientPointsHistory(
  action: FetchAllClientPointsHistoryAsync
): SagaIterator {
  try {
    yield put(clientsActions.togglePointsHistoryLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<Client.ResPointsHistoryItem[]> = yield call(
      apiClients.fetchAllClientPointsHistory
    )

    if (response.data) {
      yield put(clientsActions.getAllPoints(response.data))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response.data,
        'Clients fetchPointsHistory workers'
      )
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.togglePointsHistoryLoading(false))
  }
}
