import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from '@redux-saga/core'
import type { AxiosResponse } from 'axios'

// Local
import { ERRORS_MAIN } from '@/constants'
import { uiActions } from '@/bus/ui'
import { clientFilesActions } from '@/bus/client-files'
import type { ClientFiles } from '@/bus/client-files'
// Local Dir
import { apiClientFiles } from '../../api'
import type { CreateAsync } from '../../types'

export function* createTag(action: CreateAsync): SagaIterator {
  try {
    yield put(clientFilesActions.toggleLoadingHandler(true))

    const { data }: AxiosResponse<ClientFiles.ResCreateTag> = yield call(
      apiClientFiles.createTag,
      action.payload
    )

    if (data) {
      if (data.tags?.length > 1) {
        yield put(clientFilesActions.createTags(data.tags))
      } else {
        const [childTag] = data.tags

        yield put(
          clientFilesActions.createChildTag({
            childTag,
            parentId: childTag.parent.id,
          })
        )
      }

      yield put(clientFilesActions.toggleLoadingHandler(false))
      yield call(message.success, `Директорію створенно 📦`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error.response, 'FileTags createItem worker'))
    yield call(message.error, ERRORS_MAIN)
    yield put(clientFilesActions.toggleLoadingHandler(false))
  }
}
