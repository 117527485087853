import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
// Instruments
import { apiSettings } from '../api'
import { settingsAction } from '@/bus/settings'
import { uiActions } from '@/bus/ui'
import { ERRORS_MAIN } from '@/constants'

export function* fetchRoles() {
  try {
    const response = yield call(apiSettings.fetchRoles)

    if (response.data) {
      yield put(settingsAction.saveRoles(response.data.items))
    }
  } catch (error) {
    yield put(uiActions.emitError(error.response?.data, 'fetchRoles workers'))
    yield call(message.error, ERRORS_MAIN)
  }
}
