import React from 'react'
import { Layout } from 'antd'
// Local Dir
import { Footer } from '../components'
import { AsideMenu } from './AsideMenu'
import styles from './styles.module.scss'

type PropsTypes = {
  children: React.ReactNode
}

export const SharedLayout: React.FC<PropsTypes> = ({
  children,
}: PropsTypes) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AsideMenu />
      <Layout style={{ marginLeft: '240px' }}>
        <Layout.Content className={styles.root}>{children}</Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  )
}
