import type { ClientFiles } from './namespace'

export enum types {
  //Async
  CHANGE_TAG = 'CLIENT_FILES/CHANGE_TAG',
  CREATE_TAG = 'CLIENT_FILES/CREATE_TAG',
  DELETE_FILE = 'CLIENT_FILES/DELETE_FILE',
  FETCH_TAGS_CLIENT = 'CLIENT_FILES/FETCH_TAGS_CLIENT',
  CREATE_LINK = 'CLIENT_FILES/CREATE_LINK',
  FETCH_FILES_BY_LINK = 'CLIENT_FILES/FETCH_FILES_BY_LINK',
  FETCH_FILES_BY_TAG = 'CLIENT_FILES/FETCH_FILES_BY_TAG',
  FETCH_CLIENT_STL_FILES = 'CLIENT_FILES/FETCH_CLIENT_STL_FILES',
}

// STATE

export type ClientFilesState = {
  detailTag: ClientFiles.Detail
  createTags: ClientFiles.CreateTag[] // Потрібно на форму створення клієнта
  tags: ClientFiles.Tags[]
  tagByLink: ClientFiles.TagByLink | null
  stlFiles: ClientFiles.ClientFile[]
  link: string
  loading: boolean
  loadingLink: boolean
  loadingHandler: boolean
  loadingDetail: boolean
}

// ASYNC

export type ChangeTagAsync = {
  type: typeof types.CHANGE_TAG
  payload: ClientFiles.ReqChangeTag
}

export type CreateAsync = {
  type: typeof types.CREATE_TAG
  payload: ClientFiles.ReqCreateTag
}

export type DeleteFileAsync = {
  type: typeof types.DELETE_FILE
  payload: ClientFiles.ReqDeleteFile
}

export type FetchTagsClientAsync = {
  type: typeof types.FETCH_TAGS_CLIENT
  payload: ClientFiles.ReqFetchTagsClient
}

export type CreateLinkAsync = {
  type: typeof types.CREATE_LINK
  payload: ClientFiles.ReqCreateTagLink
}

export type FetchFilesByLinkAsync = {
  type: typeof types.FETCH_FILES_BY_LINK
  payload: string
}

export type FetchFilesByTagAsync = {
  type: typeof types.FETCH_FILES_BY_TAG
  payload: number
}

export type FetchClientStlFilesAsync = {
  type: typeof types.FETCH_CLIENT_STL_FILES
  payload: number
}

export type ClientFilesActionTypes =
  | ChangeTagAsync
  | CreateAsync
  | DeleteFileAsync
  | FetchTagsClientAsync
  | CreateLinkAsync
  | FetchFilesByLinkAsync
  | FetchFilesByTagAsync
  | FetchClientStlFilesAsync
