import { SagaIterator } from 'redux-saga'
import {
  SetDoneAsync,
  SetSentAsync,
  StartPrintAsync,
  StartProductionAsync,
} from '../../types'
import { call, put } from 'redux-saga/effects'
import { apiProductions } from '../../api'
import { message } from 'antd'
import { Productions, productionsActions } from '@/bus/productions'
import { uiActions } from '@/bus/ui'
import { ERRORS_MAIN } from '@/constants'
import { AxiosResponse } from 'axios'

export function* startPrint(action: StartPrintAsync): SagaIterator {
  try {
    // Api call or some async actions
    const response: AxiosResponse<Productions.ListItem> = yield call(
      apiProductions.startPrint,
      action.payload
    )

    if (response.status === 201 && response.data) {
      yield put(
        productionsActions.moveItem({
          item: response.data,
          from: 'created',
          to: 'printing',
        })
      )
      yield call(message.success, `Статус замовлення змінено`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Productions changeStatus workers'))
    yield call(message.error, ERRORS_MAIN)
  }
}

export function* startProduction(action: StartProductionAsync): SagaIterator {
  try {
    // Api call or some async actions
    const response: AxiosResponse<Productions.ListItem> = yield call(
      apiProductions.startProduction,
      action.payload
    )

    if (response.status === 201 && response.data) {
      yield put(
        productionsActions.moveItem({
          item: response.data,
          from: 'printing',
          to: 'production',
        })
      )
      yield call(message.success, `Статус замовлення змінено`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Productions changeStatus workers'))
    yield call(message.error, ERRORS_MAIN)
  }
}

export function* setDone(action: SetDoneAsync): SagaIterator {
  try {
    // Api call or some async actions
    const response: AxiosResponse<Productions.ListItem> = yield call(
      apiProductions.setDone,
      action.payload
    )

    if (response.status === 201 && response.data) {
      yield put(
        productionsActions.moveItem({
          item: response.data,
          from: 'production',
          to: 'done',
        })
      )
      yield call(message.success, `Статус замовлення змінено`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Productions changeStatus workers'))
    yield call(message.error, ERRORS_MAIN)
  }
}

export function* setSent(action: SetSentAsync): SagaIterator {
  try {
    // Api call or some async actions
    const response: AxiosResponse<Productions.ListItem> = yield call(
      apiProductions.setSent,
      action.payload
    )

    if (response.status === 201 && response.data) {
      yield put(
        productionsActions.moveItem({
          item: response.data,
          from: 'done',
          to: 'sent',
        })
      )
      yield call(message.success, `Статус замовлення змінено`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Productions changeStatus workers'))
    yield call(message.error, ERRORS_MAIN)
  }
}
