import io from 'socket.io-client'
import { eventsObserver } from './observable.service'
import ENV from '@/configs'
import authService from './AuthService'

export class SocketIo {
  private socket: any

  constructor() {
    this.init()
  }

  init() {
    this.socket = io(ENV.SOCKET_URL, {
      transports: ['websocket', 'webtransport', 'polling'],
      forceNew: true,
      reconnection: true,
      reconnectionAttempts: 10,
    })

    console.log('INIT SOCKET', ENV.SOCKET_URL)

    this.socket.on('connect_error', (error) => {
      console.log('Connection Error:', error)
    })

    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason)
    })

    this.socket.on('connect', () => {
      console.log('CONNECTED')
      this.emit('auth')
      this.initSockets()
    })
  }

  get header() {
    return {
      authorization: 'Bearer ' + authService.getAccessToken(),
    }
  }

  _on(key, action) {
    this.socket.on(key, (data) => action(data))
  }

  _onSocketSendEvent(key) {
    this.socket.on(key, (data) => eventsObserver.broadcast(key, data))
  }

  _off(key) {
    this.socket.off(key)
  }

  emit(key, data: any = {}) {
    data.headers = this.header
    this.socket.emit(key, data)
  }

  initSockets() {
    this._onSocketSendEvent('onWalletChange')
    this._onSocketSendEvent('onUpdateInteractiveNotifications')

    this._on('onUpdateInteractiveNotifications', () => {
      console.log('ALERTSASDASDSADASDASDS')
    })
  }

  static instance: SocketIo = null

  static get(): SocketIo {
    if (this.instance) return this.instance

    this.instance = new SocketIo()
    return this.instance
  }
}
