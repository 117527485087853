import React from 'react'
import moment from 'moment'
import type { ColumnsType } from 'antd/lib/table/interface'
import { Link } from 'react-router-dom'
// Typing
import { Item } from './RatingTable'
import { Rate } from 'antd'
import { RouteEnum } from '@/configs/RouteEnum'
import styles from './styles.module.scss'

export const useColumns = () => {
  const columns: ColumnsType<Item> = [
    {
      title: 'ПІБ клієнта',
      dataIndex: ['clientName'],
      key: 'clientName',
      width: '20%',
      render: (value: string, item) => {
        return (
          <Link
            to={`${RouteEnum.Clients}/${item.clientId}`}
            className={styles.link}
          >
            {value}
          </Link>
        )
      },
    },
    {
      title: 'Партнер',
      dataIndex: ['partnerName'],
      key: 'partnerName',
      width: '20%',
      render: (value: string, item) => {
        return (
          <Link
            to={`${RouteEnum.Partners}/${item.partnerId}`}
            className={styles.link}
          >
            {value}
          </Link>
        )
      },
    },
    {
      title: 'Оцiнка',
      dataIndex: ['rating'],
      key: 'id',
      width: '15%',
      render: (value) => {
        return <Rate disabled defaultValue={value} />
      },
    },
    {
      title: 'Комментар',
      dataIndex: ['comment'],
      key: 'id',
      width: '30%',
    },
    {
      title: 'Дата',
      dataIndex: 'createDate',
      key: 'createDate',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (value) => moment(value).format('DD.MM.YYYY'),
    },
  ]

  return columns
}
