import type { FC, ReactNode } from 'react'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
// Local
import { RootState } from '@/core/store/rootReducer'
import { authSelectors } from '@/bus/auth'
import { RouteEnum } from '@/configs/RouteEnum'
import { ModalPhotoComparison } from "@/smart-components"

interface AuthGuardProps {
  children?: ReactNode
}

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const isAuth = useSelector((state: RootState) =>
    authSelectors.isAuthenticate(state)
  )

  if (!isAuth) {
    return <Redirect to={RouteEnum.SignIn} />
  }

  return <>
    {children}
    <ModalPhotoComparison />
  </>
}
