import type { Partner } from './namespace'
import type { Client } from '@/bus/clients'

export enum types {
  //Async
  FETCH_ITEMS = 'PARTNERS/FETCH_ITEMS',
  FETCH_DETAIL = 'PARTNERS/FETCH_DETAIL',
  FETCH_CLIENTS = 'PARTNERS/FETCH_CLIENTS',
  CREATE_ITEM = 'PARTNERS/CREATE_ITEM',
  CHANGE_ITEM = 'PARTNERS/CHANGE_ITEM',
  CHANGE_PRICE = 'PARTNERS/CHANGE_PRICE',
  DELETE_ITEM = 'PARTNERS/DELETE_ITEM',
  ACTIVATE = 'PARTNERS/ACTIVATE',
  DEACTIVATE = 'PARTNERS/DEACTIVATE',
  TOGGLE_REFERRAL = 'PARTNERS/TOGGLE_REFERRAL',
  TOGGLE_INFORMING_ENABLED = 'PARTNERS/TOGGLE_INFORMING_ENABLED',
}

// STATE

export type PartnersState = {
  items: Partner.Item[] | []
  count: number
  detail: Partner.Detail | null
  clients: Client.Item[] | []
  countClients: number
  loading: boolean
  loadingReferral: boolean
  isInspe: boolean
}

// ASYNC

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS
  payload: Partner.ReqFetchItems
}

export type FetchDetailAsync = {
  type: typeof types.FETCH_DETAIL
  payload: number
}

export type FetchClientsAsync = {
  type: typeof types.FETCH_CLIENTS
  payload: { id: number; params: Client.ReqFetchItems }
}

export type CreateAsync = {
  type: typeof types.CREATE_ITEM
  payload: Partner.ReqCreate
}

export type ChangeAsync = {
  type: typeof types.CHANGE_ITEM
  payload: Partner.ReqChange & { isChangeAccount: boolean }
}

export type ChangeAsyncPrice = {
  type: typeof types.CHANGE_PRICE
  payload: Partner.ReqChangePrice & { isChangePrice: boolean }
}

export type DeleteAsync = {
  type: typeof types.DELETE_ITEM
  payload: number
}

export type ActivateAsync = {
  type: typeof types.ACTIVATE
  payload: number
}

export type DeactivateAsync = {
  type: typeof types.DEACTIVATE
  payload: number
}

export type ToggleReferralAsync = {
  type: typeof types.TOGGLE_REFERRAL
  payload: {
    isAllow: boolean
    request: number
  }
}

export type ToggleInformingEnabledAsync = {
  type: typeof types.TOGGLE_INFORMING_ENABLED
  payload: {
    isInspe: boolean
    userId: number
  }
}

export type PartnersActionTypes =
  | FetchItemsAsync
  | FetchDetailAsync
  | FetchClientsAsync
  | CreateAsync
  | ChangeAsync
  | DeleteAsync
  | ActivateAsync
  | DeactivateAsync
  | ToggleReferralAsync
  | ChangeAsyncPrice
  | ToggleInformingEnabledAsync
