import React, { FC } from 'react'
import { Product } from '@/bus/products'
import { apiProducts } from '@/bus/products/api'

import { usePaginationList } from '@/hooks/usePaginationList'
import { Button, Collapse, Row, Skeleton } from 'antd'

import styles from './styles.module.scss'
import { FileOutlined, LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'

const { Panel } = Collapse

interface ISelectDocumentTemplateProps {
  onSelect: (template: Product.Template) => void
  selectedTemplate: Product.Template
  limit?: number
}

export const SelectDocumentTemplate: FC<ISelectDocumentTemplateProps> = ({
  onSelect,
  selectedTemplate,
  limit = 20,
}) => {
  const list = usePaginationList<Product.Item>({
    fetchItems: apiProducts.fetchItems,
    limit,
  })

  const renderTemplate = (template: Product.Template) => {
    const isActive = selectedTemplate?.id === template.id
    return (
      <Row
        align="middle"
        justify="space-between"
        className={clsx(styles.template, isActive ? styles.isActive : null)}
      >
        <p>
          <FileOutlined style={{ marginRight: 10 }} />
          {template.title}
        </p>
        <Button
          type={isActive ? 'primary' : 'default'}
          size="small"
          onClick={() => onSelect(isActive ? null : template)}
        >
          {isActive ? 'Відмінити' : 'Обрати'}
        </Button>
      </Row>
    )
  }

  const renderSection = (section: Product.Item) => {
    if (!Array.isArray(section.templates) || !section.templates.length)
      return null

    return (
      <Panel header={section.productName} key={section.id}>
        {section.templates.map(renderTemplate)}
      </Panel>
    )
  }

  const renderContent = () => {
    if (list.isLoading) return <LoadingOutlined />

    return <Collapse bordered={false}>{list.items.map(renderSection)}</Collapse>
  }
  return <div>{renderContent()}</div>
}
