import type { City } from './namespace'

export enum types {
   //Async
   FETCH_ITEMS = 'CITIES/FETCH_ITEMS',
}

// STATE
export type CitiesState = {
   items: City.Item[] | []
   count: number
   loading: boolean
}

// ASYNC
export type FetchItemsAsync = {
   type: types.FETCH_ITEMS
   payload: City.ReqFetchItems
}

export type CitiesActionTypes =
   | FetchItemsAsync
