import { Client } from '@/bus/clients'
import moment from 'moment'
import React from 'react'
import { FC } from 'react'
import styles from './styles.module.scss'

interface IInfoProps {
  production: Client.ProductionsHistoryItem
}

export const ProductionInfo: FC<IInfoProps> = ({ production }) => {
  return (
    <div className={styles.productionInfo__container}>
      <div className={styles.productionInfo__textRow}>{`${moment(
        production.createDate
      ).format('DD.MM.YYYY HH:mm')} - створено замовлення (${
        production.createResp
      })`}</div>
      {production.startPrintDate && (
        <div className={styles.productionInfo__textRow}>{`${moment(
          production.startPrintDate
        ).format('DD.MM.YYYY HH:mm')} - відправлено на 3D друк (${
          production.printResp
        })`}</div>
      )}
      {production.startProductionDate && (
        <div className={styles.productionInfo__textRow}>{`${moment(
          production.startProductionDate
        ).format('DD.MM.YYYY HH:mm')} - отримано на виробництво (${
          production.productionResp
        })`}</div>
      )}
      {production.doneDate && (
        <div className={styles.productionInfo__textRow}>{`${moment(
          production.doneDate
        ).format('DD.MM.YYYY HH:mm')} - виготовлено (${
          production.doneResp
        })`}</div>
      )}
      {production.sendDate && (
        <div className={styles.productionInfo__textRow}>{`${moment(
          production.sendDate
        ).format('DD.MM.YYYY HH:mm')} - відправлено (${
          production.sendResp
        })`}</div>
      )}
      {production.ttn && (
        <div
          className={styles.productionInfo__textRow}
        >{`TTH - ${production.ttn}`}</div>
      )}
    </div>
  )
}
