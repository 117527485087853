import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import AuthService from '@/services/AuthService'
import { RouteEnum } from '@/configs/RouteEnum'
import { authActions } from '@/bus/auth'

export function* logout(): SagaIterator {
  yield call(AuthService.logout)

  yield put(authActions.logout())
  yield put(push(RouteEnum.SignIn))
}
