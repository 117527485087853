import { useState } from 'react'
// Local
import { toggleScroll } from '@/helpers/toggleScroll'

export const useDrawer = () => {
  const [isShowDrawer, setShowDrawer] = useState(false)
  const [drawerData, setDrawerData] = useState<any>("")

  const showDrawer = (data: any = null) => {
    setDrawerData(data)
    toggleScroll(true)
    setShowDrawer(true)
  }

  const closeDrawer = () => {
    toggleScroll(false)
    setShowDrawer(false)
  }
  return {
    isShowDrawer,
    showDrawer,
    closeDrawer,
    drawerData,
  }
}
