import { useEffect } from 'react'
import { eventsObserver } from '../services/observable.service'

export const useEventsListener = (event, action, dependencies = []) => {
  useEffect(() => {
    const fn = (data) => action(data)
    eventsObserver.subscribe(event, fn)
    return () => eventsObserver.unsubscribe(event, fn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
