import { Rating } from './namespace'

export enum types {
  //Async
  FETCH_ITEMS = 'FETCH_RATING',
}

// STATE

export type RatingState = {
  items: Rating.Item[]
  count: number
  loading: boolean
}

// ASYNC

export type FetchItemsAsync = {
  type: types.FETCH_ITEMS
  payload?: Rating.ReqFetchItems
}

export type RatingActionTypes = FetchItemsAsync
