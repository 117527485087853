import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet'

type PropsTypes = {
  children: React.ReactNode
  title: string
  className?: string
  rest?: [string]
}

export type Ref = HTMLDivElement

export const Page: React.FC<PropsTypes> = forwardRef<Ref, PropsTypes>(
  (props, ref) => {
    const { title, children, className, ...rest } = props

    return (
      <div ref={ref} className={className} {...rest}>
        <Helmet>
          <title>{title} | Impress</title>
        </Helmet>
        {children}
      </div>
    )
  }
)
