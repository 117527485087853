import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from '@redux-saga/core'
import type { AxiosResponse } from 'axios'
// Local
import { ERRORS_MAIN } from '@/constants'
import { partnersActions } from '@/bus/partners'
import { uiActions } from '@/bus/ui'
import { authActions } from '@/bus/auth'
import type { Partner } from '@/bus/partners'
import type { Account } from '@/bus/auth'
// Local Dir
import { apiPartners } from '../../api'
import type { ChangeAsync, ToggleInformingEnabledAsync } from '../../types'

function isPartnerDetail(
  value: Account.CurrentAccount | Partner.Detail
): value is Partner.Detail {
  return (value as Partner.Detail).partnerInfo !== undefined
}

export function* changeItem(action: ChangeAsync): SagaIterator {
  try {
    yield put(uiActions.startFetching())
    // Api call or some async actions
    const { data }: AxiosResponse<Account.CurrentAccount | Partner.Detail> =
      yield call(apiPartners.change, {
        id: action.payload.id,
        data: action.payload.data,
      })

    if (data) {
      if (action.payload.isChangeAccount) {
        if (isPartnerDetail(data)) {
          let changeData: Account.CurrentAccount
          const partnerInfo = data.partnerInfo

          changeData = {
            ...data,
            info: {
              firstName: partnerInfo.firstName,
              lastName: partnerInfo.lastName,
              middleName: partnerInfo.middleName,
              phoneNumber: partnerInfo.phoneNumber,
              photoUrl: partnerInfo.photoUrl,
              region: partnerInfo.region,
              clinicName: partnerInfo.clinicName,
              contactName: partnerInfo.contactName,
              contactPhoneNumber: partnerInfo.contactPhoneNumber,
              // telegram: partnerInfo.telegram,
              // viber: partnerInfo.viber,
            },
          }

          yield put(authActions.changeAccount(changeData))
        }
      } else {
        yield put(partnersActions.changeItem(data as Partner.Detail))
      }
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Partners changeItem workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}

export function* toggleInformingEnabled({
  payload,
}: ToggleInformingEnabledAsync) {
  try {
    yield put(partnersActions.toogleIsInspe(true))

    // Api call or some async actions
    yield call(apiPartners.setIsInspe, payload)
    if (payload.isInspe)
      yield call(message.success, 'Сповіщення в телеграм дозволено 🎉')
    else yield call(message.warning, 'Сповіщення в телеграм вимкнено 🚧')

    yield put(partnersActions.toggleInformingEnabled(payload.isInspe))
    yield put(partnersActions.toogleIsInspe(false))
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response?.data,
        'Partners informing enabled workers'
      )
    )
    yield call(message.error, ERRORS_MAIN)
    yield put(partnersActions.toogleIsInspe(false))
  }
}
