import type { AxiosPromise } from 'axios'

import axios from '@/services/axios'
import type { Client } from '@/bus/clients'
import type { Partner } from './namespace'

export const apiPartners = new (class Api {
  fetchItems(
    params: Partner.ReqFetchItems
  ): AxiosPromise<Partner.ResFetchItems> {
    return axios({
      url: '/partners',
      method: 'get',
      params,
    })
  }

  fetchDetail(id: number): AxiosPromise<Partner.ResFetchDetail> {
    return axios({
      url: `/partners/${id}`,
      method: 'get',
    })
  }

  /**
   * Получить клиенты партнера
   */
  fetchClients({
    id,
    params,
  }: Partner.ReqFetchClients): AxiosPromise<Client.ResFetchItems> {
    return axios({
      url: `/partners/${id}/clients`,
      method: 'get',
      params,
    })
  }

  create(data: Partner.ReqCreate): AxiosPromise {
    return axios({
      url: '/partners',
      method: 'post',
      data,
    })
  }

  change({ id, data }: Partner.ReqChange): AxiosPromise {
    return axios({
      url: `/partners/${id}`,
      method: 'patch',
      data,
    })
  }

  changePrice({ id, data }: Partner.ReqChangePrice): AxiosPromise {
    return axios({
      url: `/partners/${id}/diagnostic-price`,
      method: 'patch',
      data,
    })
  }

  activate(id: number): AxiosPromise {
    return axios({
      url: `/users/${id}/activate`,
      method: 'post',
    })
  }

  deactivate(id: number): AxiosPromise {
    return axios({
      url: `/users/${id}/deactivate`,
      method: 'post',
    })
  }

  referralAllow(userId: number): AxiosPromise {
    return axios({
      url: `/referral/allow`,
      method: 'post',
      data: {
        userId,
      },
    })
  }

  referralDisallow(userId: number): AxiosPromise {
    return axios({
      url: `/referral/disallow`,
      method: 'post',
      data: {
        userId,
      },
    })
  }

  setIsInspe({ userId, isInspe }: Partner.ReqSetIsInspe): AxiosPromise {
    return axios({
      url: `partners/${userId}/is-inspe`,
      method: 'patch',
      data: {
        isInspe,
      },
    })
  }
})()
