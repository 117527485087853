import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import ukUa from 'antd/es/locale/uk_UA'
import 'moment/locale/uk'
import { hot } from 'react-hot-loader/root'
// Local
import { authActions, authSelectors } from '@/bus/auth'
import routes, { renderRoutes } from '@/core/router'
import { Fallback } from '@/components'
import { citiesActions } from './bus/cities'

moment.locale('uk')

const App: React.FC = () => {
  const dispatch = useDispatch()
  const isInitialized = useSelector(authSelectors.isInitialized)

  useEffect(() => {
    dispatch(authActions.initializedAsync())
    dispatch(
      citiesActions.fetchItemsAsync({
        limit: 50,
        page: 1,
      })
    )
  }, [dispatch])

  if (!isInitialized) {
    return <Fallback />
  }

  return <ConfigProvider locale={ukUa}>{renderRoutes(routes)}</ConfigProvider>
}

export default hot(App)
