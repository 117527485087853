export enum types {
  //Sync
  EMIT_ERROR = 'UI/EMIT_ERROR',
}

export type UiState = {
  isFetching: boolean
}

export type EmitErrorAction = {
  type: typeof types.EMIT_ERROR
  payload: any
  error: boolean
  meta: string | null
}
