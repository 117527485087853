import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import copy from 'copy-to-clipboard'
import type { SagaIterator } from 'redux-saga'
import type { AxiosResponse } from 'axios'
// Local
import type { ClientFiles } from '@/bus/client-files'
import { clientFilesActions } from '@/bus/client-files'
import { uiActions } from '@/bus/ui'
import { ERRORS_MAIN } from '@/constants'
// Local Dir
import { apiClientFiles } from '../../api'
import { CreateLinkAsync, FetchFilesByLinkAsync } from '../../types'

export function* fetchFilesByLink(action: FetchFilesByLinkAsync): SagaIterator {
  try {
    yield put(clientFilesActions.toggleLoadingLink(true))

    // Api call or some async actions
    const response: AxiosResponse<ClientFiles.TagByLink> = yield call(
      apiClientFiles.fetchFilesByLink,
      action.payload
    )

    if (response.data) {
      yield put(clientFilesActions.saveTagByLink(response.data))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response.data,
        'client-files fetchFilesByLink workers'
      )
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientFilesActions.toggleLoadingLink(false))
  }
}

export function* createLink(action: CreateLinkAsync): SagaIterator {
  try {
    const key = 'updatable'

    yield put(clientFilesActions.toggleLoadingLink(true))

    const hide = message.loading({
      content: `Посилання створюється...`,
      duration: 0,
      key,
    })

    const response: AxiosResponse<ClientFiles.ResTagLink> = yield call(
      apiClientFiles.createTagLink,
      action.payload
    )

    if (response.data) {
      yield put(clientFilesActions.saveLink(response.data.key))
      const link = `${window.location.origin}/folders/${response.data.key}`

      yield put(clientFilesActions.toggleLoadingLink(false))
      yield call(copy, link)
      setTimeout(hide, 100)

      yield call(message.success, {
        content: 'Посилання скопійовано.',
        key,
      })
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error.response, 'client-files createLink worker')
    )
    yield call(message.error, ERRORS_MAIN)
    yield put(clientFilesActions.toggleLoadingLink(false))
  }
}
