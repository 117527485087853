import { createSlice } from '@reduxjs/toolkit'
import { EmitErrorAction, types, UiState } from './types'

const initialState: UiState = {
  isFetching: false,
}

const slice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    startFetching: (state: UiState) => {
      state.isFetching = true
    },
    stopFetching: (state: UiState) => {
      state.isFetching = false
    },
  },
})

export default slice.reducer

export const uiActions = {
  ...slice.actions,
  emitError: (error: any, meta: string | null = null): EmitErrorAction => ({
    type: types.EMIT_ERROR,
    payload: error,
    error: true,
    meta,
  }),
}
