import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
// Local
import { ERRORS_MAIN } from '@/constants'
import { uiActions } from '@/bus/ui'
import { partnersActions } from '@/bus/partners'
// Local Dir
import { ToggleReferralAsync } from '../../types'
import { apiPartners } from '../../api'

export function* toggleReferral({ payload }: ToggleReferralAsync) {
  try {
    yield put(partnersActions.toggleLoadingReferral(true))

    // Api call or some async actions
    if (payload.isAllow) {
      yield call(apiPartners.referralAllow, payload.request)

      yield call(message.success, 'Реферальна система активована 🎉')
      yield put(partnersActions.toggleReferral(true))
    } else {
      yield call(apiPartners.referralDisallow, payload.request)

      yield call(message.warning, 'Реферальна система деактивована 🚧')
      yield put(partnersActions.toggleReferral(false))
    }

    yield put(partnersActions.toggleLoadingReferral(false))
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response?.data,
        'Partners referralAllow workers'
      )
    )
    yield call(message.error, ERRORS_MAIN)
    yield put(partnersActions.toggleLoadingReferral(false))
  }
}
