//Core
import { SagaIterator } from '@redux-saga/core'
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
// Instruments
import { types } from '../types'
// Workers
import {
  changeAccount,
  confirmEmail,
  fetchAccount,
  initialize,
  logout,
  resetPassword,
  resetPasswordCheck,
  resetPasswordEnd,
  signIn,
} from './workers'
import { fetchAccountWallet } from './workers/account.workers'

function* watchSignIn(): SagaIterator {
  yield takeEvery(types.SIGN_IN_ASYNC, signIn)
}

function* watchLogout(): SagaIterator {
  yield takeEvery(types.LOGOUT_ASYNC, logout)
}

function* watchInitialize(): SagaIterator {
  yield takeEvery(types.INITIALIZED_ASYNC, initialize)
}

function* watchConfirmEmail(): SagaIterator {
  yield takeEvery(types.CONFIRM_EMAIL_ASYNC, confirmEmail)
}

function* watchResetPasswordStart(): SagaIterator {
  yield takeEvery(types.RESET_PASSWORD_START_ASYNC, resetPassword)
}

function* watchResetPasswordCheck(): SagaIterator {
  yield takeEvery(types.RESET_PASSWORD_CHECK_ASYNC, resetPasswordCheck)
}

function* watchResetPasswordEnd(): SagaIterator {
  yield takeEvery(types.RESET_PASSWORD_END_ASYNC, resetPasswordEnd)
}

function* watchChangeAccount(): SagaIterator {
  yield takeEvery(types.CHANGE_ACCOUNT_ASYNC, changeAccount)
}

function* watchFetchAccount(): SagaIterator {
  yield takeEvery(types.FETCH_ACCOUNT_ASYNC, fetchAccount)
}

function* watchFetchAccountWallet(): SagaIterator {
  yield takeLatest(types.FETCH_WALLET_ASYNC, fetchAccountWallet)
}

export function* watchAuth(): SagaIterator {
  yield all([
    call(watchInitialize),
    call(watchLogout),
    call(watchConfirmEmail),
    call(watchResetPasswordStart),
    call(watchResetPasswordCheck),
    call(watchResetPasswordEnd),
    call(watchSignIn),
    call(watchChangeAccount),
    call(watchFetchAccount),
    call(watchFetchAccountWallet),
  ])
}
