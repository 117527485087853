import { AxiosPromise } from 'axios'
import axios from '@/services/axios'

export const apiInteractiveNotifications = new (class Api {
  fetch(params: any): AxiosPromise<any> {
    return axios({
      url: '/interactive-notifications',
      method: 'get',
      params,
    })
  }
  count(params: any): AxiosPromise<number> {
    return axios({
      url: '/interactive-notifications/count',
      method: 'get',
      params,
    })
  }
})()
