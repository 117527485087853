import { eventsObserver } from '@/services/observable.service'
import { message } from 'antd'
import { call } from 'redux-saga/effects'
import { apiProducts } from '../../api'
import { DeleteProductAsync, DeleteProductTemplateAsync } from '../../types'

export function* deleteProduct(action: DeleteProductAsync) {
  try {
    yield call(apiProducts.delete, action.payload)

    eventsObserver.broadcast('onProductChange', null)
  } catch (e) {
    yield call(message.error, 'Сталась помилка, цю послугу не можливо видалити')
  }
}

export function* deleteProductTemplate(action: DeleteProductTemplateAsync) {
  try {
    yield call(apiProducts.deleteTemplate, action.payload)
  } catch (e) {
    console.log('Some error', e)
  }
}
