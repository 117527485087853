import { call, put, select } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from '@redux-saga/core'
import type { AxiosResponse } from 'axios'

import type { Client } from '@/bus/clients'
// Local
import { FileMimetypeEnum } from '@/configs/BackendEnum'
import { DefaultFoldersEnum } from '@/configs/default-folders.enum'
import { uiActions } from '@/bus/ui'
import { clientsActions } from '@/bus/clients'
import { ClientFiles, clientFilesActions } from '@/bus/client-files'
import { apiClientFiles } from '@/bus/client-files/api'
import { ERRORS_MAIN } from '@/constants'
// Local Dir
import { apiClients } from '../../api'
import type { CreateAsync, CreateNotificationAsync } from '../../types'
import { authSelectors } from '@/bus/auth'
import { partnersSelectors } from '@/bus/partners'

export function* createItem(action: CreateAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleLoading(true))

    const response: AxiosResponse<Client.Item> = yield call(
      apiClients.create,
      action.payload
    )

    if (response.data) {
      const clientId = response.data.id
      // yield call(() => apiClients.createDentalAnamnesis(clientId, action.payload.dentalAnamnesis))

      const responseFileTags: AxiosResponse<ClientFiles.ResCreateTag> =
        yield call(apiClientFiles.createTag, {
          clientId,
          tags: [
            {
              name: DefaultFoldersEnum.PHOTO,
              requiredMimetypes: [FileMimetypeEnum.img],
              parentId: null,
            },
            {
              name: DefaultFoldersEnum.FILE_SCANNING,
              requiredMimetypes: [FileMimetypeEnum.stl],
              parentId: null,
            },
            {
              name: DefaultFoldersEnum.FILE_SMILE_DESIGN,
              requiredMimetypes: [FileMimetypeEnum.stl],
              parentId: null,
            },
            {
              name: DefaultFoldersEnum.FILE_ELINERS,
              requiredMimetypes: [FileMimetypeEnum.stl],
              parentId: null,
            },
            {
              name: DefaultFoldersEnum.FILE_KT,
              requiredMimetypes: [FileMimetypeEnum.zip],
              parentId: null,
            },
            {
              name: DefaultFoldersEnum.DOCUMENTS,
              requiredMimetypes: [
                FileMimetypeEnum.stl,
                FileMimetypeEnum.img,
                FileMimetypeEnum.doc,
              ],
              parentId: null,
            },
          ],
        })

      const defaultFoldersName = (name: string): string => {
        switch (name) {
          case DefaultFoldersEnum.PHOTO:
            return 'До лікування'
          case DefaultFoldersEnum.FILE_SCANNING:
            return 'Початкове сканування'
          case DefaultFoldersEnum.FILE_KT:
            return 'КТ'
          case DefaultFoldersEnum.DOCUMENTS:
            return 'Договори/Акти'
          default:
            return 'Стартові файли'
        }
      }

      if (responseFileTags?.data?.tags?.length) {
        const parentsFilesTag = responseFileTags.data.tags.filter((tag) => {
          return (
            tag.name === DefaultFoldersEnum.PHOTO ||
            tag.name === DefaultFoldersEnum.FILE_SCANNING ||
            tag.name === DefaultFoldersEnum.FILE_KT ||
            tag.name === DefaultFoldersEnum.DOCUMENTS
          )
        })

        const responseChildrenTag: AxiosResponse<ClientFiles.ResCreateTag> =
          yield call(apiClientFiles.createTag, {
            clientId,
            tags: parentsFilesTag.map((parentTag) => ({
              name: defaultFoldersName(parentTag.name),
              requiredMimetypes: parentTag.requiredMimetypes,
              parentId: parentTag.id,
            })),
          })

        yield put(
          clientFilesActions.saveCreateTags(responseChildrenTag.data.tags)
        )

        const isAdmin = yield select(authSelectors.isAdmin)
        const isInspe = yield select(partnersSelectors.isInspe)

        const needGoToPassportData = isAdmin || isInspe

        yield put(clientsActions.createItem(response.data))
        yield put(clientsActions.changeStep(needGoToPassportData ? 1 : 2))
        yield put(clientsActions.toggleLoading(false))
        yield call(
          message.success,
          `Пацієнт ${response.data.clientInfo.name} створенний 🎉`
        )
      }
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error.response?.data, 'Failed to create client!')
    )
    if (error.response.status === 409) {
      yield call(
        message.error,
        'Користувач з такою поштовою адресою вже існує!'
      )
    } else {
      yield call(message.error, ERRORS_MAIN)
    }
    yield put(clientsActions.toggleLoading(false))
  }
}

export function* createNotification(
  action: CreateNotificationAsync
): SagaIterator {
  try {
    const response: AxiosResponse = yield call(
      apiClients.createNotification,
      action.payload
    )
    if (response.status === 201) {
      yield call(message.success, `Push повідомлення відправлено`)
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error.response?.data, 'Failed to create send push!')
    )
    yield call(message.error, ERRORS_MAIN)
  }
}
