import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// Local
import { types } from './types'
import type { Partner } from './namespace'
import type { PartnersActionTypes, PartnersState } from './types'
import type { Client } from '@/bus/clients'
import { act } from 'react-dom/test-utils'

const initialState: PartnersState = {
  items: [],
  count: 0,
  detail: null,
  clients: [],
  countClients: 0,
  loading: false,
  loadingReferral: false,
  isInspe: false,
}

const slice = createSlice({
  name: 'PARTNERS',
  initialState,
  reducers: {
    saveItems: (
      state: PartnersState,
      action: PayloadAction<Partner.ResFetchItems>
    ) => {
      state.items = action.payload.items
      state.count = action.payload.count
    },
    saveClients: (
      state: PartnersState,
      action: PayloadAction<Client.ResFetchItems>
    ) => {
      state.clients = action.payload.items
      state.countClients = action.payload.count
    },
    saveDetail: (
      state: PartnersState,
      action: PayloadAction<Partner.ResFetchDetail>
    ) => {
      state.detail = action.payload
    },
    createItem: (state: PartnersState, action: PayloadAction<Partner.Item>) => {
      state.items = [action.payload, ...state.items]
    },
    clearDetail: (state: PartnersState) => {
      state.detail = null
    },
    changeItem: (
      state: PartnersState,
      action: PayloadAction<Partner.Detail>
    ) => {
      // console.log('SDDiagnosticsPrice', state.detail)
      state.detail = {
        ...state.detail,
        ...action.payload,
        partnerInfo: {
          ...state.detail.partnerInfo,
          ...action.payload.partnerInfo,
        },
      }
    },
    toggleReferral: (state: PartnersState, action: PayloadAction<boolean>) => {
      state.detail.isAbleToUseReferral = action.payload
    },
    toggleLoading: (state: PartnersState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    toggleLoadingReferral: (
      state: PartnersState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingReferral = action.payload
    },
    activate: (state: PartnersState) => {
      state.detail = {
        ...state.detail,
        isActive: true,
      }
    },
    deactivate: (state: PartnersState) => {
      state.detail = {
        ...state.detail,
        isActive: false,
      }
    },
    toggleInformingEnabled: (
      state: PartnersState,
      action: PayloadAction<boolean>
    ) => {
      state.detail.partnerInfo.isInspe = action.payload
    },
    toogleIsInspe: (state: PartnersState, action: PayloadAction<boolean>) => {
      state.isInspe = action.payload
    },
  },
})

export default slice.reducer

// Action Creators Async
export const partnersActions = {
  ...slice.actions,
  fetchItemsAsync: (payload: Partner.ReqFetchItems): PartnersActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
  fetchDetailAsync: (payload: number): PartnersActionTypes => ({
    type: types.FETCH_DETAIL,
    payload,
  }),
  fetchClientsAsync: (payload: {
    id: number
    params: Client.ReqFetchItems
  }): PartnersActionTypes => ({
    type: types.FETCH_CLIENTS,
    payload,
  }),
  createAsync: (payload: Partner.ReqCreate): PartnersActionTypes => ({
    type: types.CREATE_ITEM,
    payload,
  }),
  changeAsync: (
    payload: Partner.ReqChange & { isChangeAccount: boolean }
  ): PartnersActionTypes => ({
    type: types.CHANGE_ITEM,
    payload,
  }),
  changeAsyncPrice: (
    payload: Partner.ReqChangePrice & { isChangePrice: boolean }
  ): PartnersActionTypes => ({
    type: types.CHANGE_PRICE,
    payload,
  }),
  deleteAsync: (payload: number): PartnersActionTypes => ({
    type: types.DELETE_ITEM,
    payload,
  }),
  activateAsync: (payload: number): PartnersActionTypes => ({
    type: types.ACTIVATE,
    payload,
  }),
  deactivateAsync: (payload: number): PartnersActionTypes => ({
    type: types.DEACTIVATE,
    payload,
  }),
  toggleReferralAsync: (payload: {
    isAllow: boolean
    request: number
  }): PartnersActionTypes => ({
    type: types.TOGGLE_REFERRAL,
    payload,
  }),
  toggleInformingEnabledAsync: (payload: {
    isInspe: boolean
    userId: number
  }): PartnersActionTypes => ({
    type: types.TOGGLE_INFORMING_ENABLED,
    payload,
  }),
}
