import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
// Local
import { authSelectors } from '@/bus/auth'

type PropsTypes = {
  component: React.FC
  path: string
  [x: string]: any
}

export const PartnerGuard: React.FC<PropsTypes> = ({
  path,
  component: Component,
  ...rest
}: PropsTypes) => {
  const isAdmin = useSelector(authSelectors.isAdmin)
  const isPartner = useSelector(authSelectors.isPartner)

  if (!isAdmin && !isPartner) {
    return null
  }

  return <Route path={path} component={Component} {...rest} />
}
