import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// Local
import { ClientStatusAlias } from '@/configs/BackendEnum'
import { types, UpdateIllnessAsync, UpdatePassportDataAsync } from './types'
import type {
  ClientsActionTypes,
  ClientsState,
  UpdateEligibleAsync,
  UpdateTreatmentPackageAsync,
} from './types'
import type { Client, ClientTreatmentPackage } from './namespace'

const initialState: ClientsState = {
  items: [],
  detail: null,
  count: 0,
  loading: false,
  editClient: null, // для стоврення нового клієнта
  currentStep: 0,
  points: null,
  allPoints: [],
  loadingPoints: false,
  pointsHistory: [],
  loadingPointsHistory: false,
  activationCode: null,
  loadingActivationCode: false,
  productionsHistory: [],
  loadingProductionsHistory: false,
}

const slice = createSlice({
  name: 'CLIENTS',
  initialState,
  reducers: {
    saveItems: (
      state: ClientsState,
      action: PayloadAction<Client.ResFetchItems>
    ) => {
      state.items = action.payload.items
      state.count = action.payload.count
    },
    saveDetail: (state: ClientsState, action: PayloadAction<Client.Detail>) => {
      state.detail = action.payload
    },
    saveEditClient: (
      state: ClientsState,
      action: PayloadAction<Client.Detail>
    ) => {
      state.editClient = action.payload
    },
    createItem: (state: ClientsState, action: PayloadAction<Client.Item>) => {
      state.items = [action.payload, ...state.items]
      state.editClient = action.payload
      state.count++
    },
    changeItem: (state: ClientsState, action: PayloadAction<Client.Detail>) => {
      state.items = (state.items as Client.Item[]).map((item) =>
        item.id === action.payload.id ? action.payload : item
      )
      if (state.editClient) {
        state.editClient = action.payload
        state.currentStep = 1
      }

      if (state.detail) {
        state.detail = action.payload
      }
    },
    changeEligible: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.detail.clientInfo.isEligible = action.payload
    },
    changeTreatmentPackage: (
      state: ClientsState,
      action: PayloadAction<ClientTreatmentPackage>
    ) => {
      state.detail.clientInfo.treatmentPackage = action.payload
    },
    changeItemLink: (state: ClientsState, action: PayloadAction<string>) => {
      if (state.detail) {
        state.detail = {
          ...state.detail,
          clientInfo: {
            ...state.detail.clientInfo,
            personalLink: action.payload,
          },
        }
      }
    },
    changeStep: (
      state: ClientsState,
      account: PayloadAction<1 | 0 | 2 | 3>
    ) => {
      state.currentStep = account.payload
    },
    clearEditClient: (state: ClientsState) => {
      state.editClient = null
    },
    clearDetail: (state: ClientsState) => {
      state.detail = null
    },
    toggleLoading: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    // Points
    savePoints: (state: ClientsState, action: PayloadAction<number>) => {
      state.points = action.payload
    },
    getAllPoints: (
      state: ClientsState,
      action: PayloadAction<Client.ResPointsHistoryItem[]>
    ) => {
      state.allPoints = action.payload
    },
    togglePointsLoading: (
      state: ClientsState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload
    },
    clearPoints: (state: ClientsState) => {
      state.points = null
    },
    savePointsHistory: (
      state: ClientsState,
      action: PayloadAction<Client.ResPointsHistoryItem[]>
    ) => {
      state.pointsHistory = action.payload
    },
    togglePointsHistoryLoading: (
      state: ClientsState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingPointsHistory = action.payload
    },
    clearPointsHistory: (state: ClientsState) => {
      state.pointsHistory = []
    },
    changeAvatar: (state: ClientsState, action: PayloadAction<string>) => {
      if (state.detail) {
        const newDetail = state.detail

        newDetail.clientInfo.photoUrl = action.payload

        state.detail = newDetail
      }
    },
    confirm: (state: ClientsState) => {
      if (state.detail) {
        const newDetail = state.detail
        newDetail.clientInfo.status = ClientStatusAlias.Unseemly

        state.detail = newDetail
      }
    },
    // Activation code
    saveActivationCode: (
      state: ClientsState,
      action: PayloadAction<number>
    ) => {
      state.activationCode = action.payload
    },
    toggleActivationCode: (
      state: ClientsState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingActivationCode = action.payload
    },
    // Productions history
    saveProductionsHistory: (
      state: ClientsState,
      action: PayloadAction<Client.ProductionsHistoryItem[]>
    ) => {
      state.productionsHistory = action.payload
    },
    toggleProductionsHistoryLoading: (
      state: ClientsState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingProductionsHistory = action.payload
    },
    addItemToProductions: (
      state: ClientsState,
      action: PayloadAction<Client.ProductionsHistoryItem>
    ) => {
      state.productionsHistory = [action.payload, ...state.productionsHistory]
    },
    clearProductionsHistory: (state: ClientsState) => {
      state.productionsHistory = []
    },
    saveUpdateProductionHistory: (
      state: ClientsState,
      action: PayloadAction<Client.ProductionsHistoryItem[]>
    ) => {
      state.productionsHistory = [...action.payload]
    },
  },
})

export default slice.reducer

// Action Creators Async
export const clientsActions = {
  ...slice.actions,
  // Async
  fetchItemsAsync: (payload: Client.ReqFetchItems): ClientsActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
  fetchDetailAsync: (payload: number): ClientsActionTypes => ({
    type: types.FETCH_DETAIL,
    payload,
  }),
  fetchClientPoints: (payload: number): ClientsActionTypes => ({
    type: types.FETCH_CLIENT_POINTS,
    payload,
  }),
  fetchClientPointsHistoryAsync: (payload: number): ClientsActionTypes => ({
    type: types.FETCH_CLIENT_POINTS_HISTORY,
    payload,
  }),
  fetchAllClientPointsHistoryAsync: (): ClientsActionTypes => ({
    type: types.FETCH_ALL_CLIENT_POINTS_HISTORY,
  }),
  addClientPointsAsync: (payload: Client.ReqAddPoints): ClientsActionTypes => ({
    type: types.ADD_CLIENT_POINTS,
    payload,
  }),
  subtractClientPointsAsync: (
    payload: Client.ReqSubtractPoints
  ): ClientsActionTypes => ({
    type: types.SUBTRACT_CLIENT_POINTS,
    payload,
  }),
  createAsync: (payload: Client.ReqCreate): ClientsActionTypes => ({
    type: types.CREATE_ITEM,
    payload,
  }),
  changeAsync: (payload: Client.ReqChange): ClientsActionTypes => ({
    type: types.CHANGE_ITEM,
    payload,
  }),
  changeLinkAsync: (payload: Client.ReqChangeLink): ClientsActionTypes => ({
    type: types.CHANGE_PERSONAL_LINK,
    payload,
  }),
  confirmAsync: (payload: {
    id: number
    isCreate: boolean
  }): ClientsActionTypes => ({
    type: types.CONFIRM_CLIENT,
    payload,
  }),
  createNotificationAsync: (
    payload: Client.ReqPushMessage
  ): ClientsActionTypes => ({
    type: types.CREATE_PUSH,
    payload,
  }),
  changeJawAsync: (payload: Client.ReqChangeJaw): ClientsActionTypes => ({
    type: types.CHANGE_JAW,
    payload,
  }),
  deleteAsync: (payload: number): ClientsActionTypes => ({
    type: types.DELETE_ITEM,
    payload,
  }),
  putPassportDocumentsAsync: (payload: UpdatePassportDataAsync['payload']) => ({
    type: types.UPDATE_CLIENT_PASSPORT_DATA,
    payload,
  }),
  putIllnessAsync: (payload: UpdateIllnessAsync['payload']) => ({
    type: types.UPDATE_CLIENT_ILLNESS,
    payload,
  }),
  fetchClientProductionsHistoryAsync: (
    payload: number
  ): ClientsActionTypes => ({
    type: types.FETCH_CLIENT_PRODUCTIONS_HISTORY,
    payload,
  }),
  addProductionAsync: (
    payload: Client.ReqAddProduction
  ): ClientsActionTypes => ({
    type: types.ADD_CLIENT_PRODUCTION,
    payload,
  }),
  deleteProductionAsync: (payload: number): ClientsActionTypes => ({
    type: types.DELETE_CLIENT_PRODUCTION,
    payload,
  }),
  updateEligible: (payload: UpdateEligibleAsync['payload']) => ({
    type: types.UPDATE_ELIGIBLE,
    payload,
  }),
  updateTreatmentPackage: (
    payload: UpdateTreatmentPackageAsync['payload']
  ) => ({
    type: types.UPDATE_TREATMENT_PACKAGE,
    payload,
  }),
}
