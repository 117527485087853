import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
// Local
import ENV from '@/configs'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: ENV.STAGE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120000,
})

const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000
  const randomMs = 1000 * Math.random()
  return seconds + randomMs
}

axiosRetry(axiosInstance, {
  retries: 1,
  retryDelay,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError,
})

export default axiosInstance
