import { ProductionStatus, ProductionType } from './BackendEnum'

export const productionTypeTitlesConfig: Record<ProductionType, string> = {
  [ProductionType.FullTreatment]: 'Full Treatment',
  [ProductionType.StartKit]: 'Start Kit',
  [ProductionType.Refinement]: 'Refinement',
  [ProductionType.Retainer]: 'Retainer',
}

export const productionStatusTitlesConfig: Record<ProductionStatus, string> = {
  [ProductionStatus.Created]: 'Створено',
  [ProductionStatus.Print]: 'Друк',
  [ProductionStatus.Production]: 'Виробництво',
  [ProductionStatus.Done]: 'Готово',
  [ProductionStatus.Sent]: 'Відправлено',
}
