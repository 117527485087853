import type { Settings } from './namespace'

// Types
export enum types {
  FETCH_ROLES_ASYNC = 'SETTINGS/FETCH_ROLES_ASYNC',
}

export interface SettingsState {
  roles: Settings.Role[] | []
}

export type FetchRolesAsyncAction = {
  type: typeof types.FETCH_ROLES_ASYNC
}

export type SettingsActionTypes = FetchRolesAsyncAction
