import { ERRORS_MAIN } from '@/constants'
import { message } from 'antd'
import { AxiosResponse } from 'axios'
import { SagaIterator } from 'redux-saga'
import { call } from 'redux-saga/effects'
import { apiClients } from '../../api'
import { UpdateIllnessAsync } from '../../types'

export function* updateClientIllness({
  payload,
}: UpdateIllnessAsync): SagaIterator {
  try {
    const response: AxiosResponse = yield call(
      apiClients.putIllness,
      payload.clientId,
      payload
    )
    if (response.status === 201) {
      yield call(message.success, `Паспортні данні клієнта збереженні`)
    }
  } catch (error) {
    yield call(message.error, ERRORS_MAIN)
  }
}
