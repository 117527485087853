import type { AxiosPromise } from 'axios'
// Local
import axios from '@/services/axios'

export const apiPayments = new (class Api {
  fetchPayments(params: any): AxiosPromise {
    return axios({
      url: '/payments',
      method: 'get',
      params,
    })
  }

  fetchPayment(id: number): AxiosPromise {
    return axios({
      url: '/payments/' + id,
      method: 'get',
    })
  }
})()
