class EventObserver {
	private observers: any[] = []

	public subscribe(event: string, fn: Function): void {
		this.observers.push([event, fn])
	}

	public unsubscribe(event: string, fn: Function): void {
		this.observers = this.observers.filter((subscriber) => {
			return subscriber[0] !== event || subscriber[1] !== fn
		})
	}

	public broadcast(event: string, data): void {
		console.log(event)
		this.observers.forEach((subscriber) => {
			if (subscriber[0] === event) subscriber[1](data)
		})
	}
}

export const eventsObserver = new EventObserver()
