import { useEffect, useState } from 'react'
// Typing
import type {
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface'
import { Rating } from '@/bus/rating'
import { Client } from '@/bus/clients/namespace'
import { Partner } from '@/bus/partners'

export const useFetchData = (
  fetchItems: (
    optionsClients?: Client.ReqFetchItems,
    optionsPartner?: Partner.ReqFetchItems
  ) => void,
  searchString?: string
) => {
  // Params
  const [sort, setSort] = useState({
    sortField: null,
    sort: null,
  })
  const [statuses] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [isActive] = useState(undefined)
  useEffect(() => {
    const optionsPartner: Partner.ReqFetchItems = {
      // limit: pageSize,
      page,
      // sortField: 'users.createDate',
      sort: 'ASC',
    }

    if (searchString) {
      optionsPartner.searchString = searchString
    }

    if (sort.sort) {
      optionsPartner.sortField = sort.sortField
      optionsPartner.sort = sort.sort
    }

    const optionsClients: Client.ReqFetchItems = {
      statuses,
      // limit: pageSize,
      page,
      // sortField: 'users.createDate',
      sort: 'ASC',
      activeStatus: isActive,
    }

    if (searchString) {
      optionsClients.searchString = searchString
    }

    if (sort.sort) {
      optionsClients.sortField = sort.sortField
      optionsClients.sort = sort.sort
    }

    if (isActive !== 'undefined') {
      optionsClients.activeStatus = isActive
    }
    fetchItems(optionsClients, optionsPartner)
  }, [
    fetchItems,
    page,
    pageSize,
    searchString,
    sort.sort,
    sort.sortField,
    statuses,
    isActive,
  ])

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<Rating.Item>
  ): void => {
    setPage(pagination.current)
    setPageSize(pagination.pageSize)

    if (sorter.field) {
      setSort((prev) => ({
        ...prev,
        sortField: sorter.field.toString().replace(',', '.').trim(),
      }))
    }

    if (sorter.order === 'ascend') {
      setSort((prev) => ({
        ...prev,
        sort: 'ASC',
      }))
    } else if (sorter.order === 'descend') {
      setSort((prev) => ({
        ...prev,
        sort: 'DESC',
      }))
    }
  }

  return {
    handleTableChange,
  }
}
