import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

type PropsTypes = {
  step: number
  className?: string
  stepsDefault?: boolean[]
}

export const StepIndicator: React.FC<PropsTypes> = (props: PropsTypes) => {
  const { step = 0, className } = props

  const [steps, setSteps] = useState<boolean[]>(
    props.stepsDefault ? props.stepsDefault : [true, false]
  )

  useEffect(() => {
    setSteps((prevState) => prevState.map((item, idx) => idx === step))
  }, [step])

  return (
    <div className={clsx(styles.root, className)}>
      {steps.map((item, idx) => (
        <div
          key={idx}
          className={clsx(styles.steps, item && styles.steps__active)}
        />
      ))}
    </div>
  )
}
