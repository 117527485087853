import { createSelector } from 'reselect'
// Local
import type { RootState } from '@/core/store/rootReducer'
import type { ClientFiles } from '@/bus/client-files'
import { FileMimetypeEnum } from '@/configs/BackendEnum'

export const getTags = (state: RootState) => state.entities.clientFiles.tags
export const getLink = (state: RootState) => state.entities.clientFiles.link
export const getCreateTags = (state: RootState) =>
  state.entities.clientFiles.createTags

export const getTagByLink = (state: RootState) =>
  state.entities.clientFiles.tagByLink

export const isLoading = (state: RootState) =>
  state.entities.clientFiles.loading

export const isLoadingHandler = (state: RootState) =>
  state.entities.clientFiles.loadingHandler

export const isLoadingLink = (state: RootState) =>
  state.entities.clientFiles.loadingLink

export const getCreateTagsIds = createSelector(
  getCreateTags,
  (items: ClientFiles.CreateTag[]) => {
    const tagsIds = {
      tagIdPhoto: null,
      tagIdScanning: null,
      tagIdKT: null,
    }

    console.log('item', items)

    items.forEach((item: ClientFiles.CreateTag) => {
      switch (item.name) {
        case 'Початкове сканування': {
          tagsIds.tagIdScanning = item.id
          break
        }
        case 'До лікування': {
          tagsIds.tagIdPhoto = item.id
          break
        }
        case 'КТ': {
          tagsIds.tagIdKT = item.id
          break
        }
      }
    })

    console.log(tagsIds)
    return tagsIds
  }
)

export const getDetailTag = (state: RootState) =>
  state.entities.clientFiles.detailTag
export const isLoadingDetail = (state: RootState) =>
  state.entities.clientFiles.loadingDetail

export const getStlFiles = (state: RootState) =>
  state.entities.clientFiles.stlFiles
