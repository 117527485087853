import { ClientIllnessKey } from '@/configs/BackendEnum'
import type { Client } from './namespace'

export enum types {
  //Async
  FETCH_ITEMS = 'CLIENTS/FETCH_ITEMS',
  FETCH_DETAIL = 'CLIENTS/FETCH_DETAIL',
  CREATE_ITEM = 'CLIENTS/CREATE_ITEM',
  CHANGE_ITEM = 'CLIENTS/CHANGE_ITEM',
  DELETE_ITEM = 'CLIENTS/DELETE_ITEM',
  CONFIRM_CLIENT = 'CLIENTS/CONFIRM_CLIENT',
  FETCH_CLIENT_POINTS = 'CLIENTS/FETCH_CLIENT_POINTS',
  FETCH_CLIENT_POINTS_HISTORY = 'CLIENTS/FETCH_CLIENT_POINTS_HISTORY',
  CREATE_PUSH = 'CLIENTS/CREATE_PUSH',
  CHANGE_JAW = 'CLIENTS/CHANGE_JAW',
  FETCH_ALL_CLIENT_POINTS_HISTORY = 'CLIENTS/FETCH_ALL_CLIENT_POINTS_HISTORY',
  ADD_CLIENT_POINTS = 'ADD_CLIENT_POINTS',
  SUBTRACT_CLIENT_POINTS = 'SUBSTRACT_CLIENT_POINTS',
  UPDATE_CLIENT_PASSPORT_DATA = 'CLIENTS/UPDATE_CLIENT_PASSPORT_DATA',
  UPDATE_CLIENT_ILLNESS = 'CLIENTS/UPDATE_CLIENT_ILLNESS',
  CHANGE_PERSONAL_LINK = 'CLIENTS/CHANGE_PERSONAL_LINK',
  FETCH_CLIENT_PRODUCTIONS_HISTORY = 'CLIENTS/FETCH_CLIENT_PRODUCTIONS_HISTORY',
  ADD_CLIENT_PRODUCTION = 'ADD_CLIENT_PRODUCTION',
  DELETE_CLIENT_PRODUCTION = 'DELETE_CLIENT_PRODUCTION',
  UPDATE_ELIGIBLE = 'UPDATE_ELIGIBLE',
  UPDATE_TREATMENT_PACKAGE = 'UPDATE_TREATMENT_PACKAGE',
}

// STATE

export type ClientsState = {
  items: Client.Item[] | []
  count: number
  detail: Client.Detail | null
  loading: boolean
  editClient: Client.Item | null
  currentStep: 0 | 1 | 2 | 3
  points: null | number
  loadingPoints: boolean
  pointsHistory: Client.ResPointsHistoryItem[] | []
  allPoints: Client.ResPointsHistoryItem[] | []
  loadingPointsHistory: boolean
  activationCode: number
  loadingActivationCode: boolean
  productionsHistory: Client.ProductionsHistoryItem[]
  loadingProductionsHistory: boolean
}

// ASYNC

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS
  payload: Client.ReqFetchItems
}

export type FetchDetailAsync = {
  type: typeof types.FETCH_DETAIL
  payload: number
}

export type CreateAsync = {
  type: typeof types.CREATE_ITEM
  payload: Client.ReqCreate
}

export type ChangeAsync = {
  type: typeof types.CHANGE_ITEM
  payload: Client.ReqChange
}

export type DeleteAsync = {
  type: typeof types.DELETE_ITEM
  payload: number
}

export type DeleteProductionAsync = {
  type: typeof types.DELETE_CLIENT_PRODUCTION
  payload: number
}

export type ConfirmAsync = {
  type: typeof types.CONFIRM_CLIENT
  payload: {
    id: number
    isCreate: boolean
  }
}

export type FetchClientPointsAsync = {
  type: typeof types.FETCH_CLIENT_POINTS
  payload: number
}

export type FetchClientPointsHistoryAsync = {
  type: typeof types.FETCH_CLIENT_POINTS_HISTORY
  payload: number
}

export type FetchAllClientPointsHistoryAsync = {
  type: typeof types.FETCH_ALL_CLIENT_POINTS_HISTORY
}

export type AddClientPointsAsync = {
  type: typeof types.ADD_CLIENT_POINTS
  payload: Client.ReqAddPoints
}

export type SubtractClientPointsAsync = {
  type: typeof types.SUBTRACT_CLIENT_POINTS
  payload: Client.ReqSubtractPoints
}

export type CreateNotificationAsync = {
  type: typeof types.CREATE_PUSH
  payload: Client.ReqPushMessage
}

export type ChangeJawAsync = {
  type: typeof types.CHANGE_JAW
  payload: Client.ReqChangeJaw
}

export type UpdateEligibleAsync = {
  type: typeof types.UPDATE_ELIGIBLE
  payload: Client.ReqUpdateEligible
}

export type UpdateTreatmentPackageAsync = {
  type: typeof types.UPDATE_TREATMENT_PACKAGE
  payload: Client.ReqUpdateTreatmentPackage
}

export type UpdatePassportDataAsync = {
  type: typeof types.UPDATE_CLIENT_PASSPORT_DATA
  payload: {
    clientId: number
    identificationCode: string
    passportNumber: string
    issuedBy: string
    dataOfIssue: string
    registration: string
    contractAmount?: string
    contractAmount50?: string
    contractAmountPDV?: string
    contractAmountPDV7?: string
  }
}

export type UpdateIllnessAsync = {
  type: typeof types.UPDATE_CLIENT_ILLNESS
  payload: {
    clientId: number
    items: {
      key: ClientIllnessKey
      value: boolean
      note?: string
    }[]
  }
}

export type ChangeLinkAsync = {
  type: typeof types.CHANGE_PERSONAL_LINK
  payload: Client.ReqChangeLink
}

export type FetchClientProductionsHistoryAsync = {
  type: typeof types.FETCH_CLIENT_PRODUCTIONS_HISTORY
  payload: number
}

export type AddClientProductionAsync = {
  type: typeof types.ADD_CLIENT_PRODUCTION
  payload: Client.ReqAddProduction
}

export type ClientsActionTypes =
  | FetchItemsAsync
  | FetchDetailAsync
  | CreateAsync
  | ChangeAsync
  | DeleteAsync
  | ConfirmAsync
  | FetchClientPointsAsync
  | FetchClientPointsHistoryAsync
  | CreateNotificationAsync
  | ChangeJawAsync
  | FetchAllClientPointsHistoryAsync
  | UpdatePassportDataAsync
  | UpdateIllnessAsync
  | AddClientPointsAsync
  | SubtractClientPointsAsync
  | ChangeLinkAsync
  | FetchClientProductionsHistoryAsync
  | AddClientProductionAsync
  | DeleteProductionAsync
