import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// Local
import { ProductsActionTypes, ProductsState, types } from './types'
import type { Product } from './namespace'

const initialState: ProductsState = {
  items: [],
  count: 0,
  loading: false,
}

const slice = createSlice({
  name: 'PRODUCTS',
  initialState,
  reducers: {
    saveItems: (
      state: ProductsState,
      action: PayloadAction<Product.ResFetchItems>
    ) => {
      state.items = action.payload.items
      state.count = action.payload.count
    },
    toggleLoading: (state: ProductsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateItem: (state: ProductsState, action: PayloadAction<Product.Item>) => {
      state.items = (state.items as Product.Item[]).map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              price: action.payload.price,
            }
          : item
      )
    },
  },
})

export default slice.reducer

// Action Creators Async
export const productsActions = {
  ...slice.actions,
  fetchItemsAsync: (payload: Product.ReqFetchItems): ProductsActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
  deleteAsync: (payload: number): ProductsActionTypes => ({
    type: types.DELETE_PRODUCT,
    payload,
  }),
  deleteTemplateAsync: (payload: number): ProductsActionTypes => ({
    type: types.DELETE_PRODUCT_TEMPLATE,
    payload,
  }),
}
