import { call, put } from 'redux-saga/effects'
import { message, notification } from 'antd'
import { push } from 'connected-react-router'
import type { SagaIterator } from 'redux-saga'

// Local
import { uiActions } from '@/bus/ui'
import { RouteEnum } from '@/configs/RouteEnum'
import { ERRORS_MAIN } from '@/constants'

// Local Dir
import { apiAuth } from '../../api'
import { ConfirmEmailAsync } from '../../types'

export function* confirmEmail(action: ConfirmEmailAsync): SagaIterator {
  try {
    yield put(uiActions.startFetching())

    yield call(apiAuth.confirmEmail, action.payload)

    yield put(push(RouteEnum.App))

    yield call(notification.success, {
      message: 'Реєстрація завершена',
      description:
        "Ви підтвердили Ваш Email, з Вами зв'яжеться менеджер, після буде активований Ваш акаунт.",
      duration: 0,
    })
  } catch (error) {
    yield put(uiActions.emitError(error.response?.data, 'confirmEmail workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}
