import { RootState } from '@/core/store/rootReducer'
// Selector
// Memoization

export const getItems = (state: RootState) => state.entities.partners.items
export const getCount = (state: RootState) => state.entities.partners.count
export const getDetail = (state: RootState) => state.entities.partners.detail
export const getClients = (state: RootState) => state.entities.partners.clients
export const getCountClients = (state: RootState) =>
  state.entities.partners.countClients
export const isLoading = (state: RootState) => state.entities.partners.loading
export const isLoadingReferral = (state: RootState) =>
  state.entities.partners.loadingReferral
export const isInspe = (state: RootState) => state.entities.partners.isInspe
