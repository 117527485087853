import React, { useState } from 'react'
import { Layout } from 'antd'
// Local Dir
import { TopMenu, AsideMenu, Footer } from '../components'
import styles from './styles.module.scss'
import { useEventsListener } from '@/hooks/useEventsListener'
import { useDispatch } from 'react-redux'
import { authActions } from '@/bus/auth'
import { PaymentInfoSmart, PurchaseInfoSmart } from '@/smart-components'
import { DocumentGeneratorSmart } from '@/smart-components/DocumentGenerator'
import { FileLoaderProgressSmart } from '@/smart-components/FileLoaderProgress'

export const MainLayout: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const dispatch = useDispatch()

  useEventsListener('onWalletChange', () => {
    dispatch(authActions.fetchAccountWalletAsync())
  })

  return (
    <>
      <TopMenu collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout style={{ minHeight: '100vh' }}>
        <AsideMenu collapsed={collapsed} />
        <Layout className={collapsed ? styles.fadeIn : styles.feydOut}>
          <Layout.Content className={styles.root}>{children}</Layout.Content>
          <Footer />
        </Layout>
        <PurchaseInfoSmart />
        <PaymentInfoSmart />
        <DocumentGeneratorSmart />
        <FileLoaderProgressSmart />
      </Layout>
    </>
  )
}
