import { CloseOutline } from "@/assets/icons"
import { FileImageIcon } from "@/components"
import { Button } from "antd"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styles from './styles.module.scss'

interface IProps {
   url: string
   onPressDelete?: () => void
}

export const PhotoSection = (props: IProps) => {

   if (!props.url) {
      return (
         <div className={`${styles.noPhoto} ${styles.photo_section__wrapper}`}>
            <FileImageIcon className={styles.icon} />
         </div>
      )
   }

   return (
      <>
         <div className={`${styles.photo_section__wrapper}`}>
            <LazyLoadImage
               key={props.url}
               effect="blur"
               src={props.url}
            />
            <Button
               type="primary"
               shape="circle"
               size={'small'}
               icon={<CloseOutline />}
               className={styles.iconBtn}
               onClick={() => props.onPressDelete()}
            />
         </div>
      </>
   )
}