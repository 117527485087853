import { SagaIterator } from '@redux-saga/core'
import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import { push } from 'connected-react-router'
// Tools
import { RouteEnum } from '@/configs/RouteEnum'
import { clientsActions } from '@/bus/clients'
import { uiActions } from '@/bus/ui'
import { toggleScroll } from '@/helpers/toggleScroll'
import { ERRORS_MAIN } from '@/constants'
import type { ConfirmAsync } from '../../types'
// Api
import { apiClients } from '../../api'

export function* confirmWorker(action: ConfirmAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleLoading(true))
    // Api call or some async actions
    yield call(apiClients.confirm, action.payload.id)

    if (action.payload.isCreate) {
      yield put(clientsActions.changeStep(0))
      yield put(clientsActions.clearEditClient())
      yield put(push(`${RouteEnum.Clients}/${action.payload.id}`))
      yield call(toggleScroll, false)
    }
    yield put(clientsActions.confirm())

    yield call(message.success, 'Клієнт був затверджений 🚀')
  } catch (error) {
    console.log(error.response)
    if (error.response.status === 400) {
      yield call(message.error, error.response?.data?.message)
    } else yield put(uiActions.emitError(error, 'Clients confirmWorker'))
    // yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.toggleLoading(false))
  }
}
