import { RootState } from '@/core/store/rootReducer'

export const getCreatedProductions = (state: RootState) =>
  state.entities.productions.created
export const getPrintingProductions = (state: RootState) =>
  state.entities.productions.printing
export const getProductionProductions = (state: RootState) =>
  state.entities.productions.production
export const getDoneProductions = (state: RootState) =>
  state.entities.productions.done
export const getSentProductions = (state: RootState) =>
  state.entities.productions.sent

export const getProductionsStatistics = (state: RootState) =>
  state.entities.productions.statistics
