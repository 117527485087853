import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { Client, clientsActions } from '@/bus/clients'
import {
  AddClientProductionAsync,
  DeleteProductionAsync,
  FetchClientProductionsHistoryAsync,
} from '../../types'
import { AxiosResponse } from 'axios'
import { apiClients } from '../../api'
import { uiActions } from '@/bus/ui'
import { message } from 'antd'
import { ERRORS_MAIN } from '@/constants'
import { productionsActions } from '@/bus/productions'

export function* fetchClientProductionsHistory(
  action: FetchClientProductionsHistoryAsync
): SagaIterator {
  try {
    yield put(clientsActions.toggleProductionsHistoryLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<Client.ProductionsHistoryItem[]> = yield call(
      apiClients.fetchClientProductionsHistory,
      action.payload
    )

    if (response.data) {
      yield put(clientsActions.saveProductionsHistory(response.data))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response.data,
        'Clients fetchProductionsHistory workers'
      )
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.toggleProductionsHistoryLoading(false))
  }
}

export function* addProduction(action: AddClientProductionAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleProductionsHistoryLoading(true))

    // Api call or some async actions
    const { data }: AxiosResponse<Client.ProductionsHistoryItem> = yield call(
      apiClients.addProduction,
      action.payload
    )

    if (data) {
      yield call(message.success, `Замовлення створено`)
      yield put(clientsActions.addItemToProductions(data))
      yield put(productionsActions.fetchStatisticsAsync())
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error.response.data, 'Clients addProduction workers')
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.toggleProductionsHistoryLoading(false))
  }
}

export function* deleteProduction(action: DeleteProductionAsync): SagaIterator {
  try {
    // Api call or some async actions
    const response: AxiosResponse<number> = yield call(
      apiClients.deleteProduction,
      action.payload
    )

    yield put(productionsActions.fetchStatisticsAsync())

    if (response) {
      yield call(message.success, `Замовлення видалено`)
    }
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response.data,
        'Clients deleteProduction workers'
      )
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.toggleProductionsHistoryLoading(false))
  }
}
