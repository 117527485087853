import React, { useState } from 'react'
import { Menu, Avatar } from 'antd'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { RouteEnum } from '@/configs/RouteEnum'
import { Logo } from '@/components'
import { useHandlers } from '../AsideMenu/useHandlers'
import styles from './styles.module.scss'

interface PropsTypes {
  setCollapsed?: any
  collapsed?: boolean
}

export const TopMenu = (props: PropsTypes) => {
  const { collapsed, setCollapsed } = props
  const { account, goToSetting, handleLogout } = useHandlers()
  const [isPopoverVisible, setPopoverVisible] = useState(false)

  const handlePopoverOpen = () => {
    setPopoverVisible(true)
  }

  const handlePopoverClose = () => {
    setPopoverVisible(false)
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const fullName = `${account.info?.firstName} ${account.info?.lastName}`

  const leftSide = () => {
    return (
      <div className={styles.wrapper__blok1}>
        <div className={styles.logo_wrapper}>
          <Link to={RouteEnum.App} className={styles.logo}>
            <Logo />
          </Link>
        </div>
        <div onClick={toggleCollapsed} className={styles.button_block}>
          {collapsed ? (
            <MenuUnfoldOutlined className={styles.icon} />
          ) : (
            <MenuFoldOutlined className={styles.icon} />
          )}
        </div>
      </div>
    )
  }

  const rightSide = () => {
    return (
      <div className={styles.wrapper__blok2}>
        <div
          className={styles.avatar}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <div className={styles.avatar__name}>{fullName}</div>
          <Avatar src={account.info?.photoUrl} size={40} />
          <Menu
            className={`${styles.popover} ${
              isPopoverVisible && styles.popoverVisible
            }`}
          >
            <Menu.Item className={styles.link} onClick={goToSetting}>
              <SettingOutlined />
              <span>Мій Акаунт</span>
            </Menu.Item>
            <Menu.Item className={styles.link} onClick={handleLogout}>
              <LogoutOutlined className={styles.link__icon} />
              <span>Вийти</span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    )
  }

  return (
    <Menu className={styles.wrapper}>
      {leftSide()}
      {rightSide()}
    </Menu>
  )
}
