import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from '@redux-saga/core'
import type { AxiosResponse } from 'axios'
// Local
import { ERRORS_MAIN } from '@/constants'
import { partnersActions } from '@/bus/partners'
import { uiActions } from '@/bus/ui'
import { authActions } from '@/bus/auth'
import type { Partner } from '@/bus/partners'
import type { Account } from '@/bus/auth'
// Local Dir
import { apiPartners } from '../../api'
import type { ChangeAsyncPrice } from '../../types'

function isPartnerDetail(
  value: Account.CurrentAccount | Partner.Item
): value is Partner.Detail {
  return (value as Partner.Detail).partnerInfo !== undefined
}

export function* changePrice(action: ChangeAsyncPrice): SagaIterator {
  try {
    yield put(uiActions.startFetching())
    // Api call or some async actions
    const {
      data,
    }: AxiosResponse<Account.CurrentAccount | Partner.Detail> = yield call(
      apiPartners.changePrice,
      {
        id: action.payload.id,
        data: action.payload.data,
      }
    )

    if (data) {
      if (action.payload.isChangePrice) {
        if (isPartnerDetail(data)) {
          let changeData: Account.CurrentPrice
          const partnerInfo = data.partnerInfo

          changeData = {
            price: partnerInfo.SDDiagnosticsPrice,
          }

          yield put(authActions.changePrice(changeData))
        }
      } else {
        yield put(partnersActions.changeItem(data as Partner.Detail))
      }
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Partners changeItem workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}
