import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

type PropsTypes = {
  children: ReactNode
  className?: string
  style?: any
}

export const FullScreen: React.FC<PropsTypes> = ({ children }: PropsTypes) => (
  <div className={styles.fullScreen}>{children}</div>
)

export const Box: React.FC<PropsTypes> = ({
  children,
  className,
  style
}: PropsTypes) => (
  <div style={style} className={clsx(styles.content, className)}>{children}</div>
)
