import { SagaIterator } from '@redux-saga/core'
import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { message } from 'antd'
// Tools
import { RouteEnum } from '@/configs/RouteEnum'
import { partnersActions } from '@/bus/partners'
import { uiActions } from '@/bus/ui'
// Typings
import { CreateAsync } from '../../types'
// Api
import { apiPartners } from '../../api'
import { ERRORS_MAIN } from '@/constants'

export function* createItem(action: CreateAsync): SagaIterator {
  try {
    yield put(partnersActions.toggleLoading(true))

    const response = yield call(apiPartners.create, action.payload)

    if (response.data) {
      // + `?code=${response.data}`
      yield put(push(RouteEnum.SignUpCode))
      yield call(
        message.success,
        'На електронну адресу вам було надіслано повідомлення 📩'
      )
    }
  } catch (error) {
    if (error.response?.data?.statusCode === 409) {
      yield put(push(RouteEnum.SignUp + `?error=conflict`))
      yield call(message.error, 'Електрона адреса вже використовується.')

      return
    }
    yield put(
      uiActions.emitError(error.response?.data, 'Failed to create partners!')
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(partnersActions.toggleLoading(false))
  }
}
