/* eslint-disable react-hooks/exhaustive-deps */
import { ReactText, useCallback, useEffect, useState } from 'react'
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
// Redux
import { usePaginationList } from '@/hooks/usePaginationList'
import { apiAuth } from '@/bus/auth/api'
import _ from 'lodash'

interface IUserPaymentsListParams {
  loadParams: Record<any, any>
}

export const usePackagesList = (props: IUserPaymentsListParams) => {
  const [searchString, setSearchString] = useState<string>('')
  const list = usePaginationList({
    fetchItems: apiAuth.fetchWalletsChanges,
    loadParams: {
      ...props.loadParams,
      limit: '5',
    },
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, ReactText[]>,
    sorter: SorterResult<any>
  ): void => {
    const paramsToLoad: any = {
      limit: pagination.pageSize,
    }

    if (pagination.current !== list.loadParams.page) {
      paramsToLoad.page = pagination.current
    }

    list.setLoadParams(paramsToLoad)
  }

  const handleSearch = useCallback((): void => {
    list.setLoadParams({ searchString })
  }, [searchString])

  const changeSearch = (value: string) => {
    setSearchString(value)
  }

  useEffect(() => {
    if (searchString) handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString])

  useEffect(() => {
    if (_.size(props.loadParams) > 0) list.setLoadParams(props.loadParams)
  }, [props.loadParams])

  return {
    searchString,
    handleTableChange,
    list,
    changeSearch,
  }
}
