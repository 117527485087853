import React from 'react'
import { Button, Typography, Upload } from 'antd'
import type {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload/interface'
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface'

// Local
import type { ClientFiles } from '@/bus/client-files'
// Local Dir
import styles from './styles.module.scss'
import { UploadListItem } from './UploadListItem'

type PropsTypes = {
  name: string
  icon: React.ElementType
  fileList: UploadFile<ClientFiles.ClientFile>[]
  onPreview: (file: UploadFile<ClientFiles.ClientFile>) => void
  beforeUpload: UploadProps['beforeUpload']
  request: (options: RcCustomRequestOptions) => void
  onRemove: (file: UploadFile<ClientFiles.ClientFile>) => void
  onChange: (
    info: UploadChangeParam<UploadFile<ClientFiles.ClientFile>>
  ) => void
}

export const UploaderList: React.FC<PropsTypes> = ({
  name,
  icon,
  fileList,
  onPreview,
  beforeUpload,
  request,
  onRemove,
  onChange,
}: PropsTypes) => {
  const Icon = icon
  const props: any = {
    name,
    fileList,
    onPreview,
    itemRender: () => null,
    multiple: true,
    beforeUpload,
    className: styles.uploadDrag__dragger,
    customRequest: request,
    onChange,
    onRemove,
    // iconRender: () => <Icon className={styles.customIconList} />,
  }

  return (
    <>
      <Upload {...props}>
        <div className={styles.uploadList}>
          <Button
            type="primary"
            shape="circle"
            icon={<Icon className={styles.uploadList__icon} />}
            className={styles.uploadList__button}
          />
          <Typography className={styles.uploadList__text}>
            Завантажити
          </Typography>
        </div>
      </Upload>
      <div className={styles.uploadItems}>
        {(fileList as UploadFile<ClientFiles.ClientFile>[]).map(
          (item, index) => {
            return (
              <UploadListItem
                key={index}
                {...{ item, onPreview, icon, onRemove }}
              />
            )
          }
        )}
      </div>
    </>
  )
}
