// STATE

import { Productions } from './namespace'

export type ProductionsListState = {
  items: Productions.ListItem[]
  count: number
  isLoading: boolean
}

export type ProductionsState = {
  created: ProductionsListState
  printing: ProductionsListState
  production: ProductionsListState
  done: ProductionsListState
  sent: ProductionsListState
  statistics: Productions.Statistics
}

export enum types {
  //Async
  FETCH_ITEMS = 'PRODUCTIONS/FETCH_ITEMS',
  FETCH_STATISTICS = 'PRODUCTIONS/FETCH_STATISTICS',
  START_PRINT = 'PRODUCTIONS/START_PRINT',
  START_PRODUCTION = 'PRODUCTIONS/START_PRODUCTION',
  SET_DONE = 'PRODUCTIONS/SET_DONE',
  SET_SENT = 'PRODUCTIONS/SET_SENT',
}

// ASYNC

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS
  payload: Productions.ReqGetList
}

export type FetchStatisticsAsync = {
  type: typeof types.FETCH_STATISTICS
}

export type StartPrintAsync = {
  type: typeof types.START_PRINT
  payload: Productions.ReqChangeStatusData
}

export type StartProductionAsync = {
  type: typeof types.START_PRODUCTION
  payload: Productions.ReqChangeStatusData
}

export type SetDoneAsync = {
  type: typeof types.SET_DONE
  payload: Productions.ReqChangeStatusData
}

export type SetSentAsync = {
  type: typeof types.SET_SENT
  payload: Productions.ReqSetSentData
}

export type ProductionsActionTypes =
  | FetchItemsAsync
  | FetchStatisticsAsync
  | StartPrintAsync
  | StartProductionAsync
  | SetDoneAsync
  | SetSentAsync
