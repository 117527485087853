import React from 'react'
import ReactDOM from 'react-dom'
// import * as Sentry from '@sentry/browser'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Local
import '@/assets/less/main.less'
import App from './App'
import * as serviceWorker from '@/services/serviceWorker'

import { history, store } from '@/core/store'
import ENV from '@/configs'

if (ENV.IS_DEVELOPMENT) {
  // require('@/core/mock')
}

// if (ENV.IS_PRODUCTION) {
//   Sentry.init({
//     dsn:
//       TODO: generate sentry key -> '',
//     release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
//   })
// }

const render = (Component: React.ComponentType) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Component />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default

    render(NextApp)
  })
}

serviceWorker.unregister()
