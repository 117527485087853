import { ProductionStatus } from '@/configs/BackendEnum'
import moment from 'moment'

interface IGetStatusParams {
  status: ProductionStatus
  deadLine: string
}

export const getProductionStatusTitle = ({
  status,
  deadLine,
}: IGetStatusParams) => {
  switch (status) {
    case ProductionStatus.Done:
    case ProductionStatus.Sent:
      return 'Done'
    default:
      const daysCount = moment(deadLine)
        .endOf('day')
        .diff(moment().endOf('day'), 'days')
      return `${daysCount} ${daysCount > 1 ? 'days' : 'day'}`
  }
}
