// Core
import { takeLatest } from '@redux-saga/core/effects'
import { SagaIterator } from '@redux-saga/types'
import { all, call } from 'redux-saga/effects'
// Types
import { types } from '../types'
import { fetchItems } from './workers/fetch.worker'

function* watchFetchItems(): SagaIterator {
  yield takeLatest(types.FETCH_ITEMS, fetchItems)
}

export function* watchRating(): SagaIterator {
  yield all([call(watchFetchItems)])
}
