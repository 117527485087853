import { AxiosPromise } from 'axios'
import { Productions } from './namespace'
import { IPaginationResult } from '@/types/pagination-result.interface'
import axios from '@/services/axios'

export const apiProductions = new (class Api {
  fetchItems(
    params: Productions.ReqGetList
  ): AxiosPromise<IPaginationResult<Productions.ListItem>> {
    return axios({
      url: '/productions',
      method: 'get',
      params,
    })
  }

  fetchStatistics(): AxiosPromise<Productions.Statistics> {
    return axios({
      url: '/productions/statistics',
      method: 'get',
    })
  }

  fetchStatAligners(
    startDate: string,
    endDate: string
  ): AxiosPromise<Productions.AlignersStatItem[]> {
    return axios({
      url: '/productions/aligner-statistics',
      method: 'get',
      params: { startDate, endDate },
    })
  }

  startPrint(
    data: Productions.ReqChangeStatusData
  ): AxiosPromise<Productions.ListItem> {
    return axios({
      url: '/productions/start-print',
      method: 'post',
      data,
    })
  }

  startProduction(
    data: Productions.ReqChangeStatusData
  ): AxiosPromise<Productions.ListItem> {
    return axios({
      url: '/productions/start-production',
      method: 'post',
      data,
    })
  }

  setDone(
    data: Productions.ReqChangeStatusData
  ): AxiosPromise<Productions.ListItem> {
    return axios({
      url: '/productions/set-done',
      method: 'post',
      data,
    })
  }

  setSent(
    data: Productions.ReqSetSentData
  ): AxiosPromise<Productions.ListItem> {
    return axios({
      url: '/productions/set-sent',
      method: 'post',
      data,
    })
  }
})()
