import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from 'redux-saga'
import type { AxiosResponse } from 'axios'
import type { ClientFiles } from '@/bus/client-files'
// Local
import { clientFilesActions } from '@/bus/client-files'
import { uiActions } from '@/bus/ui'
import { ERRORS_MAIN } from '@/constants'
// Local Dir
import { apiClientFiles } from '../../api'
import type {
  FetchTagsClientAsync,
  FetchFilesByTagAsync,
  FetchClientStlFilesAsync,
} from '../../types'

export function* fetchTagsClient(action: FetchTagsClientAsync): SagaIterator {
  try {
    yield put(clientFilesActions.toggleLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<ClientFiles.ResFetchTags> = yield call(
      apiClientFiles.fetchTagsClient,
      action.payload
    )

    if (response.data) {
      yield put(clientFilesActions.saveTags(response.data.tags))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'FileTags fetchItems workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientFilesActions.toggleLoading(false))
  }
}

export function* fetchFilesByTag({
  payload,
}: FetchFilesByTagAsync): SagaIterator {
  try {
    yield put(clientFilesActions.toggleLoadingDetail(true))

    // Api call or some async actions
    const { data }: AxiosResponse<ClientFiles.Detail> = yield call(
      apiClientFiles.fetchFilesByTag,
      payload
    )

    if (data) {
      yield put(clientFilesActions.saveTagDetail(data))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'FileTags fetchItems workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientFilesActions.toggleLoadingDetail(false))
  }
}

export function* fetchClientStlFiles(
  action: FetchClientStlFilesAsync
): SagaIterator {
  try {
    yield put(clientFilesActions.toggleLoadingDetail(true))

    // Api call or some async actions
    const response: AxiosResponse = yield call(apiClientFiles.fetchFiles, {
      clientId: action.payload,
      params: {
        filter: { mimetype: 'stl' },
      },
    })
    if (response.data) {
      yield put(clientFilesActions.saveStlFiles(response.data.items))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error, 'FileTags fetchClientStlFiles workers')
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientFilesActions.toggleLoadingDetail(false))
  }
}
