import { getExtension } from './getExtension'

function isFile(file: File | Blob): file is File {
  return (file as File).name !== undefined
}

export const isJpgOrPng = (file: File | Blob): boolean =>
  file.type === 'image/jpeg' || file.type === 'image/png'

export const isMp4 = (file: File | Blob): boolean => file.type === 'video/mp4'

export const isStl = (file: File | Blob): boolean => {
  const regExpStl = 'stl'

  if (isFile(file)) {
    return getExtension(file.name) === regExpStl
  } else {
    return false
  }
}

export const isArchive = (file: File | Blob): boolean => {
  const regExpRar = 'rar'

  if (isFile(file)) {
    return (
      file.type === 'application/zip' || getExtension(file.name) === regExpRar
    )
  } else {
    return false
  }
}
