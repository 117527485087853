import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'

import ENV from '@/configs'

import createRootReducer from './rootReducer'
import rootSaga from './rootSaga'

import { routerMiddleware } from 'connected-react-router'
// import loggerMiddleware from './middleware/logger'
import sagaMiddleware from './middleware/saga'

export const history = createBrowserHistory()

const middleware = [
  // ...getDefaultMiddleware({ thunk: false }),
  routerMiddleware(history),
  sagaMiddleware,
]

// if (ENV.IS_DEVELOPMENT) {
//   middleware.push(loggerMiddleware)
// }

export const store = configureStore({
  reducer: createRootReducer(history),
  devTools: ENV.IS_DEVELOPMENT,
  middleware,
})

let sagaTask = sagaMiddleware.run(rootSaga)

if (ENV.IS_DEVELOPMENT && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
  module.hot.accept('./rootSaga.ts', () => {
    const nextSagas = require('./rootSaga').default
    sagaTask.cancel()
    sagaTask.toPromise().then(() => {
      sagaTask = sagaMiddleware.run(nextSagas)
    })
  })
}
