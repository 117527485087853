import { all, call } from 'redux-saga/effects'

import { watchAuth } from '@/bus/auth/saga/watchers'
import { watchClients } from '@/bus/clients/saga/watchers'
import { watchClientFiles } from '@/bus/client-files/saga/watchers'
import { watchPartners } from '@/bus/partners/saga/watchers'
import { watchSettings } from '@/bus/settings/saga/watchers'
import { watchCities } from '@/bus/cities/saga/watchers'
import { watchRating } from '@/bus/rating/saga/watchers'
import { watchProducts } from '@/bus/products/saga/watchers'
import { watchProductions } from '@/bus/productions/saga/watchers'

function* rootSaga() {
  try {
    yield all([
      call(watchAuth),
      call(watchClients),
      call(watchClientFiles),
      call(watchPartners),
      call(watchSettings),
      call(watchCities),
      call(watchRating),
      call(watchProducts),
      call(watchProductions),
    ])
  } catch (error) {
    console.error('⛔️ error', error)
  }
}

export default rootSaga
