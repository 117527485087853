import React, { useState } from 'react'
import styles from './styles.module.scss'
import { useEventsListener } from '@/hooks/useEventsListener'
import { LoadingOutlined } from '@ant-design/icons'

interface FileLoading {
  filename: string
  progress: number
  id: number
}

export const FileLoaderProgressSmart = () => {
  const [items, setItems] = useState<FileLoading[]>([])

  useEventsListener(
    'onItemProgrress',
    (data) => {
      let result = [...items]
      const exist = items.find((it) => data.id === it.id)
      if (!exist && data.progress < 100) result.push(data)
      else {
        if (data.progress > 99.99)
          result = result.filter((it) => it.id !== data.id)
        else
          result = result.map((it) => {
            if (it.id === data.id) return data
            else return it
          })
      }

      setItems(result)
    },
    [items]
  )

  const renderItem = (item: FileLoading) => {
    return (
      <div className={styles.item} key={item.id}>
        <LoadingOutlined /> {item.filename} {Number(item.progress).toFixed(2)}%
        <div
          className={styles.itemProgress}
          style={{ width: `${item.progress}%` }}
        ></div>
      </div>
    )
  }

  return <div className={styles.container}>{items.map(renderItem)}</div>
}
