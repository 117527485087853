import { RootState } from '@/core/store/rootReducer'

export const getItems = (state: RootState) => state.entities.clients.items
export const getDetail = (state: RootState) => state.entities.clients.detail
export const getCount = (state: RootState) => state.entities.clients.count
export const isLoading = (state: RootState) => state.entities.clients.loading
export const getEditClient = (state: RootState) =>
  state.entities.clients.editClient
export const getCurrentStep = (state: RootState) =>
  state.entities.clients.currentStep

export const getPoints = (state: RootState) => state.entities.clients.points
export const isLoadingPoints = (state: RootState) =>
  state.entities.clients.loadingPoints

export const getPointsHistory = (state: RootState) =>
  state.entities.clients.pointsHistory
export const getAllPointsHistory = (state: RootState) =>
  state.entities.clients.allPoints
export const isLoadingPointsHistory = (state: RootState) =>
  state.entities.clients.loadingPointsHistory

export const getActivationCode = (state: RootState) =>
  state.entities.clients.activationCode
export const isLoadingActivationCode = (state: RootState) =>
  state.entities.clients.loadingActivationCode

export const getProductionsHistory = (state: RootState) =>
  state.entities.clients.productionsHistory
export const isLoadingProductionsHistory = (state: RootState) =>
  state.entities.clients.loadingProductionsHistory
