import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { authSelectors } from '@/bus/auth'
import { RouteEnum } from '@/configs/RouteEnum'

type PropsTypes = {
  component: React.FC
  path: string
  [x: string]: any
}

export const AdminGuard: React.FC<PropsTypes> = ({
  path,
  component: Component,
  ...rest
}: PropsTypes) => {
  const isAdmin = useSelector(authSelectors.isAdmin)

  if (!isAdmin) {
    return <Redirect to={RouteEnum.Error404} />
  }

  return <Route path={path} component={Component} {...rest} />
}
