import { useEffect, useState } from 'react'

export const usePagination = (count: number) => {
  const [pagination, setPagination] = useState({})

  useEffect(() => {
    setPagination({ total: count })
  }, [count])

  return pagination
}
