export enum ProductionStatus {
  Created = 'cr',
  Print = 'prt',
  Production = 'prd',
  Done = 'done',
  Sent = 'sent',
}

export enum ProductionType {
  FullTreatment = 'ft',
  StartKit = 'sk',
  Refinement = 'ref',
  Retainer = 'ret',
}
