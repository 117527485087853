import React, { useState } from 'react'
import { Button, Tooltip } from 'antd'
import {
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import type { UploadFile } from 'antd/lib/upload/interface'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// Local
import type { ClientFiles } from '@/bus/client-files'
// Local Dir
import styles from './styles.module.scss'

type PropsTypes = {
  item: UploadFile<ClientFiles.ClientFile>
  onPreview: (file: UploadFile<ClientFiles.ClientFile>) => void
  onRemove: (file: UploadFile<ClientFiles.ClientFile>) => void
  icon: React.ElementType
}

export const UploadListItem: React.FC<PropsTypes> = ({
  item,
  onPreview,
  onRemove,
  icon,
}) => {
  const [isLoadingImage, setIsLoadingImage] = useState(true)
  const Icon = icon

  const renderImageContent = () => {
    const fileTypeReg = /(png|jpg|jpeg)/gi
    if (item.type.match(fileTypeReg)) {
      return (
        <>
          {isLoadingImage && <Icon className={styles.uploadItems__item_icon} />}
          <LazyLoadImage
            alt={item.name}
            afterLoad={() => setIsLoadingImage(false)}
            height={80}
            effect="blur"
            src={item.url}
            width={80}
            className={styles.uploadItems__item_img}
            onClick={() => onPreview(item)}
          />
        </>
      )
    }
    return <Icon className={styles.uploadItems__item_icon} />
  }

  const _handlerPreview = () => {
    onPreview(item)
  }

  const _handlerRemove = () => {
    onRemove(item)
  }

  const renderAction = () => {
    return (
      <div className={styles.uploadItems__item_action}>
        {item.type === 'zip' ? (
          <Tooltip title="Завантажити">
            <Button
              type="primary"
              shape="circle"
              className={styles.uploadItems__item_button}
              icon={
                <DownloadOutlined
                  className={styles.uploadItems__item_button_icon}
                />
              }
              onClick={_handlerPreview}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Переглянути">
            <Button
              type="primary"
              shape="circle"
              className={styles.uploadItems__item_button}
              icon={
                <EyeOutlined className={styles.uploadItems__item_button_icon} />
              }
              onClick={_handlerPreview}
            />
          </Tooltip>
        )}
        <Tooltip title="Видалити">
          <Button
            type="primary"
            shape="circle"
            className={styles.uploadItems__item_button}
            onClick={_handlerRemove}
            icon={
              <DeleteOutlined
                className={styles.uploadItems__item_button_icon}
              />
            }
          />
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={styles.uploadItems__item}>
      <div className={styles.uploadItems__item_img_wrapper}>
        {renderAction()}
        {renderImageContent()}
      </div>
      <Tooltip title={item.name}>
        <div
          className={styles.uploadItems__item_title}
          onClick={() => onPreview(item)}
        >
          {item.name}
        </div>
      </Tooltip>
    </div>
  )
}
