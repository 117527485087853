import { Auth, Account } from './namespace'

export enum types {
  //Async
  INITIALIZED_ASYNC = 'AUTH/INITIALIZED_ASYNC',
  SIGN_IN_ASYNC = 'AUTH/SIGN_IN_ASYNC',
  LOGOUT_ASYNC = 'AUTH/LOGOUT_ASYNC',
  CONFIRM_EMAIL_ASYNC = 'AUTH/CONFIRM_EMAIL_ASYNC',
  RESET_PASSWORD_START_ASYNC = 'AUTH/RESET_PASSWORD_START_ASYNC',
  RESET_PASSWORD_CHECK_ASYNC = 'AUTH/RESET_PASSWORD_CHECK_ASYNC',
  RESET_PASSWORD_END_ASYNC = 'AUTH/RESET_PASSWORD_END_ASYNC',
  FETCH_ACCOUNT_ASYNC = 'AUTH/FETCH_ACCOUNT_ASYNC',
  CHANGE_ACCOUNT_ASYNC = 'AUTH/CHANGE_ACCOUNT_ASYNC',
  FETCH_WALLET_ASYNC = 'AUTH/FETCH_WALLET_ASYNC',
}

export type DataRestorePassword = {
  secretCode?: string
  email?: string
}

// STATE

export type AuthState = {
  isAuthenticate: boolean
  isInitialized: boolean
  dataRestorePassword: DataRestorePassword
  secretCode?: string
  currentAccount: Account.CurrentAccount | null
  currentPrice?: Account.CurrentPrice | null
  wallet?: Account.Wallet
}

// ASYNC

export type SignInAsync = {
  type: typeof types.SIGN_IN_ASYNC
  payload: {
    data: Auth.ReqSignIn
    isSaveSession: boolean
  }
}

export type LogoutAsync = {
  type: typeof types.LOGOUT_ASYNC
}

export type InitializedAsync = {
  type: typeof types.INITIALIZED_ASYNC
}

export type ConfirmEmailAsync = {
  type: typeof types.CONFIRM_EMAIL_ASYNC
  payload: string
}

export type ResetPasswordStartAsync = {
  type: typeof types.RESET_PASSWORD_START_ASYNC
  payload: string
}

export type ResetPasswordCheckAsync = {
  type: typeof types.RESET_PASSWORD_CHECK_ASYNC
  payload: {
    secretCode: string
    email: string
  }
}

export type ResetPasswordEndAsync = {
  type: typeof types.RESET_PASSWORD_END_ASYNC
  payload: {
    secretCode: string
    email: string
    password: string
  }
}

export type FetchAccountAsync = {
  type: typeof types.FETCH_ACCOUNT_ASYNC
}

export type ChangeAccountAsync = {
  type: typeof types.CHANGE_ACCOUNT_ASYNC
  payload: Account.ReqChange
}

export type FetchAccountWallet = {
  type: typeof types.FETCH_WALLET_ASYNC
}

export type AuthActionTypes =
  | SignInAsync
  | LogoutAsync
  | InitializedAsync
  | ConfirmEmailAsync
  | ResetPasswordStartAsync
  | ResetPasswordCheckAsync
  | ResetPasswordEndAsync
  | FetchAccountAsync
  | ChangeAccountAsync
  | FetchAccountWallet
