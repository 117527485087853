import React from 'react'

export const StlFileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="50"
    viewBox="0 0 44 50"
    {...props}
  >
    <path d="M41.215 17.884h-1.192v-5.793c0-.036-.006-.072-.01-.11-.003-.229-.076-.455-.232-.634L30.2.335l-.007-.007C30.137.264 30.07.211 30 .165c-.02-.014-.042-.026-.064-.039-.06-.033-.125-.06-.192-.081l-.052-.017C29.619.011 29.544 0 29.468 0H5.926C4.851 0 3.978.88 3.978 1.96v15.924H2.785C1.247 17.884 0 19.14 0 20.687V35.26c0 1.547 1.247 2.803 2.785 2.803h1.193v9.976C3.978 49.12 4.85 50 5.926 50h32.148c1.074 0 1.949-.88 1.949-1.96v-9.977h1.192c1.539 0 2.785-1.256 2.785-2.802V20.687c0-1.548-1.246-2.803-2.785-2.803zM5.926 1.961h22.568v10.032c0 .541.437.98.974.98h8.606v4.912H5.926V1.96zm-2.16 31.79l.663-2.713c.878.452 2.225.904 3.617.904 1.498 0 2.29-.624 2.29-1.571 0-.906-.685-1.423-2.418-2.047-2.398-.84-3.96-2.176-3.96-4.287 0-2.477 2.055-4.373 5.458-4.373 1.627 0 2.826.346 3.681.733l-.727 2.65c-.577-.28-1.605-.69-3.017-.69-1.414 0-2.1.645-2.1 1.4 0 .926.814 1.336 2.677 2.047 2.547.948 3.745 2.282 3.745 4.329 0 2.434-1.862 4.501-5.822 4.501-1.647 0-3.273-.431-4.088-.882zm34.308 13.757H5.926v-9.445h32.148v9.445zm2.162-13.09h-9.012V19.902H34.5v11.761h5.736v2.757zm-24.264-14.28h12.5v2.779h-12.5v-2.778zm4.861 2.779h2.778v12.5h-2.778v-12.5z" />
  </svg>
)
