import type { ReactNode } from 'react'
import React from 'react'
import { Button, Drawer } from 'antd'
// Local
import { CloseOutline as CloseOutlineIcon } from '@/assets/icons'
// Local Dir
import styles from './styles.module.scss'
import clsx from 'clsx'

type PropTypes = {
  children?: ReactNode
  closeDrawer: () => void
  isShowDrawer: boolean
  className?: string
  width?: number
}

export const DrawerMain: React.FC<PropTypes> = ({
  children,
  closeDrawer,
  isShowDrawer,
  className,
  width = 760,
}: PropTypes) => {
  return (
    <Drawer
      width={width}
      destroyOnClose
      onClose={closeDrawer}
      open={isShowDrawer}
      getContainer={false}
      closable={false}
    >
      <Button
        shape="circle"
        onClick={closeDrawer}
        icon={<CloseOutlineIcon />}
        className={styles.drawer__close}
      />
      <div className={clsx(styles.drawer, className)}>{children}</div>
    </Drawer>
  )
}
