import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from '@redux-saga/core'
// Local
import { ERRORS_MAIN } from '@/constants'
import { partnersActions } from '@/bus/partners'
import { uiActions } from '@/bus/ui'
// Local Dir
import { apiPartners } from '../../api'
import type { ActivateAsync, DeactivateAsync } from '../../types'

export function* activateWorker(action: ActivateAsync): SagaIterator {
  try {
    yield put(partnersActions.toggleLoading(true))

    yield call(apiPartners.activate, action.payload)

    yield put(partnersActions.activate())
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response?.data,
        'Failed to activeWorker partners!'
      )
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(partnersActions.toggleLoading(false))
  }
}

export function* deactivateWorker(action: DeactivateAsync): SagaIterator {
  try {
    yield put(partnersActions.toggleLoading(true))

    yield call(apiPartners.deactivate, action.payload)

    yield put(partnersActions.deactivate())
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response?.data,
        'Failed to deactivateWorker partners!'
      )
    )
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(partnersActions.toggleLoading(false))
  }
}
