import type { AxiosPromise } from 'axios'
// Local
import axios from '@/services/axios'

export const apiSettings = new (class Api {
  fetchRoles(): AxiosPromise {
    return axios({
      url: '/users/roles',
      method: 'get',
    })
  }
})()
