import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
// Local
import AuthService from '@/services/AuthService'
import { ERRORS_MAIN } from '@/constants'
import { authActions } from '@/bus/auth'
import { uiActions } from '@/bus/ui'
import { Auth } from '@/bus/auth'
import { fetchRoles } from '@/bus/settings/saga/workers'
// Local Dir
import { fetchAccount } from './account.workers'

type PropsTypes = {
  data?: Auth.Success
  isSaveSession?: boolean
}

export function* successAuth(payload?: PropsTypes): SagaIterator {
  try {
    yield put(uiActions.startFetching())

    if (payload && payload.data) {
      yield call(AuthService.login, payload.data, !!payload.isSaveSession)
    }

    yield call(fetchAccount)
    yield call(fetchRoles)

    yield put(authActions.auth())
  } catch (e) {
    yield put(uiActions.emitError(e.response?.data, 'successAuth workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(authActions.initialized())
    yield put(uiActions.stopFetching())
  }
}
