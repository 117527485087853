export const baseEnv = () => ({
  APP_NAME: 'Admin-Panel',
  KEY_AUTH_STATE: 'x-jwt',
  DEFAULT_LOCALE: 'ua',
  STAGE_URL: process.env.REACT_APP_API_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  IS_PRODUCTION: true,
  IS_DEVELOPMENT: false,
  IS_TESTING: false,
})

export type Environment = ReturnType<typeof baseEnv>
