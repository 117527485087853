import type { AxiosPromise } from 'axios'

import axios from '@/services/axios'
import { City } from "./namespace"


export const apiCities = new (class Api {
   fetchItems(
      params: City.ReqFetchItems
   ): AxiosPromise<City.ResFetchItems> {
      return axios({
         url: '/addresses',
         method: 'get',
         params,
      })
   }
})()
