import { RootState } from '@/core/store/rootReducer'
import { createSelector } from 'reselect'
import { UiState } from './types'

// Selector
// Memoization

export const isFetching = createSelector(
  (state: RootState) => state.ui,
  (ui: UiState) => ui.isFetching
)
