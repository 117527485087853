import { ReactText, useEffect, useState } from 'react'
// Typing
import type {
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface'
import type { Client } from '@/bus/clients'

export const useFetchData = (
  fetchClients: (params?: Client.ReqFetchItems) => void,
  searchString?: string
) => {
  // Params
  const [sort, setSort] = useState({
    sortField: null,
    sort: null,
  })
  const [statuses, setStatuses] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [isActive, setIsActive] = useState(undefined)

  useEffect(() => {
    const options: Client.ReqFetchItems = {
      statuses,
      limit: pageSize,
      page,
      sortField: 'users.createDate',
      sort: 'DESC',
      activeStatus: isActive,
    }

    if (searchString) {
      options.searchString = searchString
    }

    if (sort.sort) {
      options.sortField = sort.sortField
      options.sort = sort.sort
    }

    if (isActive !== 'undefined') {
      options.activeStatus = isActive
    }

    fetchClients(options)
  }, [
    fetchClients,
    page,
    pageSize,
    searchString,
    sort.sort,
    sort.sortField,
    statuses,
    isActive,
  ])

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, ReactText[]>,
    sorter: SorterResult<Client.Item>
  ): void => {
    setPage(pagination.current)
    setPageSize(pagination.pageSize)

    if (filters.isActive) {
      if (filters.isActive[0] == 'true') {
        setIsActive(true)
      } else if (filters.isActive[0] == 'false') {
        setIsActive(false)
      }
    }

    if (!filters.status) {
      setStatuses([])
    } else {
      setStatuses(filters.status.map((item: string) => parseInt(item)))
    }

    if (sorter.field) {
      if (sorter.field === 'createDate') {
        setSort((prev) => ({
          ...prev,
          sortField: 'users.createDate',
        }))
      } else if (sorter.field === 'id') {
        setSort((prev) => ({
          ...prev,
          sortField: 'users.id',
        }))
      } else if (sorter.field[1] === 'clinicName') {
        setSort((prev) => ({
          ...prev,
          sortField: 'partnerInfo.clinicName',
        }))
      } else {
        setSort((prev) => ({
          ...prev,
          sortField: sorter.field.toString().replace(',', '.').trim(),
        }))
      }
    }

    if (sorter.order === 'ascend') {
      setSort((prev) => ({
        ...prev,
        sort: 'ASC',
      }))
    } else if (sorter.order === 'descend') {
      setSort((prev) => ({
        ...prev,
        sort: 'DESC',
      }))
    }
  }

  return {
    handleTableChange,
  }
}
