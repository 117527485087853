import { apiClients } from '@/bus/clients/api'
import { useEventsListener } from '@/hooks/useEventsListener'
import { Drawer } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

interface IEventPaylod {
  purchaseId: number
}

export const PurchaseInfoSmart = () => {
  const [purchase, setPurchase] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const close = () => setPurchase(null)
  const isVisible = Boolean(purchase)

  const fetchPurchase = async (purchaseId: number) => {
    setLoading(true)
    const { data } = await apiClients.fetchClientPurchase(purchaseId)
    setPurchase(data)
  }

  useEffect(() => {
    if (purchase) setLoading(false)
  }, [purchase])

  useEventsListener(
    'pressByPurchaseDetail',
    (data: IEventPaylod) => {
      fetchPurchase(data.purchaseId)
    },
    []
  )

  const renderAddsContent = () => {
    if (purchase.payment) {
      return (
        <>
          <p>Тип покупки: Пряма оплата</p>
          <p>
            Дата платежу:{' '}
            {moment(purchase.payment.createDate).format('DD.MM.YYYY')}
          </p>
          <p>Сумма: {purchase.payment.amount} </p>
          <p>Платіжна Система: LiqPay</p>
        </>
      )
    } else {
      return (
        <>
          <p>Тип покупки: Покупка за пакети</p>
        </>
      )
    }
  }

  const renderContent = () => {
    if (!isVisible) return null

    return (
      <>
        <p>Ідентифікатор послуги: {purchase.id} </p>
        {renderAddsContent()}
      </>
    )
  }

  return (
    <Drawer
      title="Інформація про платіж"
      placement="right"
      closable={false}
      onClose={close}
      visible={isVisible}
      width={520}
    >
      {renderContent()}
    </Drawer>
  )
}
