import React from 'react'
import moment from 'moment'
import { Button } from 'antd'
import type { ColumnsType } from 'antd/lib/table/interface'
// Local
import styles from '../styles.module.scss'
// Typing
import type { Partner } from '@/bus/partners'
import { eventsObserver } from '@/services/observable.service'

const purposeOfPaymentLabels = {
  'for-client': 'Оплата послуги за клієнта',
  package: 'Покупка пакетів',
}

export const useColumns = () => {
  const showDetailPayment = (id: number) => {
    eventsObserver.broadcast('pressByPaymentDetail', { paymentId: id })
  }

  const getPayerInfo = (user) => {
    let info
    if (user.adminInfo) {
      info = user.adminInfo
      return `${info.firstName} ${info.lastName}`
    } else if (user.partnerInfo) {
      info = user.partnerInfo
      return `${info.firstName} ${info.lastName}`
    } else if (user.clientInfo) {
      info = user.clientInfo
      return info.name
    }
  }

  const columns: ColumnsType<Partner.Item> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      // sorter: true,
    },

    {
      title: 'Опис',
      dataIndex: 'purposeOfPayment',
      key: 'purposeOfPayment',
      render: (purposeOfPayment) => purposeOfPaymentLabels[purposeOfPayment],
    },
    {
      title: 'Сумма',
      key: 'amount',
      render: (item) => {
        return `${item.amount} ${item.currency}`
      },
    },

    {
      title: 'Дата оплати',
      dataIndex: 'createDate',
      key: 'createDate',
      defaultSortOrder: 'descend',
      // sorter: true,
      align: 'center',
      render: (_, value: Partner.Item) =>
        moment(value.createDate).format('DD.MM.YYYY'),
    },
    {
      title: 'Платник',
      dataIndex: 'user',
      key: 'name',
      align: 'center',
      render: (user) => getPayerInfo(user)
    },

    {
      dataIndex: 'id',
      align: 'right',
      key: 'action',
      render: (id) => (
        <>
          <Button
            onClick={() => showDetailPayment(id)}
            type="primary"
            className={styles.tableBtn}
          >
            Детальна Інформація
          </Button>
        </>
      ),
    },
  ]

  return columns
}
