export enum RouteEnum {
  Home = '/',
  // App
  App = '/app',
  Folders = '/folders/:id',
  AccountSettings = '/app/account/settings',

  Clients = '/app/clients',
  ClientDetail = '/app/clients/:id',

  Rating = '/app/rating',

  Stock = '/app/stock',

  StatisticsMenu = '/app/statistics',
  PointsHistory = '/app/statistics/points-history',
  AlignersStatistics = '/app/aligners-statistics',
  ClientStatistics = '/app/statistics/client-statistics',

  PaymentsMenu = '/app/payments',
  TransactionHistory = '/app/payments/transaction-history',
  ChangesHistory = '/app/payments/packages-history',
  ProductsList = '/app/payments/products-list',

  Partners = '/app/partners',
  PartnerDetail = '/app/partners/:id',

  StlViewer = '/public/stl-viewer',

  // Target Blank
  News = 'https://partners.orthos.com.ua/blog/',
  FAQ = 'https://drive.google.com/drive/folders/1--EesjLbRlsaGZ5bBSBKilqj4tYBOO4J',
  Feedback = 'mailto:orthosua@gmail.com?subject=Запитання про партнерську програму&body=Текст запитання',
  // Auth
  Auth = '/auth',
  SignIn = '/auth/sign-in/',
  SignUp = '/auth/sign-up/',
  SignUpCode = '/auth/sign-up/code/',

  ForgotPassword = '/auth/forgot-password/',
  ForgotPasswordCode = '/auth/forgot-password/code/',
  ForgotPasswordPassword = '/auth/forgot-password/new-password/',

  Error404 = '/404',

  Productions = '/app/productions',

  InteractionNotifications = '/app/interaction-notifications',
}
