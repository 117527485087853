//Core
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
import type { SagaIterator } from '@redux-saga/core'
// Types
import { types } from '../types'
// Workers
import {
  fetchFilesByTag,
  changeFileTag,
  createLink,
  createTag,
  deleteFile,
  fetchFilesByLink,
  fetchTagsClient,
  fetchClientStlFiles,
} from './workers'

function* watchFetchFilesByTag(): SagaIterator {
  yield takeEvery(types.FETCH_FILES_BY_TAG, fetchFilesByTag)
}

function* watchChangeFileTag(): SagaIterator {
  yield takeEvery(types.CHANGE_TAG, changeFileTag)
}

function* watchCreateTag(): SagaIterator {
  yield takeEvery(types.CREATE_TAG, createTag)
}

function* watchDeleteFile(): SagaIterator {
  yield takeEvery(types.DELETE_FILE, deleteFile)
}

function* watchFetchItems(): SagaIterator {
  yield takeLatest(types.FETCH_TAGS_CLIENT, fetchTagsClient)
}

function* watchCreateLink(): SagaIterator {
  yield takeEvery(types.CREATE_LINK, createLink)
}

function* watchFetchFilesByLink(): SagaIterator {
  yield takeLatest(types.FETCH_FILES_BY_LINK, fetchFilesByLink)
}

function* watchFetchClientStlFiles(): SagaIterator {
  yield takeLatest(types.FETCH_CLIENT_STL_FILES, fetchClientStlFiles)
}

export function* watchClientFiles(): SagaIterator {
  yield all([
    call(watchFetchFilesByTag),
    call(watchChangeFileTag),
    call(watchCreateTag),
    call(watchDeleteFile),
    call(watchCreateLink),
    call(watchFetchFilesByLink),
    call(watchFetchItems),
    call(watchFetchClientStlFiles),
  ])
}
