import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from 'redux-saga'
// Local
import { ERRORS_MAIN } from '@/constants'
import { clientFilesActions } from '@/bus/client-files'
import { uiActions } from '@/bus/ui'
// Local Dir
import { DeleteFileAsync } from '../../types'
import { apiClientFiles } from '../../api'

export function* deleteFile(action: DeleteFileAsync): SagaIterator {
  try {
    yield put(clientFilesActions.toggleLoadingHandler(true))

    // Api call or some async actions
    yield call(apiClientFiles.deleteFile, action.payload)

    yield put(clientFilesActions.deleteFile(action.payload.fileId))
    yield call(message.success, `Видалено 🗑`)
  } catch (error) {
    yield put(uiActions.emitError(error, 'client-files deleteFile workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientFilesActions.toggleLoadingHandler(false))
  }
}
