import type { AxiosPromise, AxiosResponse } from 'axios'
// Local
import axios from '@/services/axios'
import type { Auth, Account } from './namespace'

export const apiAuth = new (class Api {
  login(data: Auth.ReqSignIn): AxiosPromise<Auth.ResAuth> {
    return axios({
      url: '/auth',
      method: 'post',
      data,
    })
  }
  confirmEmail(secretCode: string): AxiosPromise<Auth.ResAuth> {
    return axios({
      url: '/auth/confirm-email',
      method: 'post',
      data: {
        secretCode,
      },
    })
  }
  refreshToken(token: string): AxiosPromise<Auth.ResResetToken> {
    return axios({
      url: '/auth/reset-token',
      method: 'post',
      data: {
        token,
      },
    })
  }
  resetPasswordStart(email: string): AxiosPromise<AxiosResponse<string>> {
    return axios({
      url: '/auth/reset-password-start',
      method: 'post',
      data: {
        email,
      },
    })
  }
  resetPasswordCheck(data: Auth.ReqResetPasswordCheck): AxiosPromise {
    return axios({
      url: '/auth/reset-password-check',
      method: 'post',
      data,
    })
  }
  resetPasswordEnd(data: Auth.ReqResetPasswordEnd): AxiosPromise<Auth.ResAuth> {
    return axios({
      url: '/auth/reset-password',
      method: 'post',
      data,
    })
  }
  fetchAccount(): AxiosPromise<Account.CurrentAccount> {
    return axios({
      url: '/account',
      method: 'get',
    })
  }
  fetchAccountWallet(): AxiosPromise<Account.Wallet> {
    return axios({
      url: '/wallets',
      method: 'get',
    })
  }
  fetchWalletsChanges(): AxiosPromise<Account.WalletChangesList> {
    return axios({
      url: '/wallets/changes',
      method: 'get',
    })
  }
  changeAccount(data: Account.ReqChange): AxiosPromise {
    return axios({
      url: `/account`,
      method: 'patch',
      data,
    })
  }
  usePackagesForBuyClientProduct(
    data: Account.ReqUsePackagesForBuyClientProduct
  ): AxiosPromise {
    return axios({
      url: `/wallets/use-for-buy-client-product`,
      method: 'post',
      data,
    })
  }

  getAvatarPresignedUrl(params: { filename: string; mimetype: string }) {
    return axios({
      url: `/account/avatar/presigned-put-avatar`,
      params,
    })
  }

  finishUploadAvatar(id: string) {
    return axios.post(`account/avatar/presigned-urls/${id}`)
  }
})()
