//Core
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import {
  activateWorker,
  deactivateWorker,
  changeItem,
  changePrice,
  createItem,
  deleteItem,
  fetchClients,
  fetchDetail,
  fetchItems,
  toggleReferral,
} from './workers'
import { toggleInformingEnabled } from './workers/changeItem'

function* watchFetchItems() {
  yield takeLatest(types.FETCH_ITEMS, fetchItems)
}

function* watchFetchDetail() {
  yield takeLatest(types.FETCH_DETAIL, fetchDetail)
}

function* watchChange() {
  yield takeEvery(types.CHANGE_ITEM, changeItem)
}

function* watchChangePrice() {
  yield takeEvery(types.CHANGE_PRICE, changePrice)
}

function* watchCreate() {
  yield takeEvery(types.CREATE_ITEM, createItem)
}

function* watchDelete() {
  yield takeEvery(types.DELETE_ITEM, deleteItem)
}

function* watchFetchClients() {
  yield takeLatest(types.FETCH_CLIENTS, fetchClients)
}

function* watchActivate() {
  yield takeLatest(types.ACTIVATE, activateWorker)
}

function* watchDeactivate() {
  yield takeLatest(types.DEACTIVATE, deactivateWorker)
}

function* watchToggleReferral() {
  yield takeLatest(types.TOGGLE_REFERRAL, toggleReferral)
}

function* watchToggleInformingEnabled() {
  yield takeLatest(types.TOGGLE_INFORMING_ENABLED, toggleInformingEnabled)
}

export function* watchPartners() {
  yield all([
    call(watchActivate),
    call(watchDeactivate),
    call(watchFetchItems),
    call(watchFetchDetail),
    call(watchCreate),
    call(watchChange),
    call(watchChangePrice),
    call(watchDelete),
    call(watchFetchClients),
    call(watchToggleReferral),
    call(watchToggleInformingEnabled),
  ])
}
