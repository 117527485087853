import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// Local
import { types } from './types'
import type { City } from './namespace'
import type { CitiesActionTypes, CitiesState } from './types'

const initialState: CitiesState = {
   items: [],
   count: 0,
   loading: false,
}

const slice = createSlice({
   name: 'CITIES',
   initialState,
   reducers: {
      saveItems: (
         state: CitiesState,
         action: PayloadAction<City.ResFetchItems>
      ) => {
         state.items = action.payload.items
         state.count = action.payload.count
      },
      toggleLoading: (state: CitiesState, action: PayloadAction<boolean>) => {
         state.loading = action.payload
      },

   },
})

export default slice.reducer

// Action Creators Async
export const citiesActions = {
   ...slice.actions,
   fetchItemsAsync: (payload: City.ReqFetchItems): CitiesActionTypes => ({
      type: types.FETCH_ITEMS,
      payload,
   }),
}
