import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SettingsActionTypes, SettingsState, types } from './types'
import type { Settings } from './namespace'

const initialState: SettingsState = {
  roles: [],
}

const slice = createSlice({
  name: 'SETTINGS',
  initialState,
  reducers: {
    saveRoles: (
      state: SettingsState,
      action: PayloadAction<Settings.Role[]>
    ) => {
      state.roles = action.payload
    },
  },
})

export default slice.reducer

// Action Creators Async
export const settingsAction = {
  ...slice.actions,
  fetchRolesAsync: (): SettingsActionTypes => ({
    type: types.FETCH_ROLES_ASYNC,
  }),
}
