//Core
import { SagaIterator } from '@redux-saga/core'
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import {
  changeWorker,
  confirmWorker,
  createItem,
  fetchDetail,
  fetchItems,
  fetchClientPoints,
  fetchClientPointsHistory,
  fetchAllClientPointsHistory,
  createNotification,
  changeJaw,
  deleteItem,
  addClientPoints,
  subtractClientPoints,
  changeLinkWorker,
  fetchClientProductionsHistory,
  addProduction,
} from './workers'
import { updateClientIllness } from './workers/illness.worker'
import { updateClientPassportData } from './workers/passport-data.worker'
import { deleteProduction } from './workers/productions.worker'
import { updateEligible, updateTreatmentPackage } from './workers/change.worker'

function* watchChange(): SagaIterator {
  yield takeEvery(types.CHANGE_ITEM, changeWorker)
}

function* watchDelete(): SagaIterator {
  yield takeEvery(types.DELETE_ITEM, deleteItem)
}

function* watchProductionDelete(): SagaIterator {
  yield takeEvery(types.DELETE_CLIENT_PRODUCTION, deleteProduction)
}

function* watchCreate(): SagaIterator {
  yield takeEvery(types.CREATE_ITEM, createItem)
}

function* watchFetchItems(): SagaIterator {
  yield takeLatest(types.FETCH_ITEMS, fetchItems)
}

function* watchFetchDetail(): SagaIterator {
  yield takeEvery(types.FETCH_DETAIL, fetchDetail)
}

function* watchConfirm(): SagaIterator {
  yield takeEvery(types.CONFIRM_CLIENT, confirmWorker)
}

function* watchFetchClientPoints(): SagaIterator {
  yield takeEvery(types.FETCH_CLIENT_POINTS, fetchClientPoints)
}

function* watchFetchClientPointsHistory(): SagaIterator {
  yield takeEvery(types.FETCH_CLIENT_POINTS_HISTORY, fetchClientPointsHistory)
}

function* watchFetchAllClientPointsHistory(): SagaIterator {
  yield takeEvery(
    types.FETCH_ALL_CLIENT_POINTS_HISTORY,
    fetchAllClientPointsHistory
  )
}

function* watchCreateNotification(): SagaIterator {
  yield takeEvery(types.CREATE_PUSH, createNotification)
}

function* watchChangeJaw(): SagaIterator {
  yield takeEvery(types.CHANGE_JAW, changeJaw)
}

function* watchUpdateEligible(): SagaIterator {
  yield takeEvery(types.UPDATE_ELIGIBLE, updateEligible)
}

function* watchUpdateTreatmentPackage(): SagaIterator {
  yield takeEvery(types.UPDATE_TREATMENT_PACKAGE, updateTreatmentPackage)
}

function* watchUpdateClientPassportData(): SagaIterator {
  yield takeEvery(types.UPDATE_CLIENT_PASSPORT_DATA, updateClientPassportData)
}

function* watchUpadateClientIllness(): SagaIterator {
  yield takeEvery(types.UPDATE_CLIENT_ILLNESS, updateClientIllness)
}

function* watchAddClientPoints(): SagaIterator {
  yield takeEvery(types.ADD_CLIENT_POINTS, addClientPoints)
}

function* watchSubtractClientPoints(): SagaIterator {
  yield takeEvery(types.SUBTRACT_CLIENT_POINTS, subtractClientPoints)
}

function* watchChangeLink(): SagaIterator {
  yield takeEvery(types.CHANGE_PERSONAL_LINK, changeLinkWorker)
}

function* watchFetchClientProductionsHistory(): SagaIterator {
  yield takeEvery(
    types.FETCH_CLIENT_PRODUCTIONS_HISTORY,
    fetchClientProductionsHistory
  )
}

function* watchAddClientProduction(): SagaIterator {
  yield takeEvery(types.ADD_CLIENT_PRODUCTION, addProduction)
}

export function* watchClients(): SagaIterator {
  yield all([
    call(watchFetchItems),
    call(watchFetchDetail),
    call(watchCreate),
    call(watchChange),
    call(watchConfirm),
    call(watchFetchClientPoints),
    call(watchFetchClientPointsHistory),
    call(watchFetchAllClientPointsHistory),
    call(watchCreateNotification),
    call(watchChangeJaw),
    call(watchDelete),
    call(watchProductionDelete),
    call(watchUpdateClientPassportData),
    call(watchUpadateClientIllness),
    call(watchAddClientPoints),
    call(watchSubtractClientPoints),
    call(watchChangeLink),
    call(watchFetchClientProductionsHistory),
    call(watchAddClientProduction),
    call(watchUpdateEligible),
    call(watchUpdateTreatmentPackage),
  ])
}
