import { ERRORS_MAIN } from '@/constants'
import { message } from 'antd'
import { AxiosResponse } from 'axios'
import { SagaIterator } from 'redux-saga'
import { call } from 'redux-saga/effects'
import { apiClients } from '../../api'
import { AddClientPointsAsync, SubtractClientPointsAsync } from '../../types'

export function* addClientPoints({
  payload,
}: AddClientPointsAsync): SagaIterator {
  try {
    const response: AxiosResponse = yield call(
      apiClients.addClientPoints,
      payload.id,
      payload
    )
    if (response.status === 201) {
      yield call(message.success, `Клієнту додано бали`)
    }
  } catch (error) {
    yield call(message.error, ERRORS_MAIN)
  }
}

export function* subtractClientPoints({
  payload,
}: SubtractClientPointsAsync): SagaIterator {
  try {
    const response: AxiosResponse = yield call(
      apiClients.subtractClientPoints,
      payload.id,
      payload
    )
    if (response.status === 200) {
      yield call(message.success, `Клієнту списано бали`)
    }
  } catch (error) {
    yield call(message.error, ERRORS_MAIN)
  }
}
