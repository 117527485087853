import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { AxiosResponse } from 'axios'
import type { SagaIterator } from 'redux-saga'

// Local
import { authActions } from '@/bus/auth'
import { uiActions } from '@/bus/ui'
import { ERRORS_MAIN } from '@/constants'
import type { Account } from '@/bus/auth'

// Local Dir
import { apiAuth } from '../../api'
import type { ChangeAccountAsync } from '../../types'
import { SocketIo } from '@/services/real-time.service'

export function* fetchAccount(): SagaIterator {
  try {
    const response = yield call(apiAuth.fetchAccount)

    if (response.data) {
      yield put(authActions.saveCurrentAccount(response.data))
      yield call(fetchAccountWallet)
      SocketIo.get()
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'fetchAccount workers'))
    yield call(message.error, ERRORS_MAIN)
  }
}

export function* changeAccount(action: ChangeAccountAsync): SagaIterator {
  try {
    yield put(uiActions.startFetching())
    const response: AxiosResponse<Account.CurrentAccount> = yield call(
      apiAuth.changeAccount,
      action.payload
    )

    if (response.data) {
      yield put(authActions.changeAccount(response.data))
      yield call(message.success, 'Зміни збережено 💾')
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'changeAccount workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}

export function* fetchAccountWallet(): SagaIterator {
  try {
    const response = yield call(apiAuth.fetchAccountWallet)

    if (response.data) {
      yield put(authActions.saveWallet(response.data))
    }
  } catch (error) {}
}
