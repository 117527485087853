import { call, put } from 'redux-saga/effects'
import { message } from 'antd'

// Local
import { ERRORS_MAIN } from '@/constants'
import { uiActions } from '@/bus/ui'
import type { Auth } from '@/bus/auth'
// Local Dir
import { successAuth } from './successAuth'
import { SignInAsync } from '../../types'
import { apiAuth } from '../../api'

export function* signIn(action: SignInAsync) {
  try {
    yield put(uiActions.startFetching())

    const response: Auth.ResAuth = yield call(
      apiAuth.login,
      action.payload.data
    )

    if (response.data) {
      yield call(successAuth, {
        data: response.data,
        isSaveSession: action.payload.isSaveSession,
      })
    }
  } catch (error) {
    if (error.response.status === 400 || error.response.status === 404) {
      yield call(message.error, 'Невірний Email або пароль.')
      return
    }
    if (error.response.status === 403) {
      yield call(
        message.error,
        'На жаль Ваш акаунт ще не активований, тому Ви не можете користуватись сервісом.'
      )
      return
    }
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(uiActions.stopFetching())
  }
}
