import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ClientFilesActionTypes, ClientFilesState } from './types'
// Local
import { types } from './types'
import type { ClientFiles } from './namespace'

const initialState: ClientFilesState = {
  detailTag: null,
  createTags: [],
  tags: [],
  tagByLink: null,
  link: null,
  stlFiles: null,
  loading: false,
  loadingHandler: false,
  loadingLink: false,
  loadingDetail: false,
}

const slice = createSlice({
  name: 'CLIENT_FILES',
  initialState,
  reducers: {
    // Saves
    saveTagDetail: (
      state: ClientFilesState,
      action: PayloadAction<ClientFiles.Detail>
    ) => {
      state.detailTag = action.payload
    },
    saveTags: (
      state: ClientFilesState,
      action: PayloadAction<ClientFiles.Tags[]>
    ) => {
      state.tags = action.payload
    },
    saveCreateTags: (
      state: ClientFilesState,
      action: PayloadAction<ClientFiles.CreateTag[]>
    ) => {
      state.createTags = action.payload
    },
    saveStlFiles: (
      state: ClientFilesState,
      action: PayloadAction<ClientFiles.ClientFile[]>
    ) => {
      state.stlFiles = action.payload
    },
    // Create
    createTags: (
      state: ClientFilesState,
      action: PayloadAction<ClientFiles.CreateTag[]>
    ) => {
      const tags: ClientFiles.Tags[] = action.payload.map((tag) => ({
        id: tag.id,
        name: tag.name,
        requiredMimetypes: tag.requiredMimetypes,
        children: [],
      }))

      state.tags = [...state.tags, ...tags]
    },

    changeTag: (
      state: ClientFilesState,
      action: PayloadAction<ClientFiles.ReqChangeTag>
    ) => {
      state.tags = state.tags.map((tag: ClientFiles.Tags) => {
        if (tag.id === action.payload.id) {
          return {
            ...tag,
            name: action.payload.data.name,
            requiredMimetypes: action.payload.data.requiredMimetypes?.length
              ? action.payload.data.requiredMimetypes
              : tag.requiredMimetypes,
          }
        }

        return {
          ...tag,
          children: (tag.children as ClientFiles.ChildrenTag[]).map(
            (childTag) => {
              if (childTag.id === action.payload.id) {
                return {
                  ...childTag,
                  name: action.payload.data.name,
                  requiredMimetypes: action.payload.data.requiredMimetypes
                    ?.length
                    ? action.payload.data.requiredMimetypes
                    : childTag.requiredMimetypes,
                }
              }

              return childTag
            }
          ),
        }
      })
    },

    createChildTag: (
      state: ClientFilesState,
      action: PayloadAction<{
        childTag: ClientFiles.CreateTag
        parentId: number
      }>
    ) => {
      const idx = state.tags.findIndex(
        (tag) => tag.id === action.payload.parentId
      )

      state.tags[idx] = {
        ...state.tags[idx],
        children: [
          ...state.tags[idx].children,
          {
            id: action.payload.childTag.id,
            name: action.payload.childTag.name,
            requiredMimetypes: action.payload.childTag.requiredMimetypes,
            children: [],
            files: [],
          },
        ],
      }
    },

    // Files
    deleteFile: (state: ClientFilesState, action: PayloadAction<number>) => {
      if (state.detailTag) {
        state.detailTag = {
          ...state.detailTag,
          files: state.detailTag.files.filter(
            (file) => file.id !== action.payload
          ),
        }
      }
    },
    saveNewFile: (
      state: ClientFilesState,
      action: PayloadAction<{ file: ClientFiles.ClientFile; tagId: number }>
    ) => {
      if (state.detailTag) {
        state.detailTag = {
          ...state.detailTag,
          files: [...state.detailTag.files, action.payload.file],
        }
      }
    },

    updateImage: (
      state: ClientFilesState,
      action: PayloadAction<{ file: ClientFiles.ClientFile }>
    ) => {
      if (state.detailTag) {
        state.detailTag = {
          ...state.detailTag,
          files: state.detailTag.files.map((img) => {
            if (img.id === action.payload.file.id) {
              return {
                ...img,
                url: action.payload.file.url,
              }
            }
            return img
          }),
        }
      }
    },

    // Links
    saveLink: (state: ClientFilesState, action: PayloadAction<string>) => {
      state.link = action.payload
    },
    saveTagByLink: (
      state: ClientFilesState,
      action: PayloadAction<ClientFiles.TagByLink>
    ) => {
      state.tagByLink = action.payload
    },

    // Resets & Clears
    clearCreateTags: (state: ClientFilesState) => {
      state.createTags = []
    },
    clearTags: (state: ClientFilesState) => {
      state.createTags = []
    },
    clearLink: (state: ClientFilesState) => {
      state.link = null
    },
    clearTagDetail: (state: ClientFilesState) => {
      state.detailTag = null
    },
    clearStlFiles: (state: ClientFilesState) => {
      state.stlFiles = null
    },

    // Loading
    toggleLoadingDetail: (
      state: ClientFilesState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingDetail = action.payload
    },
    toggleLoading: (
      state: ClientFilesState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload
    },
    toggleLoadingHandler: (
      state: ClientFilesState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingHandler = action.payload
    },
    toggleLoadingLink: (
      state: ClientFilesState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingLink = action.payload
    },
  },
})

export default slice.reducer

// Action Creators Async
export const clientFilesActions = {
  ...slice.actions,
  // Async
  fetchFilesByTagAsync: (payload: number): ClientFilesActionTypes => ({
    type: types.FETCH_FILES_BY_TAG,
    payload,
  }),
  fetchTagsClientAsync: (
    payload: ClientFiles.ReqFetchTagsClient
  ): ClientFilesActionTypes => ({
    type: types.FETCH_TAGS_CLIENT,
    payload,
  }),
  changeAsync: (payload: ClientFiles.ReqChangeTag): ClientFilesActionTypes => ({
    type: types.CHANGE_TAG,
    payload,
  }),
  createAsync: (payload: ClientFiles.ReqCreateTag): ClientFilesActionTypes => ({
    type: types.CREATE_TAG,
    payload,
  }),
  deleteFileAsync: (
    payload: ClientFiles.ReqDeleteFile
  ): ClientFilesActionTypes => ({
    type: types.DELETE_FILE,
    payload,
  }),
  createLinkAsync: (
    payload: ClientFiles.ReqCreateTagLink
  ): ClientFilesActionTypes => ({
    type: types.CREATE_LINK,
    payload,
  }),
  fetchFilesByLinkAsync: (payload: string): ClientFilesActionTypes => ({
    type: types.FETCH_FILES_BY_LINK,
    payload,
  }),
  fetchClientStlFilesAsync: (payload: number): ClientFilesActionTypes => ({
    type: types.FETCH_CLIENT_STL_FILES,
    payload,
  }),
}
