import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { AxiosResponse } from 'axios'
import type { SagaIterator } from '@redux-saga/core'

// Local
import { ERRORS_MAIN } from '@/constants'
import { clientsActions } from '@/bus/clients'
import { uiActions } from '@/bus/ui'
import type { Client } from '@/bus/clients'
// Local Dir
import { apiClients } from '../../api'
import type {
  ChangeAsync,
  ChangeJawAsync,
  ChangeLinkAsync,
  UpdateEligibleAsync,
  UpdateTreatmentPackageAsync,
} from '../../types'

export function* changeWorker(action: ChangeAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleLoading(true))
    // Api call or some async actions
    const response: AxiosResponse<Client.Detail> = yield call(
      apiClients.change,
      action.payload
    )
    if (response.data) {
      yield put(clientsActions.changeItem(response.data))
      yield put(clientsActions.toggleLoading(false))
      yield call(message.success, `Зміни збереженно 💾`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Clients changeItem workers'))
    yield call(message.error, ERRORS_MAIN)
    yield put(clientsActions.toggleLoading(false))
  }
}

export function* changeLinkWorker(action: ChangeLinkAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleLoading(true))
    // Api call or some async actions
    const response: AxiosResponse<Client.Detail> = yield call(
      apiClients.change,
      action.payload
    )
    if (response.data) {
      yield put(clientsActions.changeItemLink(action.payload.data.personalLink))
      yield put(clientsActions.toggleLoading(false))
      yield call(message.success, `Зміни збереженно 💾`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Clients changeItem workers'))
    yield call(message.error, ERRORS_MAIN)
    yield put(clientsActions.toggleLoading(false))
  }
}

export function* changeJaw(action: ChangeJawAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleLoading(true))

    const response = yield call(apiClients.changeJaw, action.payload)
    if (response.data) {
      yield put(clientsActions.changeItem(response.data))
      yield put(clientsActions.toggleLoading(false))
      yield call(message.success, `Stl збережена 💾`)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Clients change top jaw workers'))
    yield call(message.error, ERRORS_MAIN)
    yield put(clientsActions.toggleLoading(false))
  }
}

export function* updateEligible(action: UpdateEligibleAsync): SagaIterator {
  try {
    yield call(apiClients.change, {
      id: action.payload.clientId,
      data: {
        isEligible: action.payload.isEligible,
      },
    })
    yield put(clientsActions.changeEligible(action.payload.isEligible))
  } catch (error) {
    yield call(message.error, ERRORS_MAIN)
  }
}

export function* updateTreatmentPackage(
  action: UpdateTreatmentPackageAsync
): SagaIterator {
  try {
    yield call(apiClients.change, {
      id: action.payload.clientId,
      data: {
        treatmentPackage: action.payload.treatmentPackage,
      },
    })
    yield put(
      clientsActions.changeTreatmentPackage(action.payload.treatmentPackage)
    )
  } catch (error) {
    yield call(message.error, ERRORS_MAIN)
  }
}
