import { Product } from '@/bus/products'
import { apiProducts } from '@/bus/products/api'
import { useEventsListener } from '@/hooks/useEventsListener'
import {
  CheckCircleOutlined,
  DownloadOutlined,
  FileSyncOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, DatePicker, message, Modal, Row, Skeleton, Steps } from 'antd'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { SelectDocumentTemplate } from './components'
import styles from './styles.module.scss'

export const DocumentGeneratorSmart = () => {
  const [isVisible, setVisible] = useState(false)
  const [clientId, setClientId] = useState(null)
  const [template, setTemplate] = useState<Product.Template>(null)
  const tagIdRef = useRef(null)
  const successRef = useRef(() => {})
  const [documentDate, setDocumentDate] = useState(moment())
  const [step, setStep] = useState(0)

  useEventsListener(
    'generateDocument',
    (data: { clientId: number; tagId: number; onSuccess: () => any }) => {
      setClientId(data.clientId)
      tagIdRef.current = data.tagId
      successRef.current = data.onSuccess
    },
    [setClientId, tagIdRef, successRef]
  )

  useEffect(() => {
    setVisible(Boolean(clientId))
  }, [clientId])

  const onClose = () => {
    setTemplate(null)
    setClientId(null)
    setStep(0)
  }

  const generate = async () => {
    setStep(2)
    try {
      await apiProducts.generateDocument(
        template.id,
        clientId,
        tagIdRef.current,
        documentDate.toString()
      )
      if (successRef.current) successRef.current()
    } finally {
      setStep(3)
    }
  }

  const renderSteps = () => {
    if (step === 0) {
      return (
        <div>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 30 }}
          >
            <Text>Дата створення документа</Text>
            <DatePicker
              value={documentDate}
              onChange={setDocumentDate}
              style={{ width: 200 }}
            />
          </Row>
          <Row justify="center">
            <Button disabled={!documentDate} onClick={() => setStep(1)}>
              Далі
            </Button>
          </Row>
        </div>
      )
    }

    if (step === 1) {
      return (
        <div>
          <div style={{ marginBottom: 30 }}>
            <Text>Оберіть шаблон:</Text>
            <SelectDocumentTemplate
              onSelect={setTemplate}
              selectedTemplate={template}
              limit={100}
            />
          </div>
          <Row justify="center">
            <Button disabled={!template} onClick={() => generate()}>
              Далі
            </Button>
          </Row>
        </div>
      )
    }
    if (step === 2) {
      return (
        <div>
          <Text style={{ textAlign: 'center', display: 'block' }}>
            Генерування файлу в процесі. Після успішної генерація документ буде
            доступний в теці
          </Text>
          <br />
          <LoadingOutlined style={{ textAlign: 'center', display: 'block' }} />
        </div>
      )
    }

    if (step === 3) {
      return (
        <div>
          <Text style={{ textAlign: 'center', display: 'block' }}>
            Документ успішно згенерований
          </Text>
        </div>
      )
    }
  }

  return (
    <>
      <Modal
        className={styles.container}
        visible={isVisible}
        title={'Генерування документа'}
        bodyStyle={{
          minHeight: '400px',
          paddingLeft: '40px',
          paddingRight: '40px',
        }}
        onCancel={onClose}
        footer={null}
      >
        <Steps
          size="small"
          current={step}
          onChange={setStep}
          style={{ marginBottom: 30 }}
        >
          <Steps.Step disabled />
          <Steps.Step disabled />
          <Steps.Step disabled icon={<FileSyncOutlined />} />
          <Steps.Step disabled icon={<CheckCircleOutlined />} />
        </Steps>

        {renderSteps()}
      </Modal>
    </>
  )
}
