// #region Global Imports
import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
// #region Local Imports
import { uiReducer } from '@/bus/ui'
import { UiState } from '@/bus/ui/types'
import { authReducer } from '@/bus/auth'
import { AuthState } from '@/bus/auth/types'
// #region Entities Modules
import { settingsReducer } from '@/bus/settings'
import { clientsReducer } from '@/bus/clients'
import { clientFilesReducer } from '@/bus/client-files'
import { partnersReducer } from '@/bus/partners'
import { citiesReducer } from '@/bus/cities'
import { ratingReducer } from '@/bus/rating'
import { productsReducer } from '@/bus/products'
import { productionsReducer } from '@/bus/productions'

const entitiesReducer = combineReducers({
  clients: clientsReducer,
  rating: ratingReducer,
  clientFiles: clientFilesReducer,
  settings: settingsReducer,
  partners: partnersReducer,
  cities: citiesReducer,
  products: productsReducer,
  productions: productionsReducer,
})

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    entities: entitiesReducer,
    ui: uiReducer,
  })

export type EntitiesState = ReturnType<typeof entitiesReducer>
export type RootState = {
  router: RouterState
  auth: AuthState
  entities: EntitiesState
  ui: UiState
}
export default rootReducer
