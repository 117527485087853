import { SagaIterator } from 'redux-saga'
import { FetchItemsAsync, FetchStatisticsAsync } from '../../types'
import { call, delay, put } from 'redux-saga/effects'
import { ProductionStatus } from '@/configs/BackendEnum'
import { productionsActions } from '../../slice'
import { AxiosResponse } from 'axios'
import { Productions } from '../../namespace'
import { IPaginationResult } from '@/types/pagination-result.interface'
import { apiProductions } from '../../api'
import { uiActions } from '@/bus/ui'
import { message } from 'antd'
import { ERRORS_MAIN } from '@/constants'

const typeByStatus: Record<
  ProductionStatus,
  'created' | 'printing' | 'production' | 'done' | 'sent'
> = {
  [ProductionStatus.Created]: 'created',
  [ProductionStatus.Print]: 'printing',
  [ProductionStatus.Production]: 'production',
  [ProductionStatus.Done]: 'done',
  [ProductionStatus.Sent]: 'sent',
}

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  const type = typeByStatus[action.payload.status]
  try {
    if (action.payload.searchString) {
      yield delay(600)
    }
    yield put(productionsActions.toggleLoading({ type, state: true }))

    // Api call or some async actions
    const response: AxiosResponse<IPaginationResult<Productions.ListItem>> =
      yield call(apiProductions.fetchItems, action.payload)

    if (response.data) {
      yield put(productionsActions.saveItems({ type, ...response.data }))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Productions fetchItems workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(productionsActions.toggleLoading({ type, state: false }))
  }
}

export function* fetchStatistics(action: FetchStatisticsAsync): SagaIterator {
  try {
    // Api call or some async actions
    const response: AxiosResponse<Productions.Statistics> = yield call(
      apiProductions.fetchStatistics
    )

    if (response.data) {
      yield put(productionsActions.saveStatistics(response.data))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Productions fetchStatistics workers'))
    yield call(message.error, ERRORS_MAIN)
  }
}
