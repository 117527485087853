//Core
import type { SagaIterator } from 'redux-saga'
import { all, call, takeEvery } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import { fetchRoles } from './workers'

function* watchRoles(): SagaIterator {
  yield takeEvery(types.FETCH_ROLES_ASYNC, fetchRoles)
}

export function* watchSettings(): SagaIterator {
  yield all([call(watchRoles)])
}
