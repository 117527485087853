import { call, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { SagaIterator } from '@redux-saga/core'

// Local
import { ERRORS_MAIN } from '@/constants'
// Local Dir
import { clientFilesActions } from '@/bus/client-files'
import { uiActions } from '@/bus/ui'
import { apiClientFiles } from '../../api'
import type { ChangeTagAsync } from '../../types'

export function* changeFileTag(action: ChangeTagAsync): SagaIterator {
  try {
    yield put(clientFilesActions.toggleLoadingHandler(true))
    // Api call or some async actions
    yield call(apiClientFiles.changeTag, action.payload)

    yield put(clientFilesActions.changeTag(action.payload))
    yield put(clientFilesActions.toggleLoadingHandler(false))
    yield call(message.success, `Зміни збереженно 💾`)
  } catch (error) {
    yield put(uiActions.emitError(error, 'Domain changeItem workers'))
    yield call(message.error, ERRORS_MAIN)
    yield put(clientFilesActions.toggleLoadingHandler(false))
  }
}
