import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
// Local
import { RouteEnum } from '@/configs/RouteEnum'
import { FileOutline, NewsOutline, QuestionOutline } from '@/assets/icons'
import { Logo } from '@/components'
import styles from './styles.module.scss'

export const AsideMenu: React.FC = () => {
  const _renderMenuLink = () => {
    const data = [
      {
        id: '3',
        href: RouteEnum.News,
        title: 'Новини',
        icon: NewsOutline,
      },
      {
        id: '4',
        href: RouteEnum.FAQ,
        title: 'Питання/Відповіді',
        icon: FileOutline,
      },
      {
        id: '5',
        href: RouteEnum.Feedback,
        title: 'Задати питання',
        icon: QuestionOutline,
      },
    ]
    return (
      <Menu selectable={false}>
        {data.map((item) => {
          const Icon = item.icon
          return (
            <Menu.Item key={item.id} className={styles.link}>
              <span>
                <Icon className={styles.link__icon} />
                <a
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  {item.title}
                </a>
              </span>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  return (
    <Layout.Sider
      trigger={null}
      theme="light"
      collapsible
      collapsed={false}
      className={styles.root}
      width={240}
    >
      <div className={styles.root__wrapper}>
        <div className={styles.root__content}>
          <Link to={RouteEnum.App} className={styles.logo}>
            <Logo />
          </Link>

          {_renderMenuLink()}
        </div>
      </div>
    </Layout.Sider>
  )
}
