import React from 'react'
import { Layout } from 'antd'

export const Footer: React.FC = () => {
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      <h4 className="text-copy">Impress © {new Date().getFullYear()}</h4>
    </Layout.Footer>
  )
}
