import axios from '@/services/axios'
import { AxiosPromise } from 'axios'
import { Rating } from './namespace'

export const apiRating = {
  fetchItems(): AxiosPromise<Rating.ResFetchItems> {
    return axios({
      url: '/admin/reviews',
      method: 'get',
    })
  },
}
