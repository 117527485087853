import React, { useCallback, useRef, useState } from 'react'
import { Button, Carousel, Modal } from 'antd'
import { useEventsListener } from "@/hooks/useEventsListener"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styles from './styles.module.scss'
import { DownloadOutlined } from "@ant-design/icons"
import { useCapture } from 'react-capture'
import moment from 'moment';

interface IProps { }

interface IEventOptions {
  items: IPhotoComparison[]
  onClose: () => void
}

interface IPhotoComparison {
  url: string
  title: string
}

export const ModalPhotoComparisonPreview = (props: IProps) => {
  const [isVisible, setVisible] = useState(false)
  const [photosForComparison, setPhotosForComparison] = useState<IPhotoComparison[]>([])
  const { snap } = useCapture()
  const captureRef = useRef()

  const onDownload = useCallback(() => {
    snap(captureRef, { file: `photo-comparison-${moment().format('DD-MM-YYYY__HH-mm')}.png` })
  }, [snap, captureRef])

  useEventsListener(
    'openPreviewModalForPhotoComparison',
    (data: IEventOptions) => {
      setVisible(true)
      setPhotosForComparison(data.items)
    }, [photosForComparison]
  )

  const renderTitle = () => {
    let title = photosForComparison.map((it) => { return it.title })
    return (`Порівняння ${photosForComparison.length} фото: ${title}`)
  }

  const onClose = () => {
    setVisible(false)
    setPhotosForComparison([])
  }

  return (
    <>
      <Modal
        className={styles.container}
        visible={isVisible}
        title={renderTitle()}
        bodyStyle={{ height: '85vh', paddingLeft: '40px', paddingRight: '40px', }}
        width={1200}
        onCancel={onClose}
        footer={
          <Button
            type="link"
            onClick={() => onDownload()}
            icon={<DownloadOutlined />}
          >
            Завантажити
          </Button>
        }
      >
        <div className={styles.content} ref={captureRef}>
          {photosForComparison.map((item) => {
            return (
              <LazyLoadImage
                key={item.title}
                alt={item.title}
                effect="blur"
                wrapperClassName={styles.image}
                src={item.url}
              />
            )
          })}
        </div>
      </Modal>
    </>
  )
}
