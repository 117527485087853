import React from 'react'
import { Col, Row, Table } from 'antd'
import { usePagination } from '@/hooks'
import type { Partner } from '@/bus/partners'
import styles from './styles.module.scss'
import { useColumns, usePaymentsList } from './hooks'

interface IPaymentTableProps {
  loadParams: Record<any, any>
}

export const PaymentsListSmart: React.FC<IPaymentTableProps> = (props) => {
  const { list, handleTableChange } = usePaymentsList({
    loadParams: props.loadParams,
  })

  const columns = useColumns()

  return (
    <Row justify="center" className={styles.wrapTable}>
      <Col span={24}>
        <Table
          className={styles.table}
          columns={columns}
          loading={list.isLoading}
          onChange={handleTableChange}
          dataSource={list.items as any[]}
          pagination={{
            total: list.loadParams.count,
            pageSize: list.loadParams.limit,
            current: list.loadParams.page,
            position: ['bottomCenter'],
          }}
          scroll={{ x: 768 }}
          rowKey={(item: Partner.Item) => item.id.toString()}
        />
      </Col>
    </Row>
  )
}
