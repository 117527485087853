import { RootState } from '@/core/store/rootReducer'
import { Product } from '@/bus/products';

export const getItems = (state: RootState) => state.entities.products.items
export const getCount = (state: RootState) => state.entities.products.count
export const isLoading = (state: RootState) => state.entities.products.loading
export const getProduct = (id) => (state: RootState) => {
   const items = getItems(state)
   const product = items.find((item: Product.Item) => item.id === id)
   return product
}