import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

/**
 *
 * @param {string} parse - сылка в которой может хранится ключ
 * @param {string} redirect - куда перенаправить пользователя после ошибки парсинга
 *
 * @return {number} - id - ключ страницы
 */
export const useCheckId = (
  parse: string,
  redirect: string,
  checkExist?: (id: number) => boolean
): number | null => {
  const match = useRouteMatch<{ id: string }>(parse)

  const history = useHistory()

  const [id, setId] = useState<null | number>(null)

  useEffect(() => {
    if (match.params.id) {
      const parseId = parseInt(match.params.id, 10)

      if (checkExist) {
        if (checkExist(parseId)) {
          setId(parseId)
        } else {
          history.push(redirect)
        }
      } else {
        setId(parseId)
      }
    } else {
      history.push(redirect)
    }
  }, [checkExist, history, match.params.id, redirect])

  return id
}
