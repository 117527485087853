
import { all, call, takeLatest } from "redux-saga/effects";
import { types } from '../types'
import { fetchItems } from "./workers";

function* watchFetchItems() {
   yield takeLatest(types.FETCH_ITEMS, fetchItems)
}

export function* watchCities() {
   yield all([
      call(watchFetchItems),
   ])
}