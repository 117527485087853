import { call, delay, put } from 'redux-saga/effects'
import { message } from 'antd'
import type { AxiosResponse } from 'axios'
import type { SagaIterator } from 'redux-saga'
// Local
import { ERRORS_MAIN } from '@/constants'
import { uiActions } from '@/bus/ui'
import { Product, productsActions } from "../.."
// Local Dir
import {
  FetchItemsAsync,
} from '../../types'
import { apiProducts } from "../../api"

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    yield put(productsActions.toggleLoading(true))

    // Api call or some async actions
    const response: AxiosResponse<Product.ResFetchItems> = yield call(
      apiProducts.fetchItems,
      action.payload
    )

    if (response.data) {
      yield put(productsActions.saveItems(response.data))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Products fetchItems workers'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(productsActions.toggleLoading(false))
  }
}
