import { useDispatch } from 'react-redux'
import { message } from 'antd'
import { getExtension } from '@/helpers/getExtension'
import { presignedUploaderService } from '@/services/presigned-uploader.service'
import { eventsObserver } from '@/services/observable.service'
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface'
import { RcFile, UploadProps } from 'antd/lib/upload/interface'
import { apiClients } from '@/bus/clients/api'
import { clientsActions } from '@/bus/clients'
import { isJpgOrPng, isMp4 } from '@/helpers/guardFiles'
import { apiAuth } from '@/bus/auth/api'
import { authActions } from '@/bus/auth'

export const useChangeAvatar = () => {
  const dispatch = useDispatch()

  const beforeUpload = (
    file: RcFile
  ): ReturnType<UploadProps['beforeUpload']> => {
    if (!isJpgOrPng(file) && !isMp4(file)) {
      message.error(
        'Ви можете завантажити лише JPG/PNG/MP4 файли розміром до 1МВ!'
      )
      return false
    }

    return true
  }

  const uploadAvatar = async (
    options: RcCustomRequestOptions,
    clientId: number
  ) => {
    if (!clientId) return message.warn('Не вказаний клієнт!')

    //@ts-ignore
    const mimetype = getExtension(options.file?.name)
    const filename = (options.file as any).name
    const hideLoadingMessage = message.loading(`Файл завантажується ...`, 0)

    const id = new Date().getTime() + Math.random()

    const data = await presignedUploaderService.uploadAvatar(
      options.file,
      apiClients.getAvatarPresignedUrl,
      {
        filename: filename,
        mimetype,
        userId: clientId,
      },
      (p) => {
        eventsObserver.broadcast('onItemProgrress', {
          filename: filename,
          progress: Math.round((p.loaded * 100) / p.total),
          id: id,
        })
      }
    )

    eventsObserver.broadcast('onItemProgrress', {
      filename: filename,
      progress: 101,
      id: id,
    })

    hideLoadingMessage()

    if (options.onSuccess) options.onSuccess(data, options.file as any)

    if (data) {
      dispatch(clientsActions.changeAvatar(data.url))
      message.success(`Зміни збереженно 💾`)
    }
  }

  const uploadAccountAvatar = async (options: RcCustomRequestOptions) => {
    //@ts-ignore
    const mimetype = getExtension(options.file?.name)
    const filename = (options.file as any).name
    const hideLoadingMessage = message.loading(`Файл завантажується ...`, 0)

    const id = new Date().getTime() + Math.random()

    const data = await presignedUploaderService.uploadAvatar(
      options.file,
      apiAuth.getAvatarPresignedUrl,
      {
        filename: filename,
        mimetype,
      },
      (p) => {
        eventsObserver.broadcast('onItemProgrress', {
          filename: filename,
          progress: Math.round((p.loaded * 100) / p.total),
          id: id,
        })
      }
    )

    eventsObserver.broadcast('onItemProgrress', {
      filename: filename,
      progress: 101,
      id: id,
    })

    hideLoadingMessage()

    if (options.onSuccess) options.onSuccess(data, options.file as any)

    if (data) {
      dispatch(authActions.changeAvatar(data.url))
      message.success(`Зміни збереженно 💾`)
    }
  }

  return {
    beforeUpload,
    uploadAvatar,
    uploadAccountAvatar,
  }
}
