import { SagaIterator } from "redux-saga";
import { DeleteAsync } from "../../types";
import { clientsActions } from '@/bus/clients'
import { apiClients } from "../../api";
import { put, call } from "redux-saga/effects";
import { message } from "antd";
import { uiActions } from "@/bus/ui";
import { ERRORS_MAIN } from "@/constants";

export function* deleteItem (action: DeleteAsync): SagaIterator {
  try {
    yield put(clientsActions.toggleLoading(true))
    yield call(apiClients.deleteClient, action.payload)
    yield call(message.success, 'Клієнта видалено')
  } catch (error) {
    yield put(uiActions.emitError(error, 'Clients deleteWorker'))
    yield call(message.error, ERRORS_MAIN)
  } finally {
    yield put(clientsActions.toggleLoading(false))
  }
}
