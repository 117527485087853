import React from 'react'
import Icon from '@ant-design/icons'

const CopyOutlineSvg = (props) => (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M27.167 12c.46 0 .833.373.833.833v12.5c0 .46-.373.834-.833.834l-3.334-.001v1.626a.875.875 0 0 1-.78.87l-.095.005h-9.083a.875.875 0 0 1-.87-.78L13 27.792V15.375c0-.451.341-.822.78-.87l.095-.005h3.291v-1.667c0-.46.374-.833.834-.833h9.167zm-10.001 4.166h-2.5V27h7.5v-.834H18a.833.833 0 0 1-.833-.833l-.001-9.167zm9.167-2.5h-7.5V24.5h7.5V13.667z"
        id="se2k8m8waa"
      />
    </defs>
    <use
      fill="#FFFFFF"
      fill-rule="nonzero"
      xlinkHref="#se2k8m8waa"
      transform="translate(-13 -12)"
    />
  </svg>
)

export const CopyOutline = (props) => (
  <Icon component={CopyOutlineSvg} {...props} />
)
