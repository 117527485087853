import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Typing
import { types } from './types'
import type { AuthActionTypes, AuthState } from './types'
import type { Auth, Account } from './namespace'

const initialState: AuthState = {
  isAuthenticate: false,
  isInitialized: false,
  dataRestorePassword: {},
  currentAccount: null,
  wallet: null,
}

const slice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    auth: (state: AuthState) => {
      state.isAuthenticate = true
    },
    logout: (state: AuthState) => {
      state.isAuthenticate = false
    },
    initialized: (state: AuthState) => {
      state.isInitialized = true
    },
    saveDataRestorePassword: (
      state: AuthState,
      action: PayloadAction<{
        controlName: 'email' | 'secretCode'
        data: string
      }>
    ) => {
      state.dataRestorePassword[action.payload.controlName] =
        action.payload.data
    },
    clearDataRestorePassword: (state: AuthState) => {
      state.dataRestorePassword = {}
    },
    saveCurrentAccount: (
      state: AuthState,
      action: PayloadAction<Account.CurrentAccount>
    ) => {
      state.currentAccount = action.payload
    },
    changeAccount: (
      state: AuthState,
      action: PayloadAction<Account.CurrentAccount>
    ) => {
      state.currentAccount = action.payload
    },
    changePrice: (
      state: AuthState,
      action: PayloadAction<Account.CurrentPrice>
    ) => {
      state.currentPrice = action.payload
    },
    changeAvatar: (state: AuthState, action: PayloadAction<string>) => {
      state.currentAccount.info.photoUrl = action.payload
    },
    saveWallet: (state: AuthState, action: PayloadAction<Account.Wallet>) => {
      state.wallet = action.payload
    },
  },
})

export default slice.reducer

// Action Creators
export const authActions = {
  // Sync
  ...slice.actions,
  // Async
  signInAsync: (payload: {
    data: Auth.ReqSignIn
    isSaveSession: boolean
  }): AuthActionTypes => ({
    type: types.SIGN_IN_ASYNC,
    payload,
  }),
  logoutAsync: (): AuthActionTypes => ({
    type: types.LOGOUT_ASYNC,
  }),
  initializedAsync: (): AuthActionTypes => ({
    type: types.INITIALIZED_ASYNC,
  }),
  confirmEmailAsync: (payload: string): AuthActionTypes => ({
    type: types.CONFIRM_EMAIL_ASYNC,
    payload,
  }),
  // Reset Password
  resetPasswordStartAsync: (email: string): AuthActionTypes => ({
    type: types.RESET_PASSWORD_START_ASYNC,
    payload: email,
  }),
  resetPasswordCheckAsync: (payload: {
    email: string
    secretCode: string
  }): AuthActionTypes => ({
    type: types.RESET_PASSWORD_CHECK_ASYNC,
    payload,
  }),
  resetPasswordEndAsync: (payload: {
    email: string
    secretCode: string
    password: string
  }): AuthActionTypes => ({
    type: types.RESET_PASSWORD_END_ASYNC,
    payload,
  }),
  fetchCurrentAccountAsync: (): AuthActionTypes => ({
    type: types.FETCH_ACCOUNT_ASYNC,
  }),
  changeAccountAsync: (payload: Account.ReqChange): AuthActionTypes => ({
    type: types.CHANGE_ACCOUNT_ASYNC,
    payload,
  }),
  fetchAccountWalletAsync: (): AuthActionTypes => ({
    type: types.FETCH_WALLET_ASYNC,
  }),
}
