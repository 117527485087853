import {
  EAreAlignersSuitable,
  EChangeTargets,
  EJointProblems,
  EOrthologyBefore,
  ETreatTargets,
  EWisdomTeethInformation,
  EGraphicDesign,
} from '@/configs/BackendEnum'

export const initialValues = {
  whatDoUWontToTreat: ETreatTargets.UNSELECTED,
  areThereAnyJointsProblems: EJointProblems.UNSELECTED,
  informationAboutWisdomTeeth: EWisdomTeethInformation.UNSELECTED,
  hasThereBeenOrthologyBefore: EOrthologyBefore.UNSELECTED,
}

export type DiagnoseConfigsSelects = {
  key: string
  title: string
  data?: {
    value: number
    text: string
  }[]
  map: Map<number, string>
  additionalField?: {
    key: string
    title: string
    previewTitle: string
    targetValue: number
  }
  style?: any
  span?: number
}

export const configSelects: DiagnoseConfigsSelects[] = [
  {
    key: 'informationAboutWisdomTeeth',
    title: 'Інформація про зуби мудрості',
    data: [
      { value: EWisdomTeethInformation.UNSELECTED, text: 'Немає' },
      { value: EWisdomTeethInformation.CUT_THROUGH, text: 'Прорізались' },
      {
        value: EWisdomTeethInformation.NOT_CUT_THROUGH_YET,
        text: 'Ще не прорізались',
      },
      { value: EWisdomTeethInformation.ALREADY_DELETED, text: 'Вже видалені' },
      { value: EWisdomTeethInformation.MISSING, text: 'Відсутні' },
      {
        value: EWisdomTeethInformation.DONT_KNOW,
        text: 'Не знаю про їх наявність та стан',
      },
    ],
    map: new Map([
      [EWisdomTeethInformation.UNSELECTED, 'Немає'],
      [EWisdomTeethInformation.CUT_THROUGH, 'Прорізались'],
      [EWisdomTeethInformation.NOT_CUT_THROUGH_YET, 'Ще не прорізались'],
      [EWisdomTeethInformation.ALREADY_DELETED, 'Вже видалені'],
      [EWisdomTeethInformation.MISSING, 'Відсутні'],
      [EWisdomTeethInformation.DONT_KNOW, 'Не знаю про їх наявність та стан'],
    ]),
  },
  {
    key: 'hasThereBeenOrthologyBefore',
    title: 'Чи було раніше ортолікування',
    data: [
      { value: EOrthologyBefore.UNSELECTED, text: 'Не обрано' },
      { value: EOrthologyBefore.NO, text: 'Ні' },
      { value: EOrthologyBefore.YES, text: 'Так' },
    ],
    map: new Map([
      [EOrthologyBefore.UNSELECTED, 'Не обрано'],
      [EOrthologyBefore.NO, 'Ні'],
      [EOrthologyBefore.YES, 'Так'],
    ]),
  },
  {
    key: 'areThereAnyJointsProblems',
    title: 'Чи є проблеми з СНЩС?',
    data: [
      { value: EJointProblems.UNSELECTED, text: 'Не обрано' },
      { value: EJointProblems.NO, text: 'Ні' },
      { value: EJointProblems.YES, text: 'Так' },
    ],
    map: new Map([
      [EJointProblems.UNSELECTED, 'Не обрано'],
      [EJointProblems.NO, 'Ні'],
      [EJointProblems.YES, 'Так'],
    ]),
    additionalField: {
      key: 'jointsProblemsDescription',
      title: 'Опишіть проблему з СНЩС',
      targetValue: EJointProblems.YES,
      previewTitle: 'Опис проблеми з CНЩС',
    },
    style: {
      maxWidth: '100%',
    },
    span: 24,
  },
  {
    key: 'areAlignersSuitable',
    title: 'Чи підходять елайнери',
    map: new Map([
      [EAreAlignersSuitable.NO, 'Ні'],
      [EAreAlignersSuitable.YES, 'Так'],
    ]),
  },
  {
    key: 'graphicDesign',
    title: 'Чи є графічний дизайн',
    map: new Map([
      [EGraphicDesign.NO, 'Ні'],
      [EGraphicDesign.YES, 'Так'],
    ]),
  },
]

export const tagSelector: DiagnoseConfigsSelects = {
  key: 'whatDoYouWantToChange',
  title: 'Що бажає змінити',
  data: [
    {
      value: EChangeTargets.AESTHETICS,
      text: 'Покращити естетику (виправити положення зубів)',
    },
    { value: EChangeTargets.BITE, text: 'Виправити прикус' },
    {
      value: EChangeTargets.PAIN,
      text: 'Позбавитись від болю та/або хрускоту в скронево-нижньощелепному суглобі',
    },
    { value: EChangeTargets.ERASURE, text: 'Припинити стирання зубів' },
    { value: EChangeTargets.FACE_PROFILE, text: 'Змінити профіль обличчя' },
    { value: EChangeTargets.CLUSTERS, text: 'Позбутися скупченості зубів' },
    { value: EChangeTargets.GAPS, text: 'Закрити проміжки між зубами' },
  ],
  map: new Map([
    [
      EChangeTargets.AESTHETICS,
      'Покращити естетику (виправити положення зубів)',
    ],
    [EChangeTargets.BITE, 'Виправити прикус'],
    [
      EChangeTargets.PAIN,
      'Позбавитись від болю та/або хрускоту в скронево-нижньощелепному суглобі',
    ],
    [EChangeTargets.ERASURE, 'Припинити стирання зубів'],
    [EChangeTargets.FACE_PROFILE, 'Змінити профіль обличчя'],
    [EChangeTargets.CLUSTERS, 'Позбутися скупченості зубів'],
    [EChangeTargets.GAPS, 'Закрити проміжки між зубами'],
  ]),
}
