import React from 'react'
import Icon from '@ant-design/icons'

const CheckMarkSvg = (props) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M26.356 14.49a1.524 1.524 0 0 1 2.24 2.067l-9.143 9.905a1.524 1.524 0 0 1-2.197.044l-3.81-3.81a1.524 1.524 0 1 1 2.155-2.154l2.688 2.688 8.067-8.74z"
        id="fh9x7opmia"
      />
    </defs>
    <use
      fill="#78D675"
      fill-rule="nonzero"
      xlinkHref="#fh9x7opmia"
      transform="translate(-13 -14)"
    />
  </svg>
)

export const CheckMark = (props) => <Icon component={CheckMarkSvg} {...props} />
