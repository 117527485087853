import { Button } from "antd"
import React, { useState } from 'react'
import styles from './styles.module.scss'
import { PhotoSection } from './components/photoSection';
import _ from 'lodash'
import { useEventsListener } from "@/hooks/useEventsListener"
import { eventsObserver } from "@/services/observable.service";

interface IProps { }

interface IPhotoComparison {
   url: string
   title: string
}

export const ModalPhotoComparison = (props: IProps) => {
   const [photosForComparison, setPhotosForComparison] = useState<IPhotoComparison[]>([])
   const [isVisible, setVisible] = useState(false)
   const needPhotos = 2

   useEventsListener(
      'openModalPhotoComparison',
      (data: IPhotoComparison) => {
         setVisible(true)
         if (photosForComparison.length < needPhotos) {
            setPhotosForComparison((prevState) => ([...prevState, data]))
         }
      }, [photosForComparison]
   )

   const onComparison = () => {
      eventsObserver.broadcast('openPreviewModalForPhotoComparison', {
         items: photosForComparison,
         onClose: onClose
      })
      onClose()
   }

   const onDeletePhoto = (index: number) => {
      const oldArray = photosForComparison
      const newArray = oldArray.filter((it, i) => i !== index)
      setPhotosForComparison(newArray)
   }

   const renderContent = () => {
      const photos = photosForComparison.map((it, i) => {
         return <PhotoSection url={it.url} onPressDelete={() => onDeletePhoto(i)} />
      })
      return (
         <>
            {photos}
            {_.times(needPhotos - photosForComparison.length, () => <PhotoSection url={null} />)}
         </>
      )
   }

   const onClose = () => {
      setVisible(false)
      setPhotosForComparison([])
   }

   if (!isVisible) return null

   return (
      <div className={styles.modal__wrapper}>

         {renderContent()}

         <Button
            className={styles.btn}
            type='primary'
            size={'small'}
            disabled={photosForComparison.length < needPhotos}
            onClick={() => onComparison()}
         >
            Порівняти фото
         </Button>

         <Button
            className={styles.btn}
            type='default'
            size={'small'}
            style={{ width: '100%' }}
            onClick={() => onClose()}
         >
            Скасувати
         </Button>

      </div>
   )
}