export type CityType = {
  name: string
  key: number
}

export const Cities: CityType[] = [
  {
    name: 'Крим, Автономна Республіка',
    key: 1297699,
  },
  {
    name: 'Вінницька',
    key: 1297619,
  },
  {
    name: 'Волинська',
    key: 1297617,
  },
  {
    name: 'Дніпропетровська',
    key: 1297639,
  },
  {
    name: 'Донецька',
    key: 1297653,
  },
  {
    name: 'Житомирська',
    key: 1297629,
  },
  {
    name: 'Закарпатська',
    key: 1297637,
  },
  {
    name: 'Запорізька',
    key: 1297647,
  },
  {
    name: 'Івано-Франківська',
    key: 1297613,
  },
  {
    name: 'Київська',
    key: 1297657,
  },
  {
    name: 'Кіровоградська',
    key: 1297635,
  },
  {
    name: 'Луганська',
    key: 1297655,
  },
  {
    name: 'Львівська',
    key: 1297611,
  },
  {
    name: 'Миколаївська',
    key: 1297645,
  },
  {
    name: 'Одеська',
    key: 1297643,
  },
  {
    name: 'Полтавська',
    key: 1297631,
  },
  {
    name: 'Рівненська',
    key: 1297623,
  },
  {
    name: 'Сумська',
    key: 1297641,
  },
  {
    name: 'Тернопільська',
    key: 1297615,
  },
  {
    name: 'Харківська',
    key: 1297625,
  },
  {
    name: 'Херсонська',
    key: 1297651,
  },
  {
    name: 'Хмельницька',
    key: 1297621,
  },
  {
    name: 'Черкаська',
    key: 1297633,
  },
  {
    name: 'Чернівецька',
    key: 1297627,
  },
  {
    name: 'Чернігівська',
    key: 1297649,
  },
]
