import { SagaIterator } from 'redux-saga'
import { types } from '../types'
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  fetchItems,
  fetchStatistics,
  startPrint,
  startProduction,
  setDone,
  setSent,
} from './workers'

function* watchFetchItems(): SagaIterator {
  yield takeLatest(types.FETCH_ITEMS, fetchItems)
}

function* watchFetchStatistics(): SagaIterator {
  yield takeLatest(types.FETCH_STATISTICS, fetchStatistics)
}

function* watchStartPrint(): SagaIterator {
  yield takeEvery(types.START_PRINT, startPrint)
}

function* watchStartProduction(): SagaIterator {
  yield takeEvery(types.START_PRODUCTION, startProduction)
}

function* watchSetDone(): SagaIterator {
  yield takeEvery(types.SET_DONE, setDone)
}

function* watchSetSent(): SagaIterator {
  yield takeEvery(types.SET_SENT, setSent)
}

export function* watchProductions(): SagaIterator {
  yield all([
    call(watchFetchItems),
    call(watchFetchStatistics),
    call(watchStartPrint),
    call(watchStartProduction),
    call(watchSetDone),
    call(watchSetSent),
  ])
}
