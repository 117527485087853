import { createSelector } from 'reselect'

import { RootState } from '@/core/store/rootReducer'
import { SettingsState } from './types'

// Selector
// Memoization

export const getRoles = createSelector(
  (state: RootState) => state.entities.settings,
  (settings: SettingsState) => settings.roles
)
