import {
  clientIllnessKeyArray,
  clientIllnessNotesOn,
  clientIllnessTranslates,
} from '@/configs/BackendEnum'
import { Checkbox, Collapse, Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'
import styles from './styles.module.scss'

const { Panel } = Collapse

export const ClientIllnessRows = () => {
  const renderItems = () =>
    clientIllnessKeyArray.map((it) => {
      return (
        <div key={`illness_${it}`}>
          <Form.Item name={`illness_${it}`} valuePropName="checked">
            <Checkbox>{clientIllnessTranslates[it]}</Checkbox>
          </Form.Item>
          {clientIllnessNotesOn.includes(it) && (
            <Form.Item name={`illness_${it}_notes`}>
              <TextArea rows={4} />
            </Form.Item>
          )}
        </div>
      )
    })

  return (
    <div className={styles.clientIllnessRows}>
      <p className={styles.title}>Перенесені та супутні захворювання</p>
      <Collapse defaultActiveKey={['0']} ghost>
        <Panel header="Список" key="1">
          <div className={styles.list}>{renderItems()}</div>
        </Panel>
      </Collapse>
    </div>
  )
}
