import { uiActions } from "@/bus/ui"
import { AxiosResponse } from "axios"
import { SagaIterator } from "redux-saga"
import { call, put } from "redux-saga/effects"
import { apiCities } from "../../api"

import { City } from "../../namespace"
import { citiesActions } from "../../slice"
import { FetchItemsAsync } from "../../types"






export function* fetchItems(action: FetchItemsAsync): SagaIterator {
   try {
      yield put(citiesActions.toggleLoading(true))

      const response: AxiosResponse<City.ResFetchItems> = yield call(
         apiCities.fetchItems,
         action.payload
      )

      if (response.data) {
         yield put(citiesActions.saveItems(response.data))
      }
   } catch (error) {
      yield put(uiActions.emitError(error, 'Cities fetchItems workers'))
   } finally {
      yield put(citiesActions.toggleLoading(false))
   }
}