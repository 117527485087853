//Core
import { all, call, takeLatest } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import { deleteProduct, deleteProductTemplate, fetchItems } from './workers'

function* watchFetchItems() {
  yield takeLatest(types.FETCH_ITEMS, fetchItems)
}

function* watchDeleteProduct() {
  yield takeLatest(types.DELETE_PRODUCT, deleteProduct)
}

function* watchDeleteProductTemplate() {
  yield takeLatest(types.DELETE_PRODUCT_TEMPLATE, deleteProductTemplate)
}

export function* watchProducts() {
  yield all([
    call(watchFetchItems),
    call(watchDeleteProduct),
    call(watchDeleteProductTemplate),
  ])
}
