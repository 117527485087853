export enum ClientIllnessKey {
  Cardiovascular = 'c',
  Asthma = 'a',
  Diabetes = 'd',
  LiverDisease = 'ld',
  Gastrointestinal = 'g',
  Blood = 'b',
  Tuberculosis = 't',
  Allergic = 'al',
  Venereal = 'v',
  VIL_AIDS = 'va',
  Oncology = 'o',
  MentalIllnesses = 'mil',
  IntoleranceOfMedicines = 'iom',
  Other = 'ot',
}

export const clientIllnessKeyArray = Object.values(ClientIllnessKey)

export const clientIllnessTranslates = {
  [ClientIllnessKey.Allergic]: 'Алергічні захворювання',
  [ClientIllnessKey.Asthma]: 'Астма',
  [ClientIllnessKey.Blood]: 'Захворювання крові',
  [ClientIllnessKey.Cardiovascular]: 'Серцево-судинні',
  [ClientIllnessKey.Diabetes]: 'Цукровий діабет',
  [ClientIllnessKey.Gastrointestinal]: 'Шлунково-кишкові захворювання',
  [ClientIllnessKey.IntoleranceOfMedicines]:
    'Чи маєте непереносимість до яких-небудь лікарських засобів?',
  [ClientIllnessKey.LiverDisease]: 'Захворювання печінки (вірусний гепатит)',
  [ClientIllnessKey.Oncology]: 'Онкологічні захворювання',
  [ClientIllnessKey.Other]: 'Інше (вкажіть)',
  [ClientIllnessKey.Tuberculosis]: 'Туберкульоз',
  [ClientIllnessKey.VIL_AIDS]: 'ВІЛ/СПІД',
  [ClientIllnessKey.Venereal]: 'Венеричні захворювання',
  [ClientIllnessKey.MentalIllnesses]: 'Ментальні захворювання',
}

export const clientIllnessNotesOn = [
  ClientIllnessKey.Other,
  ClientIllnessKey.IntoleranceOfMedicines,
]
