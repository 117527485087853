import { Product } from './namespace'

export enum types {
  //Async
  FETCH_ITEMS = 'PRODUCTS/FETCH_ITEMS',
  DELETE_PRODUCT = 'PRODUCTS/DELETE',
  DELETE_PRODUCT_TEMPLATE = 'PRODUCTS/DELETE_TEMPLATE',
}

// STATE
export type ProductsState = {
  items: Product.Item[] | []
  count: number
  loading: boolean
}

// ASYNC
export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS
  payload: Product.ReqFetchItems
}

export type DeleteProductAsync = {
  type: typeof types.DELETE_PRODUCT
  payload: number
}

export type DeleteProductTemplateAsync = {
  type: typeof types.DELETE_PRODUCT_TEMPLATE
  payload: number
}

export type ProductsActionTypes =
  | FetchItemsAsync
  | DeleteProductAsync
  | DeleteProductTemplateAsync
