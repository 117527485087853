import React from 'react'
import { Form, Upload } from 'antd'
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface'
// Local
import styles from './styles.module.scss'
import { UploadProps } from 'antd/lib/upload/interface'
import type { ClientFiles } from '@/bus/client-files'

type PropsTypes = {
  name: string
  icon: React.ElementType | React.ElementType[]
  title: string
  beforeUpload: UploadProps['beforeUpload']
  request: (options: RcCustomRequestOptions) => void
  required?: boolean
  fileList: UploadFile[]
  onChange: (info: UploadChangeParam) => void
  onRemove: (file: UploadFile<ClientFiles.ClientFile>) => void
}

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

export const UploaderFiles: React.FC<PropsTypes> = (props: PropsTypes) => {
  const {
    name,
    title,
    icon,
    beforeUpload,
    request,
    required = false,
    fileList,
    onChange,
    onRemove,
  } = props

  const propsDragger: any = {
    multiple: true,
    beforeUpload,
    className: styles['uploadDrag__dragger'],
    customRequest: request as any,
    onChange,
    onRemove,
  }

  const renderIcon = () => {
    if (Array.isArray(icon)) {
      return icon.map((Icon) => (
        <Icon
          className={`${styles['uploadDrag__icon']} ${styles['uploadDrag__icon_m']}`}
        />
      ))
    }
    const Icon = icon

    return <Icon className={styles['uploadDrag__icon']} />
  }

  return (
    <div className={styles.uploadDrag}>
      <h4 className={styles['uploadDrag__title']}>
        {title} <span style={{ color: '#f5222d' }}>{required && '*'}</span>
      </h4>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
        initialValue={fileList}
      >
        <Upload.Dragger {...propsDragger}>
          {renderIcon()}
          <p className={styles['uploadDrag__text']}>
            Клацніть або перетягніть файл до цієї області для завантаження
          </p>
        </Upload.Dragger>
      </Form.Item>
    </div>
  )
}
