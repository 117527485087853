import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Rating } from './namespace'
import { RatingActionTypes, types, RatingState } from './types'

const initialState: RatingState = {
  items: [],
  loading: false,
  count: 0,
}

const slice = createSlice({
  name: 'RATING',
  initialState,
  reducers: {
    saveItems: (
      state: RatingState,
      action: PayloadAction<Rating.ResFetchItems>
    ) => {
      state.items = action.payload.items
      state.count = action.payload.count
    },
    toggleLoading: (state: RatingState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export default slice.reducer

// Action Creators Async
export const ratingActions = {
  ...slice.actions,
  // Async
  fetchItemsAsync: (): RatingActionTypes => ({
    type: types.FETCH_ITEMS,
  }),
}
