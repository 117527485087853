import { AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import jwtDecode from 'jwt-decode'
// Local
import axios from './axios'
import { Auth } from '@/bus/auth'
import { store } from '@/core/store'
import { authActions } from '@/bus/auth'
import { apiAuth } from '@/bus/auth/api'
import ENV from '@/configs'
import { KEY_AUTH_ACCESS_TOKEN, KEY_AUTH_REFRESH_TOKEN } from '@/constants'

interface IAuth {
  handleAuthentication: () => Promise<unknown>
  login: (authData: Auth.Success, isSaveSession: boolean) => void
  logout: () => void
  refreshToken: () => Promise<
    | boolean
    | {
        accessToken: string
        refreshToken: string
      }
  >
  setSession: (authData: Auth.Success | null, isSaveSession?: boolean) => void
  getAccessToken: () => string | null
  getRefreshToken: () => string | null
  isValidToken: (token: string) => boolean
  isAuthenticated: () => boolean
}

class AuthService implements IAuth {
  constructor() {
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getRefreshToken = this.getRefreshToken.bind(this)
  }

  handleAuthentication() {
    return new Promise(async (resolve, reject) => {
      const accessToken = this.getAccessToken()

      if (!accessToken) {
        reject(false)
      }

      if (this.isValidToken(accessToken)) {
        this.setSession({ accessToken })

        resolve(true)
      } else {
        const authData = await this.refreshToken()

        if (!authData) {
          this.setSession(null)
          reject(false)
        } else {
          this.setSession(authData, true)
          resolve(true)
        }
      }
    })
  }

  refreshToken = async () => {
    try {
      const token = this.getRefreshToken()
      if (!token) {
        return false
      }

      const response: AxiosResponse<Auth.ResResetToken> = await apiAuth.refreshToken(
        token
      )
      // TODO: Change refresh token on response
      return {
        accessToken: response.data.accessToken,
        refreshToken: token,
      }
    } catch (error) {
      console.log(error.response)
      if (error.response.data.statusCode === 400) {
        store.dispatch(authActions.logoutAsync())

        return false
      }
    }
  }

  refreshAuthLogic = (failedRequest): Promise<any> => {
    const token = this.getRefreshToken()

    if (!token) {
      store.dispatch(authActions.logoutAsync())
      return Promise.reject()
    }

    return axios
      .post(`${ENV.STAGE_URL}/auth/reset-token`, {
        token,
      })
      .then((tokenRefreshResponse: AxiosResponse<Auth.ResResetToken>) => {
        this.setSession({
          accessToken: tokenRefreshResponse.data.accessToken,
        })

        failedRequest.response.config.headers[
          'Authorization'
        ] = `Bearer ${tokenRefreshResponse.data.accessToken}`

        return Promise.resolve()
      })
      .catch((error) => {
        console.log(error.response)
        if (error.response && error.response.status === 400) {
          store.dispatch(authActions.logoutAsync())
        }

        return false
      })
  }

  login = (authData: Auth.Success, isSaveSession?: boolean): void => {
    this.setSession(authData, isSaveSession)
  }

  logout = () => {
    this.setSession(null)
  }

  setSession = (authData, isSaveSession = false) => {
    if (authData) {
      localStorage.setItem(KEY_AUTH_ACCESS_TOKEN, authData.accessToken)
      if (isSaveSession) {
        localStorage.setItem(KEY_AUTH_REFRESH_TOKEN, authData.refreshToken)
      }
      axios.defaults.headers.common.Authorization = `Bearer ${authData.accessToken}`
    } else {
      localStorage.removeItem(KEY_AUTH_ACCESS_TOKEN)
      localStorage.removeItem(KEY_AUTH_REFRESH_TOKEN)
      delete axios.defaults.headers.common.Authorization
    }
  }

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false
    }

    const decoded = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000

    return decoded.exp > currentTime
  }

  isAuthenticated = () => !!this.getAccessToken()

  getAccessToken = () => localStorage.getItem(KEY_AUTH_ACCESS_TOKEN)

  getRefreshToken = () => localStorage.getItem(KEY_AUTH_REFRESH_TOKEN)
}

const authService = new AuthService()

createAuthRefreshInterceptor(axios, authService.refreshAuthLogic, {
  skipWhileRefreshing: false,
})

export default authService
