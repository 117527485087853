import { call, put } from 'redux-saga/effects'
import { authActions } from '@/bus/auth'
import { successAuth } from './successAuth'
// Local
import AuthService from '@/services/AuthService'
import { uiActions } from '@/bus/ui'

export function* initialize() {
  try {
    yield call(AuthService.handleAuthentication)

    const isAuthenticated = yield call(AuthService.isAuthenticated)

    if (isAuthenticated) {
      yield call(successAuth)
    } else {
      throw new Error('Час сесії закінчився. ⌛️')
    }
  } catch (e) {
    yield put(uiActions.emitError(e, 'initialize workers'))
  } finally {
    yield put(authActions.initialized())
  }
}
