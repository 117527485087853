import { Rule } from 'rc-field-form/lib/interface'

type Rules = {
  email: Rule[]
  password: Rule[]
  confirmPassword: Rule[]
  agreement: Rule[]
  firstName: Rule[]
  lastName: Rule[]
  middleName: Rule[]
  fullName: Rule[]
  clinicName: Rule[]
  city: Rule[]
  phoneNumber: Rule[]
  birthDay: Rule[]
  birthDayRequired: Rule[]
  code: Rule[]
  site: Rule[]
  nameDir: Rule[]
  fileTypeDir: Rule[]
  customPushTitle: Rule[]
  customPushText: Rule[]
  identificationCode: Rule[]
  passportNumber: Rule[]
  required: Rule[]
}

export const rules: Rules = {
  required: [{ required: true, message: 'Поле обовязкове!' }],
  email: [
    { type: 'email', message: 'Некоректна електрона адреса!' },
    { required: true, message: "Email обов'язковий!" },
  ],
  password: [{ required: true, message: "Пароль обов'язковий!" }],
  confirmPassword: [
    { required: true, message: 'Підтвердьте свій пароль!' },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve()
        }
        return Promise.reject('Два введені вами паролі не збігаються!')
      },
    }),
  ],
  agreement: [
    {
      validator: (_, value) =>
        value ? Promise.resolve() : Promise.reject('Слід прийняти угоду'),
    },
  ],
  firstName: [
    {
      required: true,
      transform: (value) => (value ? value.trim() : value),
      message: "Будь ласка, введіть своє ім'я!",
    },
  ],
  lastName: [
    {
      required: true,
      transform: (value) => (value ? value.trim() : value),
      message: 'Будь ласка, введіть своє прізвище!',
    },
  ],
  middleName: [
    {
      required: true,
      transform: (value) => (value ? value.trim() : value),
      message: 'Будь ласка вкажіть ваше по батькові!',
    },
  ],
  fullName: [
    {
      required: true,
      transform: (value) => (value ? value.trim() : value),
      message: "Вкажіть повне ім'я пацієнта!",
    },
  ],
  clinicName: [
    {
      required: true,
      transform: (value) => (value ? value.trim() : value),
      message: 'Введіть назву вашої клініки!',
    },
  ],
  city: [{ required: true, message: 'Введіть область вашої клініки!' }],
  phoneNumber: [
    { required: true, message: 'Введіть номер телефону!' },
    {
      pattern: /\+?3?8?0 \(\d\d\) \d\d\d \d\d \d\d$/,
      message: 'Введено невірний телефон!',
    },
  ],
  birthDay: [{ type: 'object', message: 'Недійсний день народження!' }],
  birthDayRequired: [
    { required: true, message: 'Будь ласка, введіть день народження!' },
    { type: 'object' },
  ],
  code: [
    {
      required: true,
      transform: (value) => (value ? value.trim() : value),
      message: "Код підтвердження обов'язковий!",
    },
  ],
  site: [
    { required: true, message: 'Please enter site!' },
    {
      pattern:
        /[-a-zA-Z0-9@:%_/+.~#?&/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_/+.~#?&/=]*)?/gi,
      message: 'Please input valid site!',
    },
  ],
  nameDir: [
    {
      required: true,
      transform: (value) => (value ? value.trim() : value),
      message: "Назва директорії обов'язкова!",
    },
  ],
  fileTypeDir: [
    { required: true, message: "Тип файлів директорії обов'язковий!" },
  ],
  customPushTitle: [],
  customPushText: [],
  identificationCode: [
    {
      pattern: /^\d+$/,
      message: 'Дозволенні лише числа в ідентифікаційному номері',
    },
  ],
  passportNumber: [{ min: 6, message: 'Мінімальна кількість символів - 6' }],
}
