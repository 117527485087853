import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProductionsActionTypes, ProductionsState, types } from './types'
import { Productions } from './namespace'

const initialState: ProductionsState = {
  created: {
    items: [],
    count: 0,
    isLoading: false,
  },
  printing: {
    items: [],
    count: 0,
    isLoading: false,
  },
  production: {
    items: [],
    count: 0,
    isLoading: false,
  },
  done: {
    items: [],
    count: 0,
    isLoading: false,
  },
  sent: {
    items: [],
    count: 0,
    isLoading: false,
  },
  statistics: {
    created: 0,
    printing: 0,
    production: 0,
    done: 0,
    sent: 0,
  },
}

const slice = createSlice({
  name: 'PRODUCTIONS',
  initialState,
  reducers: {
    toggleLoading: (
      state: ProductionsState,
      action: PayloadAction<Productions.ReqToggleLoading>
    ) => {
      state[action.payload.type] = {
        ...state[action.payload.type],
        isLoading: action.payload.state,
      }
    },
    saveItems: (
      state: ProductionsState,
      action: PayloadAction<Productions.ReqSaveItems>
    ) => {
      state[action.payload.type] = {
        ...state[action.payload.type],
        items: action.payload.items,
        count: action.payload.count,
      }
    },
    saveStatistics: (
      state: ProductionsState,
      action: PayloadAction<Productions.Statistics>
    ) => {
      state.statistics = action.payload
    },
    moveItem: (
      state: ProductionsState,
      action: PayloadAction<Productions.ReqMoveItem>
    ) => {
      state[action.payload.from] = {
        ...state[action.payload.from],
        items: state[action.payload.from].items.filter(
          (it) => it.id !== action.payload?.item?.id
        ),
      }
      state[action.payload.to] = {
        ...state[action.payload.to],
        items: [action.payload.item, ...state[action.payload.to].items],
      }
      state.statistics = {
        ...state.statistics,
        [action.payload.from]: state.statistics[action.payload.from] - 1,
        [action.payload.to]: state.statistics[action.payload.to] + 1,
      }
    },
  },
})

export default slice.reducer

export const productionsActions = {
  ...slice.actions,
  fetchItemsAsync: (
    payload: Productions.ReqGetList
  ): ProductionsActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
  fetchStatisticsAsync: (): ProductionsActionTypes => ({
    type: types.FETCH_STATISTICS,
  }),
  startPrintAsync: (
    payload: Productions.ReqChangeStatusData
  ): ProductionsActionTypes => ({
    type: types.START_PRINT,
    payload,
  }),
  startProductionAsync: (
    payload: Productions.ReqChangeStatusData
  ): ProductionsActionTypes => ({
    type: types.START_PRODUCTION,
    payload,
  }),
  setDoneAsync: (
    payload: Productions.ReqChangeStatusData
  ): ProductionsActionTypes => ({
    type: types.SET_DONE,
    payload,
  }),
  setSentAsync: (
    payload: Productions.ReqSetSentData
  ): ProductionsActionTypes => ({
    type: types.SET_SENT,
    payload,
  }),
}
