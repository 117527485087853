import React from 'react'

export const ZipFileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="50"
    viewBox="0 0 44 50"
    {...props}
  >
    <path d="M29.468 0c.076 0 .151.01.224.028l.052.017c.067.02.131.048.192.081.022.013.043.024.064.039.07.046.137.1.194.163l.008.007 9.579 11.013c.156.178.229.404.23.634.006.037.011.073.011.11v5.793h1.193c1.538 0 2.785 1.254 2.785 2.802v14.574c0 1.547-1.247 2.802-2.785 2.802h-1.193v9.976c0 1.081-.874 1.961-1.948 1.961H5.926c-1.075 0-1.948-.88-1.948-1.96v-9.977H2.785C1.247 38.063 0 36.807 0 35.26V20.687c0-1.548 1.247-2.803 2.785-2.803h1.193V1.961C3.978.88 4.85 0 5.926 0zm8.606 38.063H5.926v9.445h32.148v-9.445zM23.715 21.445h-2.803v12.431h2.803v-12.43zm-4.657 0h-8.962v2.323h5.333v.074l-5.883 8.522v1.512h9.604v-2.324h-5.846v-.073l5.754-8.412v-1.622zm10.853-.093c-1.704 0-2.914.11-3.776.258v12.266h2.767v-4.444c.257.035.587.055.953.055 1.651 0 3.061-.406 4.014-1.31.733-.702 1.137-1.734 1.137-2.951 0-1.219-.532-2.25-1.32-2.878-.825-.664-2.052-.996-3.775-.996zm.146 2.121c1.393 0 2.18.683 2.18 1.827 0 1.272-.915 2.029-2.399 2.029-.404 0-.698-.019-.936-.074v-3.67c.202-.057.587-.112 1.155-.112zM28.494 1.961H5.926v15.924h32.148v-4.912h-8.606c-.538 0-.974-.438-.974-.98V1.961z" />
  </svg>
)
