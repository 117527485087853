import React from 'react'
import moment from 'moment'
import { Button, Tag } from 'antd'
import type { ColumnsType } from 'antd/lib/table/interface'
// Local
import styles from '../styles.module.scss'
// Typing
import type { Partner } from '@/bus/partners'
import { eventsObserver } from '@/services/observable.service'
import { Account } from '@/bus/auth'
import { WalletChangeType } from '@/configs/BackendEnum'
import { Link } from 'react-router-dom'

export const useColumns = () => {
  const showDetailPayment = (id: number) => {
    eventsObserver.broadcast('pressByPaymentDetail', { paymentId: id })
  }

  const columns: ColumnsType<Account.WalletChanges> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      // sorter: true,
    },
    {
      title: 'Партнер',
      dataIndex: 'wallet',
      key: 'wallet',
      render: (wallet: Account.Wallet) => {
        if (!wallet || !wallet.user || !wallet.user.partnerInfo) return ''
        return (
          <Link
            to={'/app/partners/' + wallet.user.id}
            style={{ color: '#6298ff', fontWeight: 400 }}
          >
            {wallet.user.partnerInfo.firstName}{' '}
            {wallet.user.partnerInfo.lastName}
          </Link>
        )
      },
    },

    {
      title: 'Пакети',
      key: 'value',
      width: 100,
      render: (_, item) => {
        const color =
          item.changeType === WalletChangeType.Add ? 'green' : 'volcano'
        const text =
          item.changeType === WalletChangeType.Add
            ? `+ ${item.credits}`
            : `- ${item.credits}`

        return (
          <Tag color={color} style={{ borderRadius: 0 }}>
            {text}
          </Tag>
        )
      },
    },

    {
      title: 'Дата оплати',
      dataIndex: 'createDate',
      key: 'createDate',
      defaultSortOrder: 'descend',
      // sorter: true,
      align: 'center',
      render: (_, value) => moment(value.createDate).format('DD.MM.YYYY'),
    },

    // {
    //   dataIndex: 'id',
    //   align: 'right',
    //   key: 'action',
    //   render: (id) => (
    //     <>
    //       <Button
    //         onClick={() => showDetailPayment(id)}
    //         type="primary"
    //         className={styles.tableBtn}
    //       >
    //         Детальна Інформація
    //       </Button>
    //     </>
    //   ),
    // },
  ]

  return columns
}
