import { apiClients } from '@/bus/clients/api'
import { apiPayments } from '@/bus/payments/api'
import {
  paymentSystemLabels,
  purposeOfPaymentLabels,
} from '@/configs/transformsEnums'
import { useEventsListener } from '@/hooks/useEventsListener'
import { Drawer, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

interface IEventPaylod {
  paymentId: number
}

export const PaymentInfoSmart = () => {
  const [payment, setPayment] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const close = () => setPayment(null)
  const isVisible = Boolean(payment)

  const fetchPayment = async (id: number) => {
    setLoading(true)
    const { data } = await apiPayments.fetchPayment(id)
    setPayment(data)
  }

  useEffect(() => {
    if (payment) setLoading(false)
  }, [payment])

  useEventsListener(
    'pressByPaymentDetail',
    (data: IEventPaylod) => {
      console.log('fick')
      fetchPayment(data.paymentId)
    },
    []
  )

  const renderAddsContent = () => {
    const toReturn: JSX.Element[] = []
    if (payment.data.liqpayOrder) {
      const liqpayOrder = payment.data.liqpayOrder
      toReturn.push(
        <>
          <p>Id платежа в системі LiqPay: {liqpayOrder.paymentId}</p>
          <p>
            Order_id платежа в системі LiqPay: {liqpayOrder.liqpay_order_id}
          </p>
          <p>Карта відправника: {liqpayOrder.sender_card_mask2}</p>
          <p>Ім'я відправника: {liqpayOrder.sender_first_name}</p>
          <p>Прізвище відправника: {liqpayOrder.sender_last_name}</p>
        </>
      )
    }

    if (payment.data.packagesCount) {
      toReturn.push(
        <>
          <p>Кількість оплачених пакетів: {payment.data.packagesCount}</p>
        </>
      )
    }

    return toReturn
  }

  const renderContent = () => {
    if (!isVisible) return null
    if (isLoading) return <Spin />

    return (
      <>
        <p>Ідентифікатор покупки: {payment.id} </p>
        <p>Ціль: {purposeOfPaymentLabels[payment.purposeOfPayment]} </p>
        <p>Система оплати: {paymentSystemLabels[payment.platform]} </p>
        <p>
          Дата створення платежу:{' '}
          {moment(payment.createDate).format('DD.MM.YYYY')}
        </p>
        {renderAddsContent()}
      </>
    )
  }

  return (
    <Drawer
      title="Інформація про платіж"
      placement="right"
      closable={false}
      onClose={close}
      visible={isVisible}
      width={520}
    >
      {renderContent()}
    </Drawer>
  )
}
