export const truncateString = (str: string, maxLength: number) => {
  const sliced = str.slice(0, maxLength - 1) + '...'

  return str.length < maxLength ? str : sliced
}

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getInitials = (name: string = ''): string =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('')
