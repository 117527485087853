import type { AxiosPromise } from 'axios'

import axios from '@/services/axios'
import type { Product } from './namespace'

export const apiProducts = {
  fetchItems(
    params: Product.ReqFetchItems
  ): AxiosPromise<Product.ResFetchItems> {
    return axios({
      url: '/admin/products',
      method: 'get',
      params,
    })
  },

  changePrice(
    productId: number,
    data: Product.ReqChangePrice
  ): AxiosPromise<void> {
    return axios({
      url: `/admin/products/${productId}`,
      method: 'patch',
      data,
    })
  },

  store(data: Product.ReqStoreProduct): AxiosPromise<Product.Item> {
    return axios({
      url: '/admin/products',
      method: 'post',
      data: {
        productName: data.productName,
        price: data.price,
      },
    })
  },

  update(data: Omit<Product.ReqUpdateProduct, 'templates'>) {
    return axios({
      url: `/admin/products/${data.id}`,
      method: 'patch',
      data: {
        productName: data.productName,
      },
    })
  },

  delete(data: number) {
    return axios({
      url: `/admin/products/${data}`,
      method: 'DELETE',
    })
  },

  deleteTemplate(data: number) {
    return axios({
      url: `/admin/products-documents-templates/${data}`,
      method: 'delete',
    })
  },

  generateDocument(
    templateId: number,
    clientId: number,
    tagId: number,
    date: string
  ) {
    return axios({
      url: '/admin/products-documents-templates/generate-document',
      method: 'post',
      data: { templateId, clientId, tagId, date },
      timeout: 360000,
    })
  },

  getTemplatePresignedUrl(params: {
    filename: string
    productId: number
    mimetype: string
    title: string
  }) {
    return axios({
      url: `/admin/products-documents-templates/presigned-put-template`,
      params,
    })
  },

  finishUploadTemplate(id: string) {
    return axios.post(
      `/admin/products-documents-templates/presigned-urls/${id}`
    )
  },
}
