import { call, delay } from '@redux-saga/core/effects'
import { SagaIterator } from '@redux-saga/types'
import { put } from 'redux-saga/effects'
// Local Dir
import { apiRating } from '../../api'
import { ratingActions } from '../../slice'
import { FetchItemsAsync } from '../../types'

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    yield put(ratingActions.toggleLoading(true))

    const { data } = yield call(apiRating.fetchItems)

    console.log('Rating', data)

    const preparedItems = data.items.map((it) => {
      const client = it.client?.clientInfo
      return {
        clientId: it.clientId,
        partnerId: it.partnerId,
        comment: it.comment,
        id: it.id,
        rating: it.rating,
        partnerName: it.partner?.partnerInfo?.clinicName,
        clientName: client?.name,
      }
    })

    yield put(
      ratingActions.saveItems({
        items: preparedItems,
        count: data.count,
      })
    )
  } finally {
    yield put(ratingActions.toggleLoading(false))
  }
}
