import type { AxiosPromise } from 'axios'
import qs from 'qs'

import axios from '@/services/axios'
import type { ClientFiles } from './namespace'

export const apiClientFiles = new (class Api {
  fetchTagsClient(
    params: ClientFiles.ReqFetchTagsClient
  ): AxiosPromise<ClientFiles.ResFetchTags> {
    return axios({
      url: '/files-tags',
      method: 'get',
      params,
    })
  }

  fetchFilesByTag(tagId: number): AxiosPromise<ClientFiles.Detail> {
    return axios({
      url: `/files-tags/${tagId}`,
      method: 'get',
      params: {
        attachFiles: true,
      },
    })
  }

  changeTag({
    id,
    data,
  }: ClientFiles.ReqChangeTag): AxiosPromise<ClientFiles.ResCreateTag> {
    return axios({
      url: `/files-tags/${id}`,
      method: 'patch',
      data,
    })
  }

  createTag(
    data: ClientFiles.ReqCreateTag
  ): AxiosPromise<ClientFiles.ResCreateTag> {
    return axios({
      url: '/files-tags',
      method: 'post',
      data,
    })
  }

  deleteFile({ clientId, fileId }: ClientFiles.ReqDeleteFile): AxiosPromise {
    return axios({
      url: `/clients/${clientId}/files/${fileId}`,
      method: 'delete',
    })
  }

  createTagLink(data: ClientFiles.ReqCreateTagLink): AxiosPromise<string> {
    return axios({
      url: '/files-tags-links',
      method: 'post',
      data,
    })
  }

  fetchFilesByLink(linkKey: string): AxiosPromise<ClientFiles.TagByLink> {
    return axios({
      url: `/files-tags-links/${linkKey}`,
      method: 'get',
    })
  }

  fetchFiles({
    clientId,
    params,
  }: ClientFiles.ReqFetchFiles): AxiosPromise<ClientFiles.ClientFile[]> {
    return axios({
      url: `/clients/${clientId}/files`,
      method: 'get',
      params,
      paramsSerializer: (params) => qs.stringify(params),
    })
  }

  getPresignedUrl(params: {
    filename: string
    userId: number
    mimetype: string
    tagId?: number
  }) {
    return axios({
      url: `/clients-files/v2/presigned-put-file`,
      params,
    })
  }
})()
